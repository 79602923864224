/*
  Theme Name: Hiredots - Human Resources & Recruiting React Template
  Theme URL: https://mediacity.co.in/hiredots
  Author: Media City
  Author URI: https://mediacity.co.in/
  Creation Date: 12 January 2024
  Description: A default stylesheet for Hiredots - Human Resources & Recruiting React Template.
  Version: 1.0
*/
/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Utility
# Cards
# Common
# Form
# Navigations
# Animations
# Mobile Nav
# Search Popup
# Page Header
# Google Map
# Client Carousel
--------------------------------------------------------------*/
:root {
  --hiredots-font: "Manrope", sans-serif;
  --hiredots-heading-font: "Manrope", serif;
  --hiredots-special-font: "reeyregular", cursive;
  --hiredots-text: #696e76;
  --hiredots-text-rgb: 105, 110, 118;
  --hiredots-text-dark: #7e8c9e;
  --hiredots-text-dark-rgb: 126, 140, 158;
  --hiredots-text-gray: #89868d;
  --hiredots-text-gray-rgb: 137, 134, 141;
  --hiredots-base: #00cde5;
  --hiredots-base-rgb: 0, 205, 229;
  --hiredots-primary: #0b2f81;
  --hiredots-primary-rgb: 11, 47, 129;
  --hiredots-gray: #f0f3f9;
  --hiredots-gray-rgb: 240, 243, 249;
  --hiredots-white: #fff;
  --hiredots-white-rgb: 255, 255, 255;
  --hiredots-black: #0b182a;
  --hiredots-black-rgb: 11, 24, 42;
  --hiredots-black2: #12243c;
  --hiredots-black2-rgb: 18, 36, 60;
  --hiredots-black3: #000;
  --hiredots-black3-rgb: 0, 0, 0;
  --hiredots-border-color: #cbd2da;
  --hiredots-border-color-2: #ced5dd;
  --hiredots-border-color-rgb: 203, 210, 218;
  --hiredots-border-color-rgb-2: 206, 213, 221;
  --hiredots-letter-space-sm: -0.04em;
  --hiredots-letter-space: 0.1em;
  --hiredots-letter-space-xl: 0.2em;
}

/*--------------------------------------------------------------
# Utility
--------------------------------------------------------------*/
.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-120 {
  margin-top: 120px;
}

.mt--60 {
  margin-top: -60px;
}

.mt--120 {
  margin-top: -120px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-120 {
  margin-bottom: 120px;
}

.mb--60 {
  margin-bottom: -60px;
}

.mb--120 {
  margin-bottom: -120px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-100 {
  padding-top: 100px;
}

.pt-110 {
  padding-top: 110px;
}

.pt-115 {
  padding-top: 115px;
}

.pt-120 {
  padding-top: 120px;
}

.pt-142 {
  padding-top: 142px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-110 {
  padding-bottom: 110px;
}

.pb-115 {
  padding-bottom: 115px;
}

.pb-120 {
  padding-bottom: 120px;
}

.pl-5 {
  padding-left: 5px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-30 {
  padding-left: 30px;
}

.pr-5 {
  padding-right: 5px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-30 {
  padding-right: 30px;
}

/*--------------------------------------------------------------
# Common
--------------------------------------------------------------*/
body {
  font-family: var(--hiredots-font, "Manrope", sans-serif);
  color: var(--hiredots-text, #696e76);
  font-size: 15px;
  line-height: 1.875;
  font-weight: 500;
}

body.locked {
  overflow: hidden;
}

a {
  color: var(--hiredots-base, #00cde5);
  transition: all 400ms ease;
}

a,
a:hover,
a:focus,
a:visited {
  text-decoration: none;
}

::placeholder {
  color: inherit;
  opacity: 1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--hiredots-heading-font, "Manrope", serif);
  font-weight: 800;
  color: var(--hiredots-black, #0b182a);
}
@media (max-width: 575px) {
  h1 br,
  h2 br,
  h3 br,
  h4 br,
  h5 br,
  h6 br {
    display: none;
  }
}

@media (max-width: 575px) {
  p br {
    display: none;
  }
}

::placeholder {
  color: inherit;
  opacity: 1;
}

.background-base {
  background-color: var(--hiredots-base, #00cde5);
}

.background-primary {
  background-color: var(--hiredots-primary, #0b2f81);
}

.background-gray {
  background-color: var(--hiredots-gray, #f0f3f9);
}

.background-black {
  background-color: var(--hiredots-black, #0b182a);
}

.background-black-2 {
  background-color: var(--hiredots-black2, #12243c);
}

.hiredots-text-dark {
  color: var(--hiredots-text-dark, #7e8c9e);
}

.page-wrapper {
  position: relative;
  margin: 0 auto;
  width: 100%;
  min-width: 300px;
  overflow: hidden;
}

.container-fluid,
.container {
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}
.row {
  --bs-gutter-x: 30px;
}

.gutter-y-10 {
  --bs-gutter-y: 10px;
}

.gutter-y-15 {
  --bs-gutter-y: 15px;
}

.gutter-y-20 {
  --bs-gutter-y: 20px;
}

.gutter-y-30 {
  --bs-gutter-y: 30px;
}

.gutter-y-60 {
  --bs-gutter-y: 60px;
}

.hiredots-btn {
  display: inline-block;
  vertical-align: middle;
  -webkit-appearance: none;
  border: none;
  outline: none !important;
  background-color: var(--hiredots-base, #00cde5);
  color: var(--hiredots-white, #fff);
  font-size: 14px;
  font-weight: 800;
  font-family: var(--hiredots-font, "Manrope", sans-serif);
  padding: 16px 49px;
  transition: 500ms;
  text-transform: uppercase;
  position: relative;
  overflow: hidden;
  text-align: center;
  z-index: 1;
}
.hiredots-btn::after {
  position: absolute;
  transition: 0.5s;
  content: "";
  width: 0;
  left: 50%;
  bottom: 0;
  height: 3px;
  background: var(--hiredots-primary, #0b2f81);
  height: 120%;
  left: -10%;
  transform: skewX(25deg);
  z-index: -1;
}
.hiredots-btn:hover {
  color: var(--hiredots-white, #fff);
}
.hiredots-btn:hover::after {
  left: -10%;
  width: 120%;
}
.hiredots-btn span {
  transform: 0.3s;
  position: relative;
  color: inherit;
  z-index: 2;
}
.hiredots-btn--white::after {
  background-color: var(--hiredots-white, #fff);
}
.hiredots-btn--white:hover span {
  color: var(--hiredots-primary, #0b2f81);
}
.hiredots-btn--black:hover {
  color: var(--hiredots-white, #fff);
}
.hiredots-btn--black::after {
  background-color: var(--hiredots-base, #00cde5);
}
.hiredots-btn--black::before {
  background-color: var(--hiredots-black, #0b182a);
}
.hiredots-btn--primary {
  background-color: var(--hiredots-primary, #0b2f81);
}
.hiredots-btn--primary::after {
  background-color: var(--hiredots-base, #00cde5);
}

.tabs-box .tabs-content .tab:not(.active-tab) {
  display: none;
}

.bootstrap-select .dropdown-menu {
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 0;
}
.bootstrap-select .dropdown-item.active,
.bootstrap-select .dropdown-item:active {
  background-color: var(--hiredots-base, #00cde5);
}

.tns-outer .tns-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}
.tns-outer .tns-controls button {
  width: 45px;
  height: 45px;
  border: 2px solid #f4f4f4;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--hiredots-text, #696e76);
  border-radius: 50%;
  margin-left: 5px;
  margin-right: 5px;
}

.block-title {
  margin-top: -8px;
  margin-bottom: 50px;
}
.block-title__decor {
  width: 21px;
  height: 14px;
  /* background-image: url(../images/shapes/leaf-1-1.png); */
  background-repeat: no-repeat;
  background-position: top center;
  display: inline-block;
  line-height: 1;
  margin-bottom: -5px;
  position: relative;
  top: -7px;
}
.block-title p {
  margin: 0;
  color: var(--hiredots-text, #696e76);
  font-size: 16px;
  line-height: 1;
  margin-bottom: 7px;
}
@media (min-width: 768px) {
  .block-title p {
    font-size: 18px;
  }
}
@media (min-width: 992px) {
  .block-title p {
    font-size: 20px;
  }
}
.block-title h3 {
  margin: 0;
  font-size: 35px;
  color: var(--hiredots-black, #0b182a);
  font-family: var(--hiredots-special-font, "reeyregular", cursive);
}
@media (min-width: 768px) {
  .block-title h3 {
    font-size: 42px;
  }
}
@media (min-width: 992px) {
  .block-title h3 {
    font-size: 50px;
  }
}

.ul-list-one {
  margin-bottom: 0;
}
.ul-list-one li {
  position: relative;
  padding-left: 45px;
  font-size: 15px;
  font-weight: 500;
  color: var(--hiredots-black, #0b182a);
}
@media (min-width: 481px) {
  .ul-list-one li {
    font-size: 20px;
  }
}
.ul-list-one li::before {
  content: "\e907";
  color: var(--hiredots-base, #00cde5);
  font-size: 26px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-family: "azino-icon";
}

.preloader {
  position: fixed;
  background-color: var(--hiredots-black, #0b182a);
  background-position: center center;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9991;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
}
.preloader__image {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: flipInY;
  animation-name: flipInY;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 60px auto;
  width: 100%;
  height: 100%;
}

/* scroll to top */
.scroll-to-top {
  display: flex;
  align-items: center;
  width: auto;
  height: 35px;
  background: transparent;
  position: fixed;
  bottom: 60px;
  right: -12px;
  z-index: 99;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  transform: rotate(-90deg);
  cursor: pointer;
  transition: all 0.2s ease;
}
.scroll-to-top__text {
  display: inline;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 700;
  margin-right: 8px;
}
.scroll-to-top__wrapper {
  display: inline-block;
  width: 30px;
  height: 4px;
  background-color: var(--hiredots-base, #00cde5);
  position: relative;
  overflow: hidden;
}
.scroll-to-top__inner {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--hiredots-black, #0b182a);
}
.scroll-to-top.show {
  opacity: 1;
  visibility: visible;
  bottom: 70px;
}

/* post paginations */
.post-pagination {
  margin-bottom: 0;
  margin-top: 0px;
}
@media (min-width: 992px) {
  .post-pagination {
    margin-top: 0px;
  }
}
.post-pagination a {
  display: flex;
  width: 45px;
  height: 45px;
  background-color: #eff2f6;
  align-items: center;
  justify-content: center;
  color: var(--hiredots-text, #696e76);
  font-size: 16px;
  font-weight: 500;
  border-radius: 50%;
  transition: 500ms ease;
}
@media (min-width: 992px) {
  .post-pagination a {
    width: 60px;
    height: 60px;
    font-size: 18px;
  }
}
.post-pagination a:hover {
  background-color: var(--hiredots-base, #00cde5);
  color: #fff;
}
.post-pagination li:first-child a {
  background-color: var(--hiredots-base, #00cde5);
  color: #fff;
}
.post-pagination li:last-child a {
  background-color: var(--hiredots-black, #0b182a);
  color: #fff;
}
.post-pagination li + li {
  margin-left: 10px;
}

.hiredots-owl__carousel--with-shadow .owl-stage-outer {
  overflow: visible;
}
.hiredots-owl__carousel--with-shadow .owl-item {
  opacity: 0;
  visibility: hidden;
  transition: opacity 500ms ease, visibility 500ms ease;
}
.hiredots-owl__carousel--with-shadow .owl-item.active {
  opacity: 1;
  visibility: visible;
}

.hiredots-owl__carousel--basic-nav .owl-nav {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 40px;
}
.hiredots-owl__carousel--basic-nav .owl-nav button[class*=owl-] {
  border: none;
  outline: none;
  width: 57px;
  height: 57px;
  margin: 0;
  padding: 0;
  background-color: var(--hiredots-gray, #f0f3f9);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  border-radius: 50%;
  font-size: 16px;
  color: var(--hiredots-black, #0b182a);
  transition: all 500ms ease;
}
.hiredots-owl__carousel--basic-nav .owl-nav button[class*=owl-]:hover {
  background-color: var(--hiredots-base, #00cde5);
  color: var(--hiredots-white, #fff);
}
.hiredots-owl__carousel--basic-nav .owl-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 60px;
}
.hiredots-owl__carousel--basic-nav .owl-dots .owl-dot span {
  background-color: var(--hiredots-black, #0b182a);
  border: 2px solid var(--hiredots-white, #fff);
  box-shadow: 0 0 1px rgba(var(--hiredots-black-rgb, 11, 24, 42), 1);
  margin: 0;
}
.hiredots-owl__carousel--basic-nav .owl-dots .owl-dot:hover span, .hiredots-owl__carousel--basic-nav .owl-dots .owl-dot.active span {
  background-color: var(--hiredots-black, #0b182a);
  border: 2px solid var(--hiredots-black, #0b182a);
  box-shadow: 0 0 1px rgba(var(--hiredots-black-rgb, 11, 24, 42), 1);
}
.hiredots-owl__carousel--basic-nav .owl-nav.disabled + .owl-dots {
  margin-top: 60px;
}

.sec-title {
  padding-bottom: 40px;
}
@media (min-width: 768px) {
  .sec-title {
    padding-bottom: 49px;
  }
}
.sec-title__img {
  display: inline-flex;
  margin-bottom: 15px;
}
.sec-title__tagline {
  margin: 0;
  text-transform: uppercase;
  color: var(--hiredots-primary, #0b2f81);
  font-size: 14px;
  font-weight: 600;
  line-height: 1.2em;
  letter-spacing: var(--hiredots-letter-space, 0.1em);
  position: relative;
  display: inline-block;
}
.sec-title__tagline::before {
  content: "";
  display: block;
  width: 35px;
  height: 5px;
  background-color: var(--hiredots-base, #00cde5);
  border-radius: 30px;
  margin-bottom: 17px;
}
.sec-title__title {
  margin: 0;
  font-size: 30px;
  color: var(--hiredots-black, #0b182a);
  font-weight: 800;
  line-height: 1.2em;
  letter-spacing: var(--hiredots-letter-space-sm, -0.04em);
}
@media (min-width: 768px) {
  .sec-title__title {
    font-size: 46px;
  }
}
.sec-title.text-center .sec-title__tagline::before {
  margin-left: auto;
  margin-right: auto;
}
.sec-title.text-end .sec-title__tagline::before {
  margin-left: auto;
}

.ui-datepicker .ui-datepicker-header {
  background-image: none;
  background-color: var(--hiredots-black, #0b182a);
  color: var(--hiredots-white, #fff);
  font-family: var(--hiredots-font, "Manrope", sans-serif);
}

.ui-datepicker-calendar th span {
  font-family: var(--hiredots-font, "Manrope", sans-serif);
}
.ui-datepicker-calendar td {
  background-color: var(--hiredots-gray, #f0f3f9);
  background-image: none;
  font-family: var(--hiredots-font, "Manrope", sans-serif);
  color: var(--hiredots-text, #696e76);
}
.ui-datepicker-calendar td a {
  border-color: var(--hiredots-border-color, #cbd2da);
  background-color: var(--hiredots-gray, #f0f3f9);
  background-image: none;
}
.ui-datepicker-calendar .ui-state-default,
.ui-datepicker-calendar .ui-widget-content .ui-state-default,
.ui-datepicker-calendar .ui-widget-header .ui-state-default {
  border-color: var(--hiredots-border-color, #cbd2da);
  background-color: var(--hiredots-gray, #f0f3f9);
  background-image: none;
  color: var(--hiredots-text, #696e76);
  padding: 10px 5px;
  text-align: center;
  line-height: 1em;
}
.ui-datepicker-calendar .ui-state-default:hover,
.ui-datepicker-calendar .ui-widget-content .ui-state-default:hover,
.ui-datepicker-calendar .ui-widget-header .ui-state-default:hover {
  color: var(--hiredots-white, #fff);
  background-color: var(--hiredots-base, #00cde5);
}
.ui-datepicker-calendar .ui-state-highlight,
.ui-datepicker-calendar .ui-widget-content .ui-state-highlight,
.ui-datepicker-calendar .ui-widget-header .ui-state-highlight {
  color: var(--hiredots-white, #fff);
  background-color: var(--hiredots-base, #00cde5);
}

.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
  background-image: none;
  background-color: var(--hiredots-white, #fff);
  color: var(--hiredots-black, #0b182a);
}
.ui-datepicker .ui-datepicker-prev:hover,
.ui-datepicker .ui-datepicker-next:hover {
  background-color: var(--hiredots-base, #00cde5);
  color: var(--hiredots-white, #fff);
  top: 2px;
}

.ui-datepicker .ui-datepicker-prev:hover {
  left: 2px;
}

.ui-datepicker .ui-datepicker-next:hover {
  right: 2px;
}

/*--------------------------------------------------------------
# Cards
--------------------------------------------------------------*/
.video-one {
  position: relative;
  background-color: var(--hiredots-black, #0b182a);
  padding: 100px 0;
}
.video-one__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--hiredots-black, #0b182a);
  background-size: cover;
  background-position: center center;
  opacity: 0.5;
}
.video-one .container {
  position: relative;
  text-align: center;
}
.video-one__btn {
  width: 145px;
  height: 145px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: relative;
}
.video-one__btn .video-popup {
  font-size: 24px;
  color: var(--hiredots-white, #fff);
  transition: all 500ms ease;
  position: relative;
  z-index: 10;
}
.video-one__btn .video-popup:hover {
  color: var(--hiredots-base, #00cde5);
}
.video-one__btn .curved-circle {
  position: absolute;
  top: 0;
  left: 0;
  width: 145px;
  height: 145px;
  transform-origin: center center;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: textRotate 15s linear 0s forwards infinite alternate;
}
.video-one__btn .curved-circle--item {
  width: 145px;
}
.video-one__btn .curved-circle--item span {
  text-transform: uppercase;
  font-size: 14px;
  color: var(--hiredots-white, #fff);
  letter-spacing: 0.4em;
}
.video-one__title {
  margin: 0;
  text-transform: uppercase;
  color: var(--hiredots-white, #fff);
  font-size: 40px;
  line-height: 1.2em;
  margin-bottom: 40px;
  margin-top: 30px;
}
@media (min-width: 768px) {
  .video-one__title {
    font-size: 50px;
  }
}
@media (min-width: 992px) {
  .video-one__title {
    font-size: 60px;
    margin-top: 20px;
    margin-bottom: 35px;
  }
}
.video-one__link::before {
  background-color: var(--hiredots-base, #00cde5);
}

.video-two {
  position: relative;
  background-color: var(--hiredots-black, #0b182a);
  padding: 143px 0 320px;
}
@media (max-width: 767px) {
  .video-two {
    padding: 100px 0 270px;
  }
  .video-two .text-end {
    text-align: left !important;
  }
}
.video-two__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--hiredots-black, #0b182a);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  opacity: 0.5;
}
.video-two__shape {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-position: left top;
  background-repeat: no-repeat;
  background-size: auto;
}
@media (max-width: 1199px) {
  .video-two__shape {
    display: none;
  }
}
.video-two .container {
  position: relative;
}
.video-two__btn {
  width: 145px;
  height: 145px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: relative;
  margin-top: 42px;
}
.video-two__btn .video-popup {
  font-size: 24px;
  color: var(--hiredots-white, #fff);
  transition: all 500ms ease;
  position: relative;
  z-index: 10;
}
.video-two__btn .video-popup:hover {
  color: var(--hiredots-base, #00cde5);
}
.video-two__btn .curved-circle {
  position: absolute;
  top: 0;
  left: 0;
  width: 145px;
  height: 145px;
  transform-origin: center center;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: textRotate 15s linear 0s forwards infinite alternate;
}
.video-two__btn .curved-circle--item {
  width: 145px !important;
  height: 145px !important;
}
.video-two__btn .curved-circle--item span {
  text-transform: uppercase;
  font-size: 14px;
  color: var(--hiredots-white, #fff);
  letter-spacing: 0.4em;
}
.video-two__title {
  margin: 0;
  text-transform: uppercase;
  color: var(--hiredots-white, #fff);
  font-size: 40px;
  line-height: 1.2em;
  margin-bottom: 40px;
}
@media (min-width: 768px) {
  .video-two__title {
    font-size: 50px;
  }
}
@media (min-width: 992px) {
  .video-two__title {
    font-size: 60px;
    margin-bottom: 35px;
  }
}
.video-two__link::before {
  background-color: var(--hiredots-base, #00cde5);
}

.team-one {
  position: relative;
  padding: 120px 0;
}
@media (max-width: 991px) {
  .team-one {
    padding-bottom: 60px;
  }
}
@media (max-width: 767px) {
  .team-one {
    padding: 80px 0 20px;
  }
}
@media (min-width: 992px) {
  .team-one__carousel .owl-nav {
    display: none;
  }
}
.team-one--home {
  padding-bottom: 178px;
}
@media (max-width: 991px) {
  .team-one--home {
    padding-bottom: 60px;
  }
}
@media (max-width: 767px) {
  .team-one--home {
    padding-bottom: 20px;
  }
}

.team-card {
  text-align: center;
  margin-bottom: 60px;
}
@media (min-width: 992px) {
  .team-card {
    margin-bottom: 0;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .team-card {
    padding: 0 20px;
  }
}
.team-card:hover .team-card__image__bg {
  background-color: var(--hiredots-primary, #0b2f81);
}
.team-card__image {
  position: relative;
  display: inline-block;
  margin-bottom: 47px;
}
.team-card__image img {
  position: relative;
  max-width: 100%;
  border-radius: 50%;
}
.team-card__image__bg {
  position: absolute;
  top: -25px;
  bottom: -25px;
  left: -25px;
  right: -25px;
  display: inline-block;
  border-radius: 50%;
  background-color: var(--hiredots-gray, #f0f3f9);
  transition: 0.3s;
}
.team-card__image__bg::before {
  content: "";
  position: absolute;
  top: -25px;
  bottom: 50%;
  left: -25px;
  right: -25px;
  display: inline-block;
  border-radius: 50% 50% 0 0;
  background-color: var(--hiredots-white, #fff);
}
.team-card__hover {
  position: absolute;
  top: 0;
  left: 0;
}
.team-card__social {
  color: var(--hiredots-base, #00cde5);
  background-color: var(--hiredots-white, #fff);
  position: relative;
  cursor: pointer;
  transition: all 500ms ease;
  box-shadow: 0px 10px 30px 0px rgba(12, 13, 12, 0.1);
  border-radius: 50%;
}
.team-card__social:hover {
  background-color: var(--hiredots-base, #00cde5);
}
.team-card__social:hover > i {
  color: var(--hiredots-white, #fff);
}
.team-card__social > i {
  width: 50px;
  height: 50px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--hiredots-black, #0b182a);
  transition: all 500ms ease;
}
.team-card__social__list {
  position: absolute;
  top: 110%;
  left: 0;
  right: 0;
  text-align: center;
  transform: scale(0, 1);
  background-color: var(--hiredots-white, #fff);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  min-height: 50px;
  padding: 15px;
  opacity: 0;
  border-radius: 30px;
  transition: 500ms ease;
  transform-origin: top bottom;
  box-shadow: 0px 10px 30px 0px rgba(12, 13, 12, 0.1);
}
.team-card__social__list a {
  color: var(--hiredots-black, #0b182a);
  font-size: 14px;
  transition: all 500ms ease;
}
.team-card__social__list a + a {
  margin-top: 10px;
}
.team-card__social__list a:hover {
  color: var(--hiredots-base, #00cde5);
}
.team-card__social:hover .team-card__social__list {
  opacity: 1;
  transform: scale(1, 1);
}
.team-card__content {
  position: relative;
}
.team-card__title {
  margin: 0;
  font-size: 20px;
  line-height: 1;
  color: var(--hiredots-black, #0b182a);
  text-transform: capitalize;
  margin-bottom: 13px;
  letter-spacing: -0.96px;
}
@media (min-width: 768px) {
  .team-card__title {
    font-size: 24px;
  }
}
.team-card__title a {
  color: inherit;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.team-card__title a:hover {
  background-size: 100% 1px;
}
.team-card__title a:hover {
  color: var(--hiredots-base, #00cde5);
}
.team-card__designation {
  line-height: 1em;
  margin: 0;
  font-size: 16px;
}

.team-details {
  padding-top: 120px;
}
@media (max-width: 767px) {
  .team-details {
    padding: 80px 0 0;
  }
}
.team-details__inner {
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.07);
  background-color: var(--hiredots-white, #fff);
  position: relative;
  z-index: 1;
  padding: 30px;
}
@media (min-width: 992px) {
  .team-details__inner {
    padding: 0;
  }
}
.team-details__image {
  display: inline-block;
  position: relative;
}
@media (max-width: 991px) {
  .team-details__image {
    margin-bottom: 30px;
  }
}
.team-details__image img {
  max-width: 100%;
}
@media (min-width: 1200px) {
  .team-details__content {
    padding-left: 30px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .team-details__content {
    padding-right: 10px;
  }
}
.team-details__icon {
  position: absolute;
  left: 40px;
  bottom: 40px;
  background-color: var(--hiredots-base, #00cde5);
  border: 20px solid var(--hiredots-white, #fff);
  width: 220px;
  padding: 30px;
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
}
@media (min-width: 992px) {
  .team-details__icon {
    left: -96px;
  }
}
.team-details__icon i {
  font-size: 60px;
  color: var(--hiredots-white, #fff);
}
.team-details__icon__text {
  margin: 0;
  font-size: 18px;
  color: var(--hiredots-white, #fff);
  text-transform: uppercase;
  max-width: 104px;
  font-weight: bold;
  margin-bottom: -5px;
  margin-top: 10px;
}
@media (min-width: 768px) {
  .team-details__icon__text {
    font-size: 20px;
  }
}
@media (min-width: 992px) {
  .team-details__icon__text {
    font-size: 24px;
  }
}
.team-details__tagline {
  font-size: 30px;
  color: var(--hiredots-base, #00cde5);
  font-weight: 800;
  letter-spacing: var(--hiredots-letter-space-sm, -0.04em);
}
@media (min-width: 768px) {
  .team-details__tagline {
    font-size: 35px;
  }
}
@media (min-width: 992px) {
  .team-details__tagline {
    font-size: 40px;
  }
}
.team-details__title {
  margin: 0;
  font-weight: 800;
  font-size: 30px;
  line-height: 1;
  margin-top: -7px;
  margin-bottom: 26px;
  letter-spacing: var(--hiredots-letter-space-sm, -0.04em);
}
@media (min-width: 768px) {
  .team-details__title {
    font-size: 35px;
  }
}
@media (min-width: 992px) {
  .team-details__title {
    font-size: 40px;
  }
}
.team-details__designation {
  margin: 0;
  font-size: 16px;
  margin-bottom: 20px;
}
.team-details__text {
  margin: 0;
  font-size: 16px;
  line-height: 30px;
}
@media (max-width: 1199px) {
  .team-details__text br {
    display: none;
  }
}
.team-details__highlight {
  margin: 0;
  letter-spacing: -0.96px;
  font-size: 24px;
  line-height: 30px;
  margin-top: 27px;
  margin-bottom: 33px;
}
.team-details__list {
  margin-bottom: 0;
}
.team-details__list li {
  position: relative;
  font-size: 16px;
  line-height: 30px;
  font-weight: 500;
  padding-left: 29px;
  color: var(--hiredots-text, #696e76);
  margin-bottom: 7px;
}
@media (min-width: 576px) {
  .team-details__list li {
    width: 50%;
    float: left;
  }
}
.team-details__list li > i {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-size: 14px;
  color: var(--hiredots-base, #00cde5);
}
.team-details__social {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 35px;
}
.team-details__social a {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--hiredots-gray, #f0f3f9);
  font-size: 14px;
  color: var(--hiredots-black, #0b182a);
  transition: all 500ms ease;
  border-radius: 50%;
}
.team-details__social a:hover {
  background-color: var(--hiredots-base, #00cde5);
  color: var(--hiredots-white, #fff);
}

.team-skills-one {
  padding: 100px 0;
}
@media (max-width: 767px) {
  .team-skills-one {
    padding: 60px 0;
  }
}
.team-skills-one__title {
  margin: 0;
  text-transform: uppercase;
  font-size: 25px;
  font-weight: bold;
  color: var(--hiredots-black, #0b182a);
  line-height: 1.2em;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .team-skills-one__title {
    font-size: 30px;
  }
}
@media (min-width: 768px) {
  .team-skills-one__title {
    font-size: 36px;
    margin-bottom: 20px;
  }
}
.team-skills-one__text {
  margin: 0;
  font-size: 15px;
  line-height: 2em;
  max-width: 500px;
  width: 100%;
}
.team-skills-one__progress + .team-skills-one__progress {
  margin-top: 17px;
}
.team-skills-one__progress__title {
  text-transform: uppercase;
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}
.team-skills-one__progress__bar {
  width: 100%;
  height: 17px;
  border: 1px solid var(--hiredots-border-color, #cbd2da);
  box-shadow: inset 0px 0px 7px 0px rgba(0, 0, 0, 0.15);
  position: relative;
}
.team-skills-one__progress__inner {
  position: absolute;
  height: calc(100% - 6px);
  left: 4px;
  top: 3px;
  background-color: var(--hiredots-base, #00cde5);
  transition: all 700ms linear;
  width: 0px;
}
.team-skills-one__progress__number {
  position: absolute;
  bottom: calc(100% + 5px);
  right: 0;
  font-size: 14px;
  font-weight: 400;
}


.team-form-one {
  position: relative;
  padding: 120px 0;
}
@media (max-width: 767px) {
  .team-form-one {
    padding: 80px 0;
  }
}
.team-form-one__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  opacity: 0.8;
  mix-blend-mode: luminosity;
}
.team-form-one .container {
  position: relative;
  max-width: 800px;
}
.team-form-one .form-one .bootstrap-select > .dropdown-toggle,
.team-form-one .form-one input[type=text],
.team-form-one .form-one input[type=email],
.team-form-one .form-one textarea {
  background-color: var(--hiredots-gray, #f0f3f9);
}
.team-form-one .form-one textarea {
  height: 188px;
}

.team-history-one {
  padding-top: 210px;
  padding-bottom: 114px;
  margin-top: -120px;
  background-size: cover;
}
.team-history-one__title {
  font-size: 30px;
  font-weight: 800;
  letter-spacing: var(--hiredots-letter-space-sm, -0.04em);
}
@media (min-width: 768px) {
  .team-history-one__title {
    font-size: 35px;
  }
}
@media (min-width: 992px) {
  .team-history-one__title {
    font-size: 46px;
  }
}
.team-history-one__innter {
  margin-top: 60px;
}
@media (min-width: 768px) {
  .team-history-one__innter {
    border-top: 1px solid var(--hiredots-border-color-2, #ced5dd);
  }
}
.team-history-one__date {
  color: var(--hiredots-base, #00cde5);
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  line-height: 34px;
  margin-bottom: 3px;
  display: inline-block;
  letter-spacing: var(--hiredots-letter-space, 0.1em);
}
.team-history-one__progress {
  position: relative;
  padding-top: 26px;
}
.team-history-one__progress:hover::before {
  border: 1px solid var(--hiredots-base, #00cde5);
  background-color: var(--hiredots-gray, #f0f3f9);
}
.team-history-one__progress::before {
  position: absolute;
  content: "";
  width: 15px;
  height: 15px;
  display: inline-block;
  top: -7.5px;
  border-radius: 50%;
  background-color: var(--hiredots-base, #00cde5);
}
.team-history-one__progress__title {
  color: var(--hiredots-black, #0b182a);
  font-size: 20px;
  font-weight: 800;
  letter-spacing: var(--hiredots-letter-space-sm, -0.04em);
  margin-bottom: 8px;
}
.team-history-one__text {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
}

.blog-card {
  position: relative;
  background-color: var(--hiredots-white, #fff);
  margin-right: 30px;
}
.blog-card__image {
  position: relative;
  overflow: hidden;
}
.blog-card__image img {
  transition: 0.5s;
  background-size: cover;
  width: 100%;
}
.blog-card__image__link {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: rgba(var(--hiredots-black-rgb, 11, 24, 42), 0.5);
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transform: translateY(-20%);
  transition: opacity 500ms ease, transform 500ms ease;
}
.blog-card__image__link::before, .blog-card__image__link::after {
  content: "";
  width: 32px;
  height: 2px;
  background-color: var(--hiredots-white, #fff);
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.blog-card__image__link::after {
  transform: translate(-50%, -50%) rotate(90deg);
}
.blog-card__info {
  margin-bottom: 21px;
}
.blog-card:hover .blog-card__image img {
  transform: scale(1.1);
}
.blog-card__content {
  background-color: var(--hiredots-white, #fff);
  position: relative;
  transition: all 500ms ease;
}
.blog-card__date {
  width: 66px;
  height: 67px;
  background-color: var(--hiredots-base, #00cde5);
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-size: 14px;
  color: var(--hiredots-white, #fff);
  padding: 0 20px;
  line-height: 1.2em;
  position: absolute;
  top: -27px;
  right: 0;
  z-index: 10;
  font-weight: 800;
  flex-direction: column;
}
.blog-card__date span {
  display: block;
}
.blog-card__content {
  margin-right: 40px;
  background-color: var(--hiredots-white, #fff);
  padding: 25px 40px 18px;
  margin-top: -40px;
  position: relative;
  z-index: 10;
  transition: all 500ms ease;
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
}
@media (max-width: 1199px) {
  .blog-card__content {
    margin-right: 0;
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (max-width: 991px) {
  .blog-card__content {
    margin-right: 30px;
  }
}
.blog-card:hover .blog-card__content {
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
}
.blog-card__title {
  margin: 0;
  color: var(--hiredots-black, #0b182a);
  font-size: 20px;
  margin-bottom: 20px;
  letter-spacing: -0.055em;
}
@media (min-width: 768px) {
  .blog-card__title {
    font-size: 22px;
  }
}
.blog-card__title a {
  color: inherit;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.blog-card__title a:hover {
  background-size: 100% 1px;
}
.blog-card__title a:hover {
  color: var(--hiredots-base, #00cde5);
}
.blog-card__link {
  display: flex;
  align-items: center;
  text-transform: capitalize;
  font-size: 13px;
  font-weight: 700;
  color: var(--hiredots-black, #0b182a);
  transition: all 500ms ease;
  line-height: 1em;
  position: relative;
  border-top: 2px solid var(--hiredots-border-color, #cbd2da);
  padding-top: 16px;
}
.blog-card__link::before {
  content: "";
  width: 92px;
  height: 2px;
  background-color: var(--hiredots-primary, #0b2f81);
  position: absolute;
  left: 0;
  top: -2px;
  transition: 0.3s;
}
.blog-card__link:hover {
  color: var(--hiredots-base, #00cde5);
}
.blog-card__link:hover::before {
  width: 100%;
}
.blog-card__link i {
  font-size: 16px;
  margin-left: 10px;
}
.blog-card__meta {
  display: flex;
  align-items: center;
  margin: 0;
  margin-bottom: 9px;
}
.blog-card__meta li {
  color: var(--hiredots-text, #696e76);
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.blog-card__meta li i {
  color: var(--hiredots-base, #00cde5);
  margin-right: 3px;
  font-size: 13px;
}
.blog-card__meta li a {
  display: flex;
  align-items: center;
  color: inherit;
  transition: all 500ms ease;
}
.blog-card__meta li a:hover {
  color: var(--hiredots-base, #00cde5);
  text-shadow: 0 0 1px currentColor;
}
.blog-card__meta li + li {
  margin-left: 9px;
}
.blog-card__meta img {
  border-radius: 100%;
  margin-right: 10px;
  width: 24px !important;
}

.blog-one {
  padding: 120px 0;
}
@media (max-width: 767px) {
  .blog-one {
    padding: 80px 0;
  }
}
@media (min-width: 992px) {
  .blog-one__carousel .owl-nav {
    display: none;
  }
}

.blog-card-two .blog-card__image {
  margin-bottom: 27px;
}
.blog-card-two .blog-card__meta {
  margin-bottom: 6px;
}
.blog-card-two .blog-card__title {
  font-size: 25px;
  border: none;
  padding-bottom: 0;
  margin-bottom: 0;
}
@media (min-width: 992px) {
  .blog-card-two .blog-card__title {
    font-size: 30px;
  }
}
.blog-card-two .blog-card__date {
  bottom: 0;
}
.blog-card-two__text {
  margin: 0;
  font-size: 15px;
  line-height: 2em;
  margin-top: 12px;
}

.blog-card-link,
.blog-card-qoute {
  background-color: var(--hiredots-gray, #f0f3f9);
  padding: 30px;
}
@media (min-width: 768px) {
  .blog-card-link,
  .blog-card-qoute {
    padding: 60px;
  }
}
.blog-card-link .blog-card__title,
.blog-card-qoute .blog-card__title {
  margin: 0;
  margin-bottom: -10px;
}

.blog-card-qoute__text {
  margin: 0;
  margin-bottom: -5px;
}

.blog-card-qoute__image {
  line-height: 1em;
  margin-bottom: 20px;
}

.blog-card-link__icon {
  font-size: 40px;
  color: var(--hiredots-base, #00cde5);
  line-height: 1em;
  margin-bottom: 22px;
}

/*--------------------------------------------------------------
# Form
--------------------------------------------------------------*/
.form-one__group {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  margin: 0;
}
@media (min-width: 576px) {
  .form-one__group {
    grid-template-columns: repeat(2, 1fr);
  }
}
.form-one__control {
  border: none;
  width: auto;
  height: auto;
  border-radius: 0;
  padding: 0;
  position: relative;
}
.form-one__control__icon {
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
  font-size: 14px;
}
.form-one__control--full {
  grid-column-start: 1;
  grid-column-end: -1;
}
.form-one .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
  width: 100%;
  height: 58px;
  display: flex;
  align-items: center;
}
.form-one .bootstrap-select > .dropdown-toggle {
  padding: 0;
  background-color: transparent;
  border-radius: 0;
  border: none;
  outline: none !important;
  color: var(--hiredots-text, #696e76);
  font-size: 14px;
}
.form-one .bootstrap-select > .dropdown-toggle,
.form-one input[type=text],
.form-one input[type=email],
.form-one textarea {
  display: block;
  width: 100%;
  height: 58px;
  background-color: var(--hiredots-gray, #f0f3f9);
  color: var(--hiredots-text, #696e76);
  font-size: 14px;
  font-weight: 500;
  border: none;
  outline: none;
  padding-left: 30px;
  padding-right: 30px;
}
.form-one textarea {
  height: 195px;
  padding-top: 20px;
}
.form-one .bootstrap-select > .dropdown-toggle {
  display: flex;
  align-items: center;
}
.form-one .bootstrap-select > .dropdown-toggle .filter-option {
  display: flex;
  align-items: center;
}

/*--------------------------------------------------------------
# Custom Cursor
--------------------------------------------------------------*/
.custom-cursor__cursor {
  width: 25px;
  height: 25px;
  border-radius: 100%;
  border: 1px solid var(--hiredots-base, #00cde5);
  -webkit-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
  position: fixed;
  pointer-events: none;
  left: 0;
  top: 0;
  -webkit-transform: translate(calc(-50% + 5px), -50%);
  transform: translate(calc(-50% + 5px), -50%);
  z-index: 999991;
}
.custom-cursor__cursor-two {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: var(--hiredots-base, #00cde5);
  opacity: 0.3;
  position: fixed;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  pointer-events: none;
  -webkit-transition: width 0.3s, height 0.3s, opacity 0.3s;
  transition: width 0.3s, height 0.3s, opacity 0.3s;
  z-index: 999991;
}
.custom-cursor__hover {
  background-color: var(--hiredots-base, #00cde5);
  opacity: 0.4;
}
.custom-cursor__innerhover {
  width: 25px;
  height: 25px;
  opacity: 0.4;
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.main-footer {
  position: relative;
}
.main-footer__top {
  padding-top: 80px;
  padding-bottom: 60px;
}
@media (max-width: 767px) {
  .main-footer__top {
    padding-bottom: 40px;
  }
}
.main-footer__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;
}
.main-footer .container {
  position: relative;
  z-index: 2;
}
.main-footer__bottom {
  position: relative;
  text-align: center;
  border-top: 1px solid rgba(var(--hiredots-white-rgb, 255, 255, 255), 0.1);
}
.main-footer__bottom__inner {
  padding: 33px 0;
}
.main-footer__copyright {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  color: var(--hiredots-text-dark, #7e8c9e);
}

.main-footer__subscribe__wrapper {
  display: block;
  align-items: center;
  justify-content: space-between;
  background-color: var(--hiredots-primary, #0b2f81);
  padding: 50px 30px;
}
@media (min-width: 768px) {
  .main-footer__subscribe__wrapper {
    display: flex;
    padding: 0;
  }
}
.main-footer__subscribe__left {
  display: block;
  align-items: center;
  flex-shrink: 0;
  margin-right: 50px;
}
@media (min-width: 768px) {
  .main-footer__subscribe__left {
    display: flex;
  }
}
.main-footer__subscribe__right {
  width: 100%;
  display: flex;
  justify-content: end;
}
.main-footer__subscribe__icon {
  height: 135px;
  width: 135px;
  line-height: 135px;
  margin-right: 50px;
  text-align: center;
  display: inline-block;
  background-color: var(--hiredots-base, #00cde5);
  color: var(--hiredots-white, #fff);
  font-size: 54px;
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .main-footer__subscribe__icon {
    margin-bottom: 0;
  }
}
.main-footer__subscribe__title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  color: var(--hiredots-white, #fff);
  letter-spacing: var(--hiredots-letter-space-sm, -0.04em);
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .main-footer__subscribe__title {
    margin-bottom: 0;
  }
}
.main-footer__newsletter {
  max-width: 540px;
  width: 100%;
  position: relative;
  margin-right: 0px;
}
@media (min-width: 768px) {
  .main-footer__newsletter {
    margin-right: 60px;
  }
}
.main-footer__newsletter input {
  font-weight: 500;
  color: var(--hiredots-white, #fff);
  background-color: transparent;
  border: 0;
  padding-bottom: 10px;
  border-bottom: 3px solid rgba(var(--hiredots-white-rgb, 255, 255, 255), 0.2);
  width: 100%;
}
.main-footer__newsletter input:focus {
  outline: 0;
}
.main-footer__newsletter button {
  position: absolute;
  right: 0;
  font-size: 26px;
  color: var(--hiredots-white, #fff);
  background-color: transparent;
  border-color: transparent;
  transition: all 500ms ease;
}
.main-footer__newsletter button:hover {
  color: var(--hiredots-base, #00cde5);
}

.footer-widget {
  margin-bottom: 40px;
}
.footer-widget__logo {
  display: inline-flex;
  margin-bottom: 20px;
}
.footer-widget__title {
  font-size: 20px;
  font-weight: bold;
  color: var(--hiredots-white, #fff);
  margin: 0;
  margin-top: -5px;
  margin-bottom: 15px;
}
.footer-widget__links {
  font-size: 15px;
  line-height: 30px;
  font-weight: 500;
}
.footer-widget__links li {
  font-size: 15px;
  color: var(--hiredots-text-dark, #7e8c9e);
  font-weight: 700;
  line-height: 36px;
}
.footer-widget__links li a {
  color: inherit;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.footer-widget__links li a:hover {
  background-size: 100% 1px;
}
.footer-widget__links li a:hover {
  color: var(--hiredots-white, #fff);
}
.footer-widget__info i {
  margin-right: 10px;
  color: var(--hiredots-base, #00cde5);
}
.footer-widget__info a {
  color: var(--hiredots-white, #fff);
  font-size: 15px;
  font-weight: 700;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.footer-widget__info a:hover {
  background-size: 100% 1px;
}
.footer-widget__text {
  font-size: 15px;
  color: var(--hiredots-text-dark, #7e8c9e);
  font-weight: 500;
  line-height: 30px;
  margin: 0;
  margin-bottom: 21px;
}
.footer-widget__social {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}
.footer-widget__social a {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--hiredots-black2, #12243c);
  font-size: 14px;
  color: var(--hiredots-white, #fff);
  transition: all 500ms ease;
  border-radius: 50%;
}
.footer-widget__social a:hover {
  background-color: var(--hiredots-base, #00cde5);
  color: var(--hiredots-white, #fff);
}
.footer-widget__author {
  display: flex;
  align-items: center;
  margin-top: -10px;
}
.footer-widget__author__img {
  margin-right: 20px;
  display: inline-block;
  border: 2px solid var(--hiredots-base, #00cde5);
  border-radius: 50%;
  padding: 5px;
}
.footer-widget__author__tagline {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  color: var(--hiredots-text-dark, #7e8c9e);
  letter-spacing: var(--hiredots-letter-space, 0.1em);
}
.footer-widget__author__title {
  font-size: 18px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: var(--hiredots-letter-space-sm, -0.04em);
}
.footer-widget__author__title a {
  color: var(--hiredots-white, #fff);
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.footer-widget__author__title a:hover {
  background-size: 100% 1px;
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact-one {
  position: relative;
  padding: 120px 0;
}
@media (max-width: 767px) {
  .contact-one {
    padding: 80px 0;
  }
}
.contact-one__content {
  position: relative;
}
.contact-one__content__shape-1 {
  position: absolute;
  top: 0;
  right: 0;
}
.contact-one__content__shape-2 {
  position: absolute;
  bottom: 0;
  right: 0;
}
.contact-one__inner {
  position: relative;
}
.contact-one__inner-shape {
  position: absolute;
  right: -285px;
  bottom: -83px;
  z-index: -1;
}
.contact-one__inner-shape img {
  width: 100%;
  height: auto;
  -webkit-animation-name: float-bob-y-2;
  animation-name: float-bob-y-2;
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}
.contact-one__left {
  margin-top: 50px;
}
@media (min-width: 1200px) {
  .contact-one__left {
    margin-top: 0;
    padding-left: 70px;
  }
}
.contact-one__text {
  margin: 0;
  margin-top: -30px;
  font-size: 15px;
  line-height: 2em;
  margin-bottom: 40px;
  position: relative;
}
.contact-one__info {
  margin-bottom: 0;
  position: relative;
}
.contact-one__info__content {
  margin-top: 8px;
}
.contact-one__info__item {
  display: block;
}
@media (min-width: 576px) {
  .contact-one__info__item {
    display: flex;
    align-items: center;
  }
}
.contact-one__info__item + .contact-one__info__item {
  margin-top: 40px;
}
@media (min-width: 576px) {
  .contact-one__info__item + .contact-one__info__item {
    margin-top: 10px;
  }
}
.contact-one__info__icon {
  width: 80px;
  height: 80px;
  background-color: var(--hiredots-base, #00cde5);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  transition: all 500ms ease;
  color: var(--hiredots-white, #fff);
  margin-right: 30px;
  flex-shrink: 0;
}
.contact-one__info__icon:hover {
  background-color: var(--hiredots-base, #00cde5);
  color: var(--hiredots-white, #fff);
}
.contact-one__info__text {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
}
.contact-one__info__title {
  font-size: 20px;
  color: var(--hiredots-text, #696e76);
  line-height: 30px;
}
.contact-one__info__title a {
  color: var(--hiredots-black, #0b182a);
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.contact-one__info__title a:hover {
  background-size: 100% 1px;
}
.contact-one__form {
  padding: 60px 30px;
  position: relative;
  z-index: 10;
  background-color: #204478;
  overflow: hidden;
}
.contact-one__form::before {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  content: url(../images/shapes/contact-page-shape.png);
  mix-blend-mode: multiply;
}
@media (min-width: 768px) {
  .contact-one__form {
    padding: 60px;
  }
}
.contact-one__form .form-one__group {
  grid-gap: 20px;
  position: relative;
}
.contact-one__form .form-one__control__icon {
  right: 0;
  color: var(--hiredots-white, #fff);
}
.contact-one__form .bootstrap-select > .dropdown-toggle,
.contact-one__form input[type=text],
.contact-one__form input[type=email],
.contact-one__form textarea {
  padding: 0;
  height: 58px;
  padding: 30px;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid rgba(var(--hiredots-white-rgb, 255, 255, 255), 0.15);
  color: var(--hiredots-white, #fff);
}
.contact-one__form .bootstrap-select > .dropdown-toggle#datepicker,
.contact-one__form input[type=text]#datepicker,
.contact-one__form input[type=email]#datepicker,
.contact-one__form textarea#datepicker {
  cursor: pointer;
}
.contact-one__form textarea {
  height: 150px;
  padding-top: 20px;
}

.contact-two {
  position: relative;
  background-color: var(--hiredots-white, #fff);
}
.contact-two__bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 85.8%;
  height: 100%;
  background-position: left top;
  background-repeat: no-repeat;
  background-size: auto;
  clip-path: polygon(43% 0, 100% -3%, 57.5% 100%, 0% 100%);
}
@media (max-width: 991px) {
  .contact-two__bg {
    width: 100%;
    clip-path: none;
    background-size: cover;
  }
}
.contact-two__bg::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: rgba(var(--hiredots-primary-rgb, 11, 47, 129), 0.9);
}
.contact-two__form {
  position: relative;
  background-color: var(--hiredots-white, #fff);
  max-width: 500px;
  padding: 46px 60px 60px;
  border-top: 5px solid var(--hiredots-base, #00cde5);
  margin-top: -60px;
}
@media (max-width: 1199px) {
  .contact-two__form {
    max-width: 360px;
    padding: 46px 30px 60px;
  }
}
@media (max-width: 991px) {
  .contact-two__form {
    margin: 80px 0 0;
    max-width: 100%;
  }
}
@media (max-width: 767px) {
  .contact-two__form {
    margin: 80px 0 0;
    padding: 46px 30px 60px;
  }
}
.contact-two .sec-title__tagline::before {
  display: none;
}
.contact-two .sec-title__title {
  font-size: 36px;
}
.contact-two .sec-title {
  padding-bottom: 22px;
}
.contact-two .form-two__group {
  position: relative;
}
.contact-two .form-two__control {
  position: relative;
  margin: 0 0 20px;
  line-height: 1;
}
.contact-two .form-two__control__icon {
  right: 0;
  color: var(--hiredots-white, #fff);
}
.contact-two .bootstrap-select > .dropdown-toggle,
.contact-two input[type=text],
.contact-two input[type=email],
.contact-two textarea {
  padding: 0;
  height: 58px;
  padding: 0 30px;
  background-color: var(--hiredots-gray, #f0f3f9);
  border: none;
  outline: none;
  box-shadow: none;
  color: var(--hiredots-text, #696e76);
  font-size: 14px;
  font-weight: 500;
  width: 100%;
}
.contact-two .bootstrap-select > .dropdown-toggle#datepicker,
.contact-two input[type=text]#datepicker,
.contact-two input[type=email]#datepicker,
.contact-two textarea#datepicker {
  cursor: pointer;
}
.contact-two textarea {
  height: 115px;
  padding-top: 20px;
}
.contact-two .col-lg-6 {
  position: relative;
}
.contact-two__info {
  position: absolute;
  left: -35px;
  top: 70px;
}
@media (max-width: 1199px) {
  .contact-two__info {
    left: -80px;
    top: 45px;
    max-width: 290px;
    word-break: break-all;
  }
}
@media (max-width: 991px) {
  .contact-two__info {
    position: relative;
    left: 0;
    top: 0;
    margin: 50px 0 30px;
  }
}
.contact-two .contact-one__info__text {
  color: #8faad1;
  font-weight: 500;
}
.contact-two .contact-one__info__title {
  color: var(--hiredots-white, #fff);
  font-size: 18px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .contact-two .contact-one__info__title {
    line-height: 24px;
  }
}
.contact-two .contact-one__info__title a {
  color: inherit;
}
.contact-two .contact-one__info__item + .contact-one__info__item {
  margin-top: 20px;
}
.contact-two__social {
  display: flex;
  gap: 10px;
  margin: 30px 0 20px;
}
.contact-two__social a {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #163563;
  font-size: 14px;
  color: var(--hiredots-white, #fff);
  transition: all 500ms ease;
  border-radius: 50%;
}
.contact-two__social a:hover {
  background-color: var(--hiredots-base, #00cde5);
  color: var(--hiredots-white, #fff);
}
.contact-two__label {
  color: var(--hiredots-white, #fff);
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 4px;
  font-weight: 600;
  margin: 0;
  transform: rotate(90deg);
  position: absolute;
  left: -75px;
  bottom: -167px;
}
.contact-two__map {
  position: relative;
  overflow: hidden;
  clip-path: polygon(72% 0, 100% 0%, 100% 100%, 0% 100%);
}
@media (max-width: 991px) {
  .contact-two__map {
    clip-path: none;
  }
}
.contact-two__map .google-map iframe {
  height: 670px;
}
@media (max-width: 991px) {
  .contact-two__map .google-map iframe {
    height: 500px;
  }
}

.contact {
  position: relative;
  background-color: var(--hiredots-black, #0b182a);
  padding: 120px 0;
}
@media (max-width: 767px) {
  .contact {
    padding: 80px 0;
  }
}
.contact__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--hiredots-black, #0b182a);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.contact__shape {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: auto;
}
@media (max-width: 767px) {
  .contact__shape {
    background-size: cover;
  }
}
.contact .sec-title__title {
  color: var(--hiredots-white, #fff);
}
.contact .sec-title {
  padding-bottom: 34px;
}
.contact__form-box {
  position: relative;
  display: block;
  padding: 0;
}
.contact__form {
  position: relative;
  display: block;
}
.contact__form .row {
  --bs-gutter-x: 20px;
}
.contact__input-box {
  position: relative;
  display: block;
  margin-bottom: 16px;
}
.contact__input-box i {
  position: absolute;
  right: 0;
  color: var(--hiredots-white, #fff);
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  z-index: -1;
}
.contact__input-box input[type=date],
.contact__input-box input[type=text],
.contact__input-box input[type=email] {
  height: 62px;
  width: 100%;
  border: none;
  background-color: transparent;
  padding-left: 0;
  padding-right: 0;
  outline: none;
  border-bottom: 2px solid rgba(var(--hiredots-white-rgb, 255, 255, 255), 0.15);
  font-size: 14px;
  color: var(--hiredots-white, #fff);
  font-family: var(--hiredots-font, "Manrope", sans-serif);
  display: block;
  font-weight: 500;
}
.contact__input-box input[type=date]#datepicker,
.contact__input-box input[type=text]#datepicker,
.contact__input-box input[type=email]#datepicker {
  cursor: pointer;
}
.contact__input-box .bootstrap-select .dropdown-menu {
  border: none;
}
.contact__input-box .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
  position: relative;
  display: block;
  width: 100% !important;
  font-family: var(--hiredots-font, "Manrope", sans-serif);
}
.contact__input-box .bootstrap-select > .dropdown-toggle::after {
  display: none;
}
.contact__input-box .bootstrap-select > .dropdown-toggle {
  position: relative;
  height: 62px;
  outline: none !important;
  border-radius: 0;
  border: 0;
  background-color: transparent !important;
  margin: 0;
  padding: 0;
  padding-left: 0;
  padding-right: 0;
  color: var(--hiredots-white, #fff) !important;
  font-size: 14px;
  line-height: 60px;
  font-weight: 500;
  box-shadow: none !important;
  background-repeat: no-repeat;
  background-size: 14px 12px;
  background-position: right 25.75px center;
  border-bottom: 2px solid rgba(var(--hiredots-white-rgb, 255, 255, 255), 0.15);
}
.contact__input-box .bootstrap-select > .dropdown-toggle:before {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  font-family: "Font Awesome 5 Free";
  content: "\f107";
  font-weight: 600;
  font-size: 14px;
  color: var(--hiredots-white, #fff);
}
.contact__input-box .bootstrap-select .dropdown-menu > li + li > a {
  border-top: 1px solid var(--hiredots-border-color, #cbd2da);
}
.contact__input-box .bootstrap-select .dropdown-menu > li > a {
  font-size: 14px;
  font-weight: 500;
  padding: 10px 30px;
  color: var(--hiredots-text, #696e76);
  background-color: var(--hiredots-white, #fff);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.contact__input-box .bootstrap-select .dropdown-menu > li:hover > a,
.contact__input-box .bootstrap-select .dropdown-menu > li.selected > a {
  background: var(--hiredots-base, #00cde5);
  color: var(--hiredots-white, #fff);
  border-color: var(--hiredots-base, #00cde5);
}
.contact__input-box textarea {
  font-size: 14px;
  font-weight: 500;
  color: var(--hiredots-white, #fff);
  height: 112px;
  width: 100%;
  background-color: transparent;
  font-family: var(--hiredots-font, "Manrope", sans-serif);
  padding: 0;
  border: none;
  outline: none;
  margin-bottom: 0px;
  border-bottom: 2px solid rgba(var(--hiredots-white-rgb, 255, 255, 255), 0.15);
}
.contact__input-box.text-message-box {
  height: 112px;
  margin-top: 21px;
  margin-bottom: 18px;
}

/*--------------------------------------------------------------
# Topbar
--------------------------------------------------------------*/
.topbar-one {
  display: none;
  position: relative;
  z-index: 11;
}
@media (min-width: 1300px) {
  .topbar-one {
    margin-bottom: -40px;
  }
}
@media (min-width: 1200px) {
  .topbar-one {
    margin-bottom: -30px;
    display: block;
  }
}
.topbar-one .container-fluid {
  max-width: 1300px;
}
@media (max-width: 1500px) {
  .topbar-one .container-fluid {
    max-width: 930px;
  }
}
.topbar-one__inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 14px;
  padding-bottom: 13px;
}
@media (min-width: 992px) {
  .topbar-one__inner {
    flex-direction: row;
  }
}
.topbar-one__info {
  display: flex;
  align-items: center;
  margin: 0;
}
.topbar-one__info__item {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 1.2em;
}
.topbar-one__info__item i {
  color: var(--hiredots-base, #00cde5);
  margin-right: 8px;
  position: relative;
  top: 1.5px;
}
.topbar-one__info__item a {
  color: inherit;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.topbar-one__info__item a:hover {
  background-size: 100% 1px;
}
.topbar-one__info__item + .topbar-one__info__item {
  margin-left: 20px;
}
.topbar-one__info__icon {
  font-size: 14px;
  color: var(--hiredots-base, #00cde5);
  position: relative;
  top: 2px;
  margin-right: 9px;
}
.topbar-one__nav ul {
  padding-bottom: 0;
  margin-bottom: 0;
  list-style: none;
}
.topbar-one__nav ul li {
  display: inline-block;
}
.topbar-one__nav ul li:not(:first-child)::before {
  content: "/";
  padding-right: 5px;
  margin-left: 5px;
}
.topbar-one__nav ul li a {
  color: var(--hiredots-text, #696e76);
  font-size: 14px;
}
.topbar-one__nav ul li a:hover {
  color: var(--hiredots-base, #00cde5);
}
.topbar-one__right {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
@media (min-width: 992px) {
  .topbar-one__right {
    margin-top: 0;
    margin-left: auto;
  }
}
.topbar-one__text {
  margin: 0;
  font-size: 14px;
  line-height: 1.2em;
}
.topbar-one__social {
  display: flex;
  align-items: center;
  padding: 3.5px 0;
  padding-left: 30px;
  line-height: 1em;
}
.topbar-one__social a {
  font-size: 11px;
  color: var(--hiredots-text, #696e76);
  transition: all 500ms ease;
  width: 26px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  background-color: var(--hiredots-gray, #f0f3f9);
  border-radius: 50%;
}
.topbar-one__social a:hover {
  color: var(--hiredots-white, #fff);
  background-color: var(--hiredots-base, #00cde5);
}
.topbar-one__social a + a {
  margin-left: 10px;
}
.topbar-one--two {
  position: relative;
  z-index: 2;
  background-color: var(--hiredots-black, #0b182a);
}
.topbar-one--two .topbar-one__inner {
  padding-top: 17px;
  padding-bottom: 15px;
}
.topbar-one--two .topbar-one__info__item {
  color: var(--hiredots-text-dark, #7e8c9e);
}
.topbar-one--two .topbar-one__info__item a:hover {
  color: var(--hiredots-base, #00cde5);
}
.topbar-one--two .topbar-one__nav ul li a {
  color: var(--hiredots-text-dark, #7e8c9e);
}
.topbar-one--two .topbar-one__nav ul li a:hover {
  color: var(--hiredots-base, #00cde5);
}
@media (min-width: 1200px) {
  .topbar-one--two {
    margin-bottom: -40px;
  }
}
@media (max-width: 1299px) {
  .topbar-one--two {
    margin-bottom: -26px;
  }
  .topbar-one--two .container-fluid {
    margin-left: auto;
    margin-right: 11px;
  }
}
@media (min-width: 1200px) and (max-width: 1500px) {
  .topbar-one--two .topbar-one__info {
    margin-left: -50px;
  }
}

/*--------------------------------------------------------------
# Navigations
--------------------------------------------------------------*/
.main-header {
  padding: 0 10px;
}
@media (min-width: 576px) {
  .main-header {
    padding: 0;
  }
}
.main-header .container-fluid {
  max-width: 1920px;
}
.main-header__call i {
  font-size: 19px;
  color: var(--hiredots-primary, #0b2f81);
  padding-right: 15px;
  margin-right: 15px;
  border-right: 1px solid #ced5dd;
  position: relative;
  top: 5px;
}
.main-header__call a {
  font-size: 14px;
  color: var(--hiredots-black, #0b182a);
  font-weight: 700;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.main-header__call a:hover {
  background-size: 100% 1px;
}
.main-header__call a:hover {
  color: var(--hiredots-base, #00cde5);
}
@media only screen and (min-width: 1300px) and (max-width: 1500px) {
  .main-header__call {
    display: none;
  }
}
.main-header__inner {
  display: flex;
  align-items: center;
  background-color: var(--hiredots-white, #fff);
  padding: 30px 0;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  justify-content: space-between;
}
@media (max-width: 1199px) {
  .main-header__inner {
    padding: 20px 30px;
    align-items: center;
  }
}
@media (min-width: 1200px) {
  .main-header__inner {
    align-items: end;
  }
}
@media (min-width: 1200px) {
  .main-header__inner {
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
    padding-left: 54px;
  }
}
@media (min-width: 1200px) and (max-width: 1320px) {
  .main-header__inner {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (max-width: 767px) {
  .main-header__inner {
    padding: 20px 0;
  }
}
.main-header__logo {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.main-header__logo a{
  text-decoration: none;
}

@media (min-width: 768px) {
  .main-header__logo {
    width: auto;
  }
}
@media (min-width: 1200px) {
  .main-header__logo {
    margin-bottom: 33px;
    margin-right: 57px;
  }
}
@media only screen and (min-width: 1300px) and (max-width: 1500px) {
  .main-header__logo {
    margin-bottom: 10px;
  }
}
@media (min-width: 768px) {
  .main-header__logo .mobile-nav__btn {
    margin-left: 30px;
  }
}
.main-header__menu {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-right: auto;
  background-color: var(--hiredots-gray, #f0f3f9);
  padding: 0 50px;
  display: none;
}
@media (min-width: 1200px) {
  .main-header__menu {
    display: flex;
    margin-right: -45px;
    position: relative;
    z-index: 12;
  }
}
.main-header__btn {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.2;
  text-transform: uppercase;
  background-color: var(--hiredots-base, #00cde5);
  display: inline-block;
  color: var(--hiredots-white, #fff);
  padding: 38px 90px;
  display: none;
}
.main-header__btn:hover {
  background-color: var(--hiredots-primary, #0b2f81);
}
@media (min-width: 1300px) {
  .main-header__btn {
    display: block;
  }
}
.main-header__right {
  display: flex;
  align-items: center;
  border-left: 1px solid var(--hiredots-border-color, #cbd2da);
  margin-left: 10px;
  padding: 9.5px 0;
}
@media (min-width: 768px) {
  .main-header__right {
    margin-left: auto;
    padding: 0;
    padding-left: 10px;
  }
}
@media (min-width: 1200px) {
  .main-header__right {
    margin-left: 0;
  }
}
.main-header__cart, .main-header__search {
  font-size: 24px;
  color: var(--hiredots-white, #fff);
  transition: all 500ms ease;
  margin-right: 10px;
  width: 70px;
  height: 70px;
  line-height: 70px;
  background-color: var(--hiredots-primary, #0b2f81);
  display: inline-block;
  flex-shrink: 0;
  text-align: center;
  display: none;
}
@media (min-width: 1200px) {
  .main-header__cart, .main-header__search {
    display: inline-block;
  }
}
.main-header__cart:hover, .main-header__search:hover {
  background-color: var(--hiredots-base, #00cde5);
  color: var(--hiredots-white, #fff);
}
.main-header--two .main-header__inner {
  background-color: var(--hiredots-black, #0b182a);
  padding-left: 80px;
}
@media (max-width: 1500px) {
  .main-header--two .main-header__inner {
    padding-left: 30px;
  }
}
@media (max-width: 1299px) {
  .main-header--two .main-header__inner {
    padding-right: 70px;
  }
}
@media (max-width: 1199px) {
  .main-header--two .main-header__inner {
    padding-right: 15px;
  }
}
.main-header--two .main-header__inner .mobile-nav__btn span {
  background-color: var(--hiredots-white, #fff);
}
@media (max-width: 767px) {
  .main-header--two {
    padding-left: 0;
    padding-right: 0;
  }
}
.main-header--two .main-header__menu {
  max-width: 1270px;
  background-color: var(--hiredots-white, #fff);
  padding-right: 0;
}
.main-header--two .main-header__call {
  margin-left: auto;
}
.main-header--two .main-header__search {
  margin: 0 0 0 30px;
  background-color: var(--hiredots-gray, #f0f3f9);
  color: var(--hiredots-black, #0b182a);
}
.main-header--two .main-header__search:hover {
  background-color: var(--hiredots-base, #00cde5);
  color: var(--hiredots-white, #fff);
}
.main-header--two .main-header__logo {
  position: relative;
  z-index: 3;
}
@media (min-width: 1200px) {
  .main-header--two .main-header__logo {
    margin-right: 33px;
  }
}
@media (min-width: 1200px) and (max-width: 1500px) {
  .main-header--two .main-header__logo {
    margin-bottom: 33px;
  }
}
.main-header--two .main-header__btn {
  position: relative;
  z-index: 3;
  background-color: transparent;
  font-weight: 800;
  text-align: right;
  border-left: 3px solid var(--hiredots-black2, #12243c);
  padding: 48.3px 80px;
  margin-top: -20px;
}
@media (min-width: 1200px) and (max-width: 1500px) {
  .main-header--two .main-header__btn {
    padding: 48.3px 56px;
    margin-left: 60px;
  }
}
.main-header--two .main-header__btn:hover {
  background-color: var(--hiredots-base, #00cde5);
  color: var(--hiredots-white, #fff);
}
.main-header--two.sticky-header--cloned .main-header__inner {
  background-color: var(--hiredots-white, #fff);
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  justify-content: center;
}
.main-header--two.sticky-header--cloned .main-header__menu {
  margin-right: 0;
}
.main-header--two.sticky-header--cloned .main-header__logo,
.main-header--two.sticky-header--cloned .main-header__link {
  display: none;
}
@media (max-width: 1199px) {
  .main-header--two.sticky-header--cloned.main-header--two .main-header__logo {
    display: block;
  }
  .main-header--two.sticky-header--cloned.main-header--two .main-header__inner {
    background-color: var(--hiredots-black, #0b182a);
    justify-content: space-between;
    padding: 20px 20px;
  }
}
@media (max-width: 767px) {
  .main-header--two.sticky-header--cloned {
    padding-left: 0;
    padding-right: 0;
  }
}
.main-header--three {
  padding: 0;
  z-index: 99;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
.main-header--three .main-header__logo {
  margin: 0;
  padding: 18px 0;
}
.main-header--three .main-header__inner {
  width: 100%;
  padding: 0 80px;
  margin: 0;
  background: transparent;
  border-bottom: 2px solid rgba(var(--hiredots-white-rgb, 255, 255, 255), 0.2);
  align-items: center;
}
@media (max-width: 1499px) {
  .main-header--three .main-header__inner {
    padding: 0 50px;
  }
}
@media (max-width: 1299px) {
  .main-header--three .main-header__inner {
    padding: 0 20px;
  }
}
@media (max-width: 1199px) {
  .main-header--three .main-header__inner {
    padding: 0 15px 0 30px;
  }
}
.main-header--three .main-header__menu {
  margin: 0;
  padding: 0 0 0 155px;
  max-width: 100%;
  width: 100%;
  background-color: transparent;
}
@media (max-width: 1499px) {
  .main-header--three .main-header__menu {
    padding: 0 0 0 55px;
  }
}
.main-header--three .main-menu .main-menu__list > li {
  padding-top: 48px;
  padding-bottom: 50px;
}
.main-header--three .main-menu .main-menu__list > li > a {
  color: var(--hiredots-white, #fff);
}
.main-header--three .main-menu .main-menu__list > li.current > a,
.main-header--three .main-menu .main-menu__list > li:hover > a {
  color: var(--hiredots-base, #00cde5);
}
.main-header--three .mobile-nav__btn span {
  background-color: var(--hiredots-white, #fff);
}
.main-header--three .main-header__link {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  width: 100%;
}
@media (max-width: 1199px) {
  .main-header--three .main-header__link {
    display: none;
  }
}
.main-header--three .main-header__link .main-header__call {
  display: flex;
  align-items: center;
}
.main-header--three .main-header__link .main-header__call i {
  color: var(--hiredots-base, #00cde5);
  top: 0;
  height: 30px;
  padding-top: 5px;
  border-color: rgba(var(--hiredots-border-color-rgb, 203, 210, 218), 0.2);
}
.main-header--three .main-header__link .main-header__call a {
  color: var(--hiredots-white, #fff);
}
.main-header--three .main-header__link .main-header__btn {
  height: 58px;
  padding: 20px 50px;
  display: inline-block;
  font-weight: 800;
  margin-left: 40px;
}
@media (max-width: 1499px) {
  .main-header--three .main-header__link .main-header__btn {
    padding: 20px 30px;
    margin-left: 20px;
  }
}
.main-header--three.sticky-header--cloned {
  background-color: var(--hiredots-black, #0b182a);
}
.main-header--three.sticky-header--cloned .main-header__inner {
  border: none;
}
.main-header--three.sticky-header--cloned .main-menu .main-menu__list > li {
  padding-top: 35px;
  padding-bottom: 35px;
}

.sticky-header--cloned {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  top: 0;
  background-color: var(--hiredots-white, #fff);
  transform: translateY(-100%);
  box-shadow: 0px 3px 18px rgba(var(--hiredots-black-rgb, 11, 24, 42), 0.07);
  transition: 0.6s cubic-bezier(0.24, 0.74, 0.58, 1);
  visibility: hidden;
  transition: transform 500ms ease, visibility 500ms ease;
}
.sticky-header--cloned.active {
  transform: translateY(0%);
  visibility: visible;
}

.mobile-nav__btn {
  width: 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  cursor: pointer;
  z-index: 3;
}
@media (max-width: 1199px) {
  .mobile-nav__btn {
    margin-left: -50px;
    margin-right: 10px;
  }
}
@media (max-width: 767px) {
  .mobile-nav__btn {
    margin-left: -40px;
    margin-right: 10px;
  }
}
@media (min-width: 1200px) {
  .mobile-nav__btn {
    display: none;
  }
}
.mobile-nav__btn span {
  width: 100%;
  height: 2px;
  background-color: var(--hiredots-black, #0b182a);
}
.mobile-nav__btn span:nth-child(2) {
  margin-top: 4px;
  margin-bottom: 4px;
}

.main-menu {
  /* after third level no menu */
}
.main-menu .main-menu__list,
.main-menu .main-menu__list ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  align-items: center;
  display: none;
}
@media (min-width: 1200px) {
  .main-menu .main-menu__list,
  .main-menu .main-menu__list ul {
    display: flex;
  }
}
.main-menu .main-menu__list > li {
  padding-top: 18px;
  padding-bottom: 22px;
  position: relative;
}
.main-menu .main-menu__list > li.dropdown > a {
  position: relative;
}
.main-menu .main-menu__list > li + li {
  margin-left: 52px;
}
@media (max-width: 1400px) {
  .main-menu .main-menu__list > li + li {
    margin-left: 40px;
  }
}
@media (min-width: 1200px) and (max-width: 1300px) {
  .main-menu .main-menu__list > li + li {
    margin-left: 35px;
  }
}
.main-menu .main-menu__list > li > a {
  font-size: 16px;
  display: flex;
  align-items: center;
  color: var(--hiredots-text, #696e76);
  font-weight: 500;
  position: relative;
  transition: all 500ms ease;
}
.main-menu .main-menu__list > li.current > a,
.main-menu .main-menu__list > li:hover > a {
  color: var(--hiredots-primary, #0b2f81);
  font-weight: 700;
}
.main-menu .main-menu__list li ul {
  position: absolute;
  top: 100%;
  left: -25px;
  min-width: 270px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  opacity: 0;
  visibility: hidden;
  transform-origin: top center;
  transform: scaleY(0) translateZ(100px);
  transition: opacity 500ms ease, visibility 500ms ease, transform 700ms ease;
  z-index: 99;
  background-color: var(--hiredots-white, #fff);
  box-shadow: 0px 10px 60px 0px RGBA(var(--hiredots-white-rgb, 255, 255, 255), 0.07);
  padding: 15px 20px 11px;
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
}
.main-menu .main-menu__list li:hover > ul {
  opacity: 1;
  visibility: visible;
  transform: scaleY(1) translateZ(0px);
}
.main-menu .main-menu__list > .megamenu {
  position: static;
}
.main-menu .main-menu__list > .megamenu > ul {
  top: 100% !important;
  left: 0 !important;
  right: 0 !important;
  background-color: transparent;
  box-shadow: none;
  padding: 0;
}
.main-menu .main-menu__list li ul li {
  flex: 1 1 100%;
  width: 100%;
  position: relative;
}
.main-menu .main-menu__list li ul li > a {
  font-size: 14px;
  line-height: 26px;
  color: var(--hiredots-text, #696e76);
  font-family: var(--hiredots-font, "Manrope", sans-serif);
  font-weight: 600;
  display: flex;
  padding: 8px 20px;
  transition: 400ms;
  margin-bottom: 4px;
}
.main-menu .main-menu__list li ul li > a::after {
  position: absolute;
  right: 20px;
  top: 8px;
  border-radius: 0;
  font-size: 6px;
  font-weight: 700;
  font-family: "Font Awesome 5 Free";
  content: "\f111";
  color: var(--hiredots-base, #00cde5);
  visibility: hidden;
  opacity: 0;
  transition: all 500ms ease;
  transform: scale(0);
}
.main-menu .main-menu__list li ul li.current > a,
.main-menu .main-menu__list li ul li:hover > a {
  background-color: var(--hiredots-gray, #f0f3f9);
  color: var(--hiredots-black, #0b182a);
}
.main-menu .main-menu__list li ul li.current > a::after,
.main-menu .main-menu__list li ul li:hover > a::after {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}
.main-menu .main-menu__list li ul li > ul {
  top: 0;
  left: calc(100% + 20px);
}
.main-menu .main-menu__list li ul li > ul.right-align {
  top: 0;
  left: auto;
  right: 100%;
}
.main-menu .main-menu__list li ul li > ul ul {
  display: none;
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .main-menu__list li:nth-last-child(1) ul li > ul,
  .main-menu__list li:nth-last-child(2) ul li > ul {
    left: auto;
    right: calc(100% + 20px);
  }
}
/*--------------------------------------------------------------
# Megamenu Popup
--------------------------------------------------------------*/
.mobile-nav__container .main-menu__list > .megamenu.megamenu-clickable > ul,
.main-menu .main-menu__list > .megamenu.megamenu-clickable > ul,
.stricky-header .main-menu__list > .megamenu.megamenu-clickable > ul {
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  width: 100vw;
  height: 100vh;
  visibility: visible;
  overflow-y: scroll;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: scale(1, 0);
  transform: scale(1, 0);
  -webkit-transform-origin: bottom center;
  transform-origin: bottom center;
  transition: transform 0.7s ease, opacity 0.7s ease, visibility 0.7s ease;
  z-index: 999999;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
  padding: 0;
  background-color: var(--hiredots-white, #fff);
  display: block !important;
  margin: 0;
}

.main-menu__list > li.megamenu-clickable > ul::-webkit-scrollbar {
  display: none;
}

.mobile-nav__container .main-menu__list > .megamenu.megamenu-clickable > ul.megamenu-clickable--active,
.main-menu .main-menu__list > .megamenu.megamenu-clickable > ul.megamenu-clickable--active,
.stricky-header .main-menu__list > .megamenu.megamenu-clickable > ul.megamenu-clickable--active {
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  opacity: 1;
  visibility: visible;
  transition: transform 0.7s ease, opacity 0.7s ease, visibility 0.7s ease;
}

body.megamenu-popup-active {
  overflow: hidden;
}

body.megamenu-popup-active .stricky-header {
  bottom: 0;
}

body.megamenu-popup-active .mobile-nav__content {
  overflow: unset;
}

.mobile-nav__content .demo-one .container {
  padding-left: 15px;
  padding-right: 15px;
}

.megamenu-popup {
  position: relative;
}
.megamenu-popup .megamenu-clickable--close {
  position: absolute;
  top: 18px;
  right: 20px;
  display: block;
  color: var(--hiredots-black, #0b182a);
}
@media (min-width: 1300px) {
  .megamenu-popup .megamenu-clickable--close {
    top: 38px;
    right: 40px;
  }
}
.megamenu-popup .megamenu-clickable--close:hover {
  color: var(--hiredots-base, #00cde5);
}
.megamenu-popup .megamenu-clickable--close span {
  width: 24px;
  height: 24px;
  display: block;
  position: relative;
  color: currentColor;
  transition: all 500ms ease;
}
.megamenu-popup .megamenu-clickable--close span::before, .megamenu-popup .megamenu-clickable--close span::after {
  content: "";
  width: 100%;
  height: 2px;
  background-color: currentColor;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
}
.megamenu-popup .megamenu-clickable--close span::after {
  transform: translate(-50%, -50%) rotate(45deg);
}

/*--------------------------------------------------------------
# Why choose
--------------------------------------------------------------*/
.why-choose-one {
  position: relative;
}
.why-choose-one__shape-1 {
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;
  animation: shapeMove 4s linear 0s infinite;
}
@media (min-width: 992px) {
  .why-choose-one__shape-1 {
    display: block;
  }
}
@keyframes shapeMove {
  0%, 100% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(10px);
  }
}
.why-choose-one__inner {
  background-color: var(--hiredots-gray, #f0f3f9);
  padding-top: 120px;
  padding-bottom: 120px;
  position: relative;
}
.why-choose-one__inner::before {
  content: "";
  width: 10000px;
  height: 100%;
  background-color: var(--hiredots-gray, #f0f3f9);
  position: absolute;
  top: 0;
  right: 100%;
}
.why-choose-one .container {
  position: relative;
}
.why-choose-one__content {
  padding-left: 30px;
  padding-right: 30px;
}
@media (min-width: 1200px) {
  .why-choose-one__content {
    padding-left: 0;
    padding-right: 0;
  }
}
.why-choose-one__image {
  text-align: right;
  margin-top: 35px;
}
@media (min-width: 1200px) {
  .why-choose-one__image {
    margin-top: 0;
  }
}
.why-choose-one__image img {
  max-width: 100%;
}
@media (min-width: 1200px) {
  .why-choose-one__image img {
    max-width: none;
  }
}
.why-choose-one__highlighted {
  margin: 0;
  color: var(--hiredots-base, #00cde5);
  font-size: 18px;
  line-height: 30px;
  margin-top: -20px;
}
@media (min-width: 768px) {
  .why-choose-one__highlighted {
    font-size: 20px;
  }
}
.why-choose-one__text {
  margin: 0;
  font-size: 15px;
  line-height: 2em;
  margin-top: 15px;
}
@media (min-width: 1200px) {
  .why-choose-one__text {
    margin-top: 20px;
  }
}
.why-choose-one__list {
  margin-top: 45px;
}
.why-choose-one__list__item {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}
@media (min-width: 1200px) {
  .why-choose-one__list__item {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}
.why-choose-one__list__item + .why-choose-one__list__item {
  margin-top: 20px;
}
.why-choose-one__list__icon {
  width: 57px;
  height: 57px;
  background-color: var(--hiredots-base, #00cde5);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: var(--hiredots-white, #fff);
  font-size: 21px;
  flex-shrink: 0;
  border-radius: 50%;
  margin-bottom: 15px;
  transition: all 500ms ease;
}
.why-choose-one__list__icon i {
  transform: scale(1);
  transition: 500ms ease;
}
.why-choose-one__list__icon:hover {
  background-color: var(--hiredots-black, #0b182a);
  color: var(--hiredots-white, #fff);
}
.why-choose-one__list__icon:hover i {
  transform: scale(0.9);
}
@media (min-width: 1200px) {
  .why-choose-one__list__icon {
    margin-bottom: 0;
    margin-right: 20px;
  }
}
.why-choose-one__list__title {
  margin: 0;
  text-transform: uppercase;
  font-size: 20px;
  color: var(--hiredots-black, #0b182a);
  font-weight: bold;
}
@media (min-width: 1200px) {
  .why-choose-one__list__title {
    min-width: 130px;
  }
}
.why-choose-one__list__title a {
  color: inherit;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.why-choose-one__list__title a:hover {
  background-size: 100% 1px;
}
.why-choose-one__list__text {
  margin: 0;
  font-size: 15px;
  line-height: 26px;
  position: relative;
}
.why-choose-one__list__text::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 57px;
  background-color: var(--hiredots-border-color, #cbd2da);
  top: 50%;
  transform: translateY(-50%);
  display: none;
}
@media (min-width: 1200px) {
  .why-choose-one__list__text::before {
    display: block;
  }
}
@media (min-width: 1200px) {
  .why-choose-one__list__text {
    padding-left: 30px;
  }
}

.why-choose-two {
  padding: 120px 0;
  position: relative;
}
@media (max-width: 767px) {
  .why-choose-two {
    padding: 80px 0;
  }
}
.why-choose-two__circle {
  width: 130px;
  height: 130px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  top: 30px;
  right: -30px;
}
.why-choose-two__circle .video-popups {
  font-size: 62px;
  color: var(--hiredots-base, #00cde5);
  transition: all 500ms ease;
  position: relative;
  z-index: 10;
}
.why-choose-two__circle .curved-circle {
  position: absolute;
  top: 0;
  left: 0;
  width: 130px;
  height: 130px;
  transform-origin: center center;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: textRotate 15s linear 0s forwards infinite alternate;
}
.why-choose-two__circle .curved-circle--item {
  width: 130px;
}
.why-choose-two__circle .curved-circle--item span {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 800;
  color: var(--hiredots-base, #00cde5);
  letter-spacing: 0.3em;
}
.why-choose-two__image {
  position: relative;
  display: inline-block;
}
.why-choose-two__image img {
  max-width: 100%;
}
.why-choose-two__image__two {
  position: relative;
  top: -100px;
}
@media (min-width: 768px) {
  .why-choose-two__image__two {
    position: absolute;
    top: 30px;
    left: -120px;
  }
}
.why-choose-two__image__shape {
  position: absolute;
  bottom: 30px;
  left: 0;
  z-index: -1;
  animation: shapeMove 4s linear 0s infinite;
}
@media (min-width: 992px) {
  .why-choose-two__image__shape {
    bottom: 30px;
    left: auto;
    right: 0;
  }
}
.why-choose-two__image__icon {
  width: 96px;
  height: 96px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--hiredots-base, #00cde5);
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 10;
}
@media (min-width: 992px) {
  .why-choose-two__image__icon {
    left: -48px;
  }
}
@media (min-width: 1200px) {
  .why-choose-two__content {
    padding-left: 70px;
  }
}
.why-choose-two__content .sec-title {
  padding-bottom: 30px;
}
.why-choose-two__highlight {
  font-size: 18px;
  line-height: 30px;
  font-weight: 500;
  color: var(--hiredots-base, #00cde5);
  margin: 0;
  margin-top: -10px;
  margin-bottom: 20px;
}
@media (min-width: 992px) {
  .why-choose-two__highlight {
    margin: 0;
    font-size: 20px;
    line-height: 34px;
    margin-top: -20px;
    margin-bottom: 32px;
  }
}
.why-choose-two__text {
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  line-height: 30px;
}
@media (min-width: 992px) {
  .why-choose-two__text {
    font-size: 16px;
  }
}
.why-choose-two__feature {
  list-style: none;
  padding-left: 0;
  margin-bottom: 29px;
  margin-top: 23px;
  font-size: 18px;
  font-weight: 700;
  color: var(--hiredots-black, #0b182a);
  letter-spacing: var(--hiredots-letter-space-sm, -0.04em);
}
.why-choose-two__feature li {
  list-style: none;
  margin-bottom: 3px;
}
.why-choose-two__feature i {
  margin-right: 10px;
  color: var(--hiredots-base, #00cde5);
}
.why-choose-two__progress {
  margin-top: 30px;
  margin-bottom: 40px;
}
.why-choose-two__progress__title {
  margin: 0;
  font-size: 18px;
  font-weight: 800;
  margin-bottom: 9px;
  letter-spacing: -0.72px;
  color: var(--hiredots-black, #0b182a);
}
.why-choose-two__progress__bar {
  width: 100%;
  height: 13px;
  border-radius: 30px;
  position: relative;
  background-color: var(--hiredots-gray, #f0f3f9);
}
.why-choose-two__progress__inner {
  position: absolute;
  height: 100%;
  left: 0px;
  top: 0px;
  background-color: var(--hiredots-primary, #0b2f81);
  transition: all 700ms linear;
  width: 0px;
  border-radius: 30px;
}
.why-choose-two__progress__number {
  position: absolute;
  bottom: calc(100% + 5px);
  left: 350px;
  font-size: 15px;
}
.why-choose-two__link:hover {
  color: var(--hiredots-white, #fff);
}
.why-choose-two__link::after {
  background-color: var(--hiredots-black, #0b182a);
}
.why-choose-two__link::before {
  background-color: var(--hiredots-base, #00cde5);
}

.why-choose-three {
  position: relative;
  padding: 120px 0 220px;
  background-color: var(--hiredots-black, #0b182a);
  margin-bottom: -100px;
}
@media (max-width: 767px) {
  .why-choose-three {
    padding: 80px 0 180px;
  }
}
.why-choose-three__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--hiredots-black, #0b182a);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  opacity: 0.3;
}
.why-choose-three__content {
  position: relative;
}
.why-choose-three__content__text {
  font-size: 15px;
  line-height: 30px;
  color: rgba(var(--hiredots-white-rgb, 255, 255, 255), 0.6);
  margin: 0;
}
.why-choose-three .sec-title__title {
  color: var(--hiredots-white, #fff);
}
.why-choose-three .sec-title {
  padding-bottom: 26px;
}
.why-choose-three__list {
  position: relative;
  background-color: var(--hiredots-black2, #12243c);
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  row-gap: 0;
}
@media (max-width: 991px) {
  .why-choose-three__list {
    margin: 50px 0 0;
  }
}
.why-choose-three__item {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 40px 40px 36px;
}
.why-choose-three__item:nth-child(1), .why-choose-three__item:nth-child(3) {
  border-right: 1px solid rgba(var(--hiredots-white-rgb, 255, 255, 255), 0.1);
}
.why-choose-three__item:nth-child(1), .why-choose-three__item:nth-child(2) {
  border-bottom: 1px solid rgba(var(--hiredots-white-rgb, 255, 255, 255), 0.1);
}
@media (min-width: 992px) and (max-width: 1199px) {
  .why-choose-three__item {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media (max-width: 767px) {
  .why-choose-three__item {
    flex: 0 0 100%;
    max-width: 100%;
    border-bottom: 1px solid rgba(var(--hiredots-white-rgb, 255, 255, 255), 0.1);
  }
}
.why-choose-three__item:hover .why-choose-three__item__icon span {
  transform: scale(0.8);
}
.why-choose-three__item__top {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 12px;
}
.why-choose-three__item__icon {
  width: 57px;
  height: 57px;
  background-color: var(--hiredots-base, #00cde5);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: var(--hiredots-white, #fff);
  font-size: 21px;
  margin-right: 18px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .why-choose-three__item__icon {
    margin-right: 12px;
  }
}
.why-choose-three__item__icon span {
  display: inline-block;
  transition: all 500ms linear;
  transition-delay: 0s;
  transition-delay: 0s;
  transition-delay: 0.1s;
  transform: scale(1);
}
.why-choose-three__item__title {
  color: var(--hiredots-white, #fff);
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 700;
  margin: 0;
}
.why-choose-three__item__text {
  color: var(--hiredots-text-dark, #7e8c9e);
  font-size: 15px;
  line-height: 30px;
  margin: 0;
}

/*--------------------------------------------------------------
# Funfact
--------------------------------------------------------------*/
.funfact-one {
  position: relative;
}
.funfact-one .container {
  max-width: 1370px;
}
.funfact-one .container .row > * {
  padding-left: 0;
  padding-right: 0;
}
.funfact-one__item {
  position: relative;
  background-color: var(--hiredots-primary, #0b2f81);
  padding: 60px 25px 55px 100px;
}
@media (min-width: 992px) and (max-width: 1300px) {
  .funfact-one__item {
    padding-left: 50px;
  }
}
.funfact-one__item::after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  opacity: 0.102;
  background-image: linear-gradient(90deg, rgba(11, 24, 42, 0) 0%, rgba(11, 24, 42, 0.290994567) 33%, rgb(11, 24, 42) 100%);
}
.funfact-one__item:hover .funfact-one__icon span {
  transform: scale(0.9);
}
.funfact-one__icon {
  width: 103px;
  height: 104px;
  background-color: var(--hiredots-white, #fff);
  font-size: 62px;
  color: var(--hiredots-base, #00cde5);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  position: relative;
}
.funfact-one__icon::after {
  position: absolute;
  left: 16px;
  bottom: -7px;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 10px 0 10px;
  border-color: var(--hiredots-white, #fff) transparent transparent transparent;
}
.funfact-one__icon span {
  display: inline-block;
  transition: all 0.6s ease;
}
.funfact-one__count {
  margin: 0 0 4px;
  color: var(--hiredots-white, #fff);
  font-size: 35px;
  line-height: 1;
}
@media (min-width: 768px) {
  .funfact-one__count {
    font-size: 40px;
  }
}
.funfact-one__text {
  margin: 0;
  color: #9bb6dd;
  font-size: 16px;
  line-height: 30px;
  margin: 0;
}

.funfact-two {
  position: relative;
}
@media (max-width: 991px) {
  .funfact-two {
    margin-top: 40px;
  }
}
.funfact-two__item {
  position: relative;
  padding: 15px 0 0 133px;
  min-height: 104px;
  margin: 0 0 10px;
}
.funfact-two__item:hover .funfact-one__icon span {
  transform: scale(0.9);
}
.funfact-two__icon {
  width: 103px;
  height: 104px;
  background-color: var(--hiredots-gray, #f0f3f9);
  font-size: 62px;
  color: var(--hiredots-base, #00cde5);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  position: absolute;
  left: 0;
  top: 0;
}
.funfact-two__icon span {
  display: inline-block;
  transition: all 0.6s ease;
}
.funfact-two__count {
  margin: 0 0 4px;
  color: var(--hiredots-black, #0b182a);
  font-size: 35px;
  line-height: 1;
}
@media (min-width: 768px) {
  .funfact-two__count {
    font-size: 40px;
  }
}
.funfact-two__text {
  margin: 0;
  font-size: 16px;
  line-height: 30px;
  margin: 0;
}

.funfact-three {
  position: relative;
  padding: 100px 0;
}
@media (max-width: 767px) {
  .funfact-three {
    padding: 80px 0 50px;
  }
}
.funfact-three__bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--hiredots-gray, #f0f3f9);
}
@media (min-width: 1400px) {
  .funfact-three__bg {
    width: calc(100% - 240px);
    left: 120px;
  }
}
.funfact-three__list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  row-gap: 0;
}
@media (max-width: 767px) {
  .funfact-three__list {
    display: block;
  }
}
.funfact-three__item {
  flex: 0 0 25%;
  max-width: 25%;
  position: relative;
}
@media (max-width: 991px) {
  .funfact-three__item {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 0 30px !important;
  }
}
@media (max-width: 767px) {
  .funfact-three__item {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 0 30px !important;
    text-align: center;
  }
}
.funfact-three__item:not(:last-of-type)::before {
  content: "";
  width: 1px;
  height: 100%;
  background-color: var(--hiredots-border-color, #cbd2da);
  position: absolute;
  top: 0;
  right: 54px;
}
@media (max-width: 991px) {
  .funfact-three__item:not(:last-of-type)::before {
    right: 50px !important;
  }
}
@media (max-width: 767px) {
  .funfact-three__item:not(:last-of-type)::before {
    display: none;
  }
}
.funfact-three__item:not(:first-of-type) {
  padding-left: 37px;
}
@media (max-width: 1199px) {
  .funfact-three__item:not(:first-of-type) {
    padding-left: 0;
  }
}
.funfact-three__item:nth-child(2)::before {
  right: 16px;
}
@media (max-width: 991px) {
  .funfact-three__item:nth-child(2)::before {
    display: none;
  }
}
.funfact-three__item:nth-child(3)::before {
  right: -20px;
}
.funfact-three__item:nth-child(3) {
  padding-left: 63px;
}
@media (max-width: 1199px) {
  .funfact-three__item:nth-child(3) {
    padding-left: 40px;
  }
}
.funfact-three__item:last-child {
  padding-left: 104px;
}
@media (max-width: 1199px) {
  .funfact-three__item:last-child {
    padding-left: 70px;
  }
}
.funfact-three__item:hover .funfact-three__icon::before {
  transform: scale(0.9);
}
.funfact-three__icon {
  width: 103px;
  height: 104px;
  background-color: var(--hiredots-base, #00cde5);
  font-size: 60px;
  color: var(--hiredots-white, #fff);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 13px;
}
@media (max-width: 767px) {
  .funfact-three__icon {
    margin-left: auto;
    margin-right: auto;
  }
}
.funfact-three__icon::before {
  transition: all 500ms linear;
  transition-delay: 0s;
  transition-delay: 0s;
  transition-delay: 0s;
  transition-delay: 0s;
  transition-delay: 0.1s;
  transform: scale(1);
}
.funfact-three__count {
  margin: 0;
  color: var(--hiredots-black, #0b182a);
  font-family: var(--hiredots-font, "Manrope", sans-serif);
  font-size: 35px;
  font-weight: 500;
  line-height: 1;
}
@media (min-width: 768px) {
  .funfact-three__count {
    font-size: 40px;
  }
}
.funfact-three__text {
  margin: 0;
  color: var(--hiredots-text, #696e76);
  font-size: 16px;
  margin-top: 9px;
  margin-bottom: -6px;
}

/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
.testimonials-one {
  padding: 120px 0;
  position: relative;
  overflow: hidden;
}
@media (max-width: 767px) {
  .testimonials-one {
    padding: 80px 0;
  }
}
.testimonials-one__sec-title {
  position: relative;
  z-index: 2;
}
.testimonials-one .sec-title {
  padding-bottom: 30px;
}
.testimonials-one__text {
  font-size: 18px;
  line-height: 30px;
  margin: 0 0 35px;
}
.testimonials-one__shape {
  position: absolute;
  top: 0;
  bottom: 0;
}
.testimonials-one__dots {
  position: relative;
  display: flex;
  align-items: center;
  gap: 7px;
}
.testimonials-one__dots .owl-dot {
  position: relative;
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
}
.testimonials-one__dots .owl-dot span {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 0;
  transition: 500ms ease;
  margin: 0;
  display: block;
  background-color: rgba(var(--hiredots-black-rgb, 11, 24, 42), 0.2);
}
.testimonials-one__dots .owl-dot:hover span, .testimonials-one__dots .owl-dot.active span {
  transform: scale(1.5);
  background-color: var(--hiredots-base, #00cde5);
}

.testimonials-card {
  transition: all 500ms ease;
  position: relative;
  background-color: var(--hiredots-white, #fff);
  padding: 60px 30px;
  margin-top: 70px;
  transition: all 500ms ease;
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
}
.testimonials-card::before {
  position: absolute;
  bottom: -11px;
  left: 0;
  content: url(../images/shapes/testi-line.png);
}
@media (min-width: 768px) {
  .testimonials-card {
    padding: 60px;
    margin-right: 30px;
  }
}
.testimonials-card__image {
  width: 117px;
  height: 117px;
  position: absolute;
  right: 40px;
  top: -60px;
}
.testimonials-card__image::before {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  display: inline-block;
  content: "";
  position: absolute;
  right: 0;
  top: -10px;
  background-color: var(--hiredots-base, #00cde5);
  z-index: -1;
}
@media (min-width: 1200px) {
  .testimonials-card__image {
    margin-bottom: 0;
  }
}
.testimonials-card__image img {
  max-width: 100%;
  border-radius: 50%;
}
.testimonials-card:hover .testimonials-card__image {
  border-color: var(--hiredots-base, #00cde5);
}
.testimonials-card__rating {
  display: flex;
  align-items: center;
}
.testimonials-card__rating i {
  color: #ff9c2c;
  font-size: 16px;
  letter-spacing: var(--hiredots-letter-space-xl, 0.2em);
}
.testimonials-card__author {
  display: flex;
  align-items: center;
  margin-top: 21px;
}
.testimonials-card__icon {
  font-size: 32px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--hiredots-primary, #0b2f81);
  background-color: var(--hiredots-gray, #f0f3f9);
}
.testimonials-card__name {
  margin: 0;
  text-transform: capitalize;
  color: var(--hiredots-black, #0b182a);
  font-size: 24px;
  margin-bottom: 7px;
  letter-spacing: -0.96px;
  line-height: 1;
  margin-top: -4px;
}
.testimonials-card__designation {
  margin: 0;
  line-height: 1;
  font-weight: 500;
  font-size: 16px;
}
.testimonials-card__content {
  font-size: 18px;
  line-height: 32px;
  padding-top: 20px;
}

.testimonials-two {
  position: relative;
  margin-top: -197px;
}
.testimonials-two .col-lg-8 {
  width: 72.5%;
}
@media (max-width: 991px) {
  .testimonials-two .col-lg-8 {
    width: 100%;
  }
}
.testimonials-two .col-lg-4 {
  width: 27.5%;
}
@media (max-width: 991px) {
  .testimonials-two .col-lg-4 {
    width: 100%;
  }
}
.testimonials-two__bg {
  position: relative;
  background-color: var(--hiredots-white, #fff);
  padding: 100px 0 91px;
  max-width: 1370px;
  margin: 0 auto;
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
}
@media (max-width: 1400px) {
  .testimonials-two__bg {
    max-width: 1300px;
  }
}
.testimonials-two__wrapper {
  position: relative;
}
.testimonials-two__wrapper .sec-title__tagline::before {
  display: none;
}
.testimonials-two__wrapper .sec-title {
  line-height: 1;
  padding: 0 0 28px 300px;
}
@media (max-width: 767px) {
  .testimonials-two__wrapper .sec-title {
    padding-left: 0;
  }
}
.testimonials-two__carousel {
  position: relative;
}
.testimonials-two__item {
  position: relative;
  min-height: 200px;
  padding: 0 0 0 300px;
}
@media (max-width: 767px) {
  .testimonials-two__item {
    padding-left: 0;
  }
}
.testimonials-two__item__author {
  position: absolute;
  left: 70px;
  top: 12px;
  width: 194px;
  height: 194px;
  border: 2px solid var(--hiredots-base, #00cde5);
  border-radius: 50%;
  padding: 8px;
}
@media (max-width: 767px) {
  .testimonials-two__item__author {
    position: relative;
    left: 0;
    top: 0;
    margin-bottom: 15px;
  }
}
.testimonials-two__item__author img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.testimonials-two__item__quote {
  font-size: 24px;
  line-height: 40px;
  font-weight: 400;
  font-style: italic;
  margin: 0 0 50px;
}
@media (min-width: 1200px) {
  .testimonials-two__item__quote {
    margin-right: 40px;
  }
}
@media (max-width: 767px) {
  .testimonials-two__item__quote {
    margin: 0 0 30px;
  }
}
.testimonials-two__meta {
  display: flex;
  align-items: baseline;
  position: relative;
  font-size: 14px;
  line-height: 1;
  font-weight: 700;
}
.testimonials-two__meta__name {
  color: var(--hiredots-black, #0b182a);
  font-size: 24px;
  margin: 0;
  letter-spacing: -0.96px;
}
.testimonials-two__meta__dot {
  margin: 0 11px;
}
.testimonials-two__image {
  margin: 0;
  padding: 0;
  position: absolute;
  left: 0;
  bottom: 22px;
  z-index: 3;
  list-style: none;
}
@media (max-width: 1199px) {
  .testimonials-two__image {
    bottom: 52px;
  }
}
@media (max-width: 767px) {
  .testimonials-two__image {
    position: relative;
    bottom: 0;
    max-width: 200px;
    margin: 30px 0 0;
  }
}
.testimonials-two__image__item {
  margin: 0 0 10px;
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
  width: 58px;
  height: 58px;
  overflow: hidden;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
}
.testimonials-two__image__item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.testimonials-three {
  position: relative;
  overflow: hidden;
  padding: 120px 0;
}
@media (max-width: 767px) {
  .testimonials-three {
    padding: 80px 0;
  }
}
.testimonials-three__carousel.owl-carousel {
  position: relative;
}
.testimonials-three__carousel.owl-carousel .owl-nav {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 34px 0 0;
}
.testimonials-three__carousel.owl-carousel .owl-nav button {
  width: 57px;
  height: 57px;
  background-color: var(--hiredots-gray, #f0f3f9);
  font-size: 16px;
  color: var(--hiredots-black, #0b182a);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  transition: all 500ms ease;
}
.testimonials-three__carousel.owl-carousel .owl-nav button:hover {
  background-color: var(--hiredots-base, #00cde5);
  color: var(--hiredots-white, #fff);
}
.testimonials-three__item {
  position: relative;
}
.testimonials-three__ratings {
  display: flex;
  color: #f3a927;
  font-size: 16px;
  letter-spacing: 3px;
  margin: 0 0px 19px;
}
.testimonials-three__content {
  font-size: 24px;
  line-height: 40px;
  font-weight: 400;
  margin: 0 0 50px;
}
.testimonials-three__meta {
  display: flex;
  align-items: baseline;
  position: relative;
  font-size: 14px;
  line-height: 1;
  font-weight: 700;
  color: var(--hiredots-base, #00cde5);
}
.testimonials-three__meta__name {
  color: var(--hiredots-black, #0b182a);
  font-size: 24px;
  margin: 0;
  letter-spacing: -0.96px;
}
.testimonials-three__meta__dot {
  margin: 0 11px;
}
.testimonials-three__thumbs {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
@media (min-width: 1200px) {
  .testimonials-three__thumbs {
    margin-left: 18px;
  }
}
@media (max-width: 991px) {
  .testimonials-three__thumbs {
    margin-top: 40px;
    max-width: 552px;
  }
}
.testimonials-three__thumbs__icon {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 178px;
  height: 178px;
  background-color: var(--hiredots-primary, #0b2f81);
  border-radius: 50%;
  font-size: 78px;
  color: var(--hiredots-white, #fff);
}
@media (max-width: 767px) {
  .testimonials-three__thumbs__icon {
    width: 120px;
    height: 120px;
    font-size: 55px;
  }
}
.testimonials-three__thumbs__icon span {
  transform: scale(1);
  transition: 500ms ease;
}
.testimonials-three__thumbs__item {
  border-radius: 50%;
  overflow: hidden;
}
.testimonials-three__thumbs__item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.testimonials-three__thumbs:hover .testimonials-three__thumbs__icon span {
  transform: scale(0.9);
}

/*--------------------------------------------------------------
# CTA
--------------------------------------------------------------*/
.cta-one {
  padding-top: 107px;
  position: relative;
  background-position: top center;
  background-repeat: no-repeat;
}
@media (max-width: 767px) {
  .cta-one {
    padding-top: 80px;
  }
}
.cta-one__title {
  margin: 0;
  font-size: 56px;
  color: var(--hiredots-white, #fff);
  letter-spacing: -2.2px;
  margin-bottom: 48px;
}
@media (max-width: 991px) {
  .cta-one__title {
    font-size: 42px;
  }
}
@media (max-width: 767px) {
  .cta-one__title {
    font-size: 36px;
    margin-bottom: 28px;
  }
}
@media (max-width: 767px) {
  .cta-one .text-end {
    text-align: left !important;
  }
}
.cta-one__text {
  position: relative;
  border-top: 3px solid var(--hiredots-white, #fff);
  font-size: 18px;
  color: var(--hiredots-white, #fff);
  padding-top: 30px;
  margin: 0 0 29px;
}
.cta-one__text p {
  margin: 0;
}
.cta-one .hiredots-btn {
  margin-top: 46px;
}
@media (max-width: 767px) {
  .cta-one .hiredots-btn {
    margin: 0 0 35px;
  }
}
@media (max-width: 991px) {
  .cta-one .hiredots-btn {
    padding: 16px 45px;
  }
}
.cta-one .video-popup {
  width: 105px;
  height: 105px;
  background-color: var(--hiredots-base, #00cde5);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: var(--hiredots-white, #fff);
  position: relative;
  margin-top: 17px;
}
.cta-one .video-popup:hover {
  background-color: var(--hiredots-primary, #0b2f81);
}
.cta-one .video-popup::before {
  content: "";
  border: 1px solid rgba(var(--hiredots-white-rgb, 255, 255, 255), 0.5);
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  animation: zoomBig 2s linear infinite;
}
.cta-one .video-popup::after {
  content: "";
  border: 1px solid rgba(var(--hiredots-white-rgb, 255, 255, 255), 0.5);
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  animation: zoomBig 3.25s linear infinite;
}
@keyframes zoomBig {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
    border-width: 3px;
  }
  40% {
    opacity: 0.5;
    border-width: 1px;
  }
  65% {
    border-width: 2px;
  }
  100% {
    transform: translate(-50%, -50%) scale(1.5);
    opacity: 0;
    border-width: 1px;
  }
}
.cta-one__content {
  position: relative;
  background-color: var(--hiredots-white, #fff);
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.05);
  padding: 0 0 20px;
}
@media (max-width: 991px) {
  .cta-one__content {
    margin: 45px 0 0;
  }
}
.cta-one__content__title {
  color: var(--hiredots-white, #fff);
  background-color: var(--hiredots-primary, #0b2f81);
  font-size: 30px;
  letter-spacing: -1.2px;
  margin: 0 0 20px;
  padding: 24px 100px 30px;
  position: relative;
}
@media (max-width: 767px) {
  .cta-one__content__title {
    padding: 24px 30px 30px;
  }
}
.cta-one__content__title::after {
  position: absolute;
  left: 100px;
  bottom: -30px;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 30px 49px 0 0;
  border-color: var(--hiredots-primary, #0b2f81) transparent transparent transparent;
}
@media (max-width: 767px) {
  .cta-one__content__title::after {
    left: 30px;
  }
}
.cta-one__content__list {
  background-color: var(--hiredots-white, #fff);
  padding: 39px 40px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  transition: all 0.5s ease;
  margin: 0 60px;
  max-width: 100%;
}
@media (max-width: 991px) {
  .cta-one__content__list {
    margin: 0 30px;
    padding: 29px 20px;
  }
}
@media (max-width: 767px) {
  .cta-one__content__list {
    margin: 0 20px;
    padding: 39px 30px;
    display: block;
  }
}
.cta-one__content__list--bg {
  background-color: var(--hiredots-gray, #f0f3f9);
}
.cta-one__content__list__icon {
  margin-right: 20px;
  font-size: 62px;
  line-height: 1;
  color: var(--hiredots-base, #00cde5);
  flex-shrink: 0;
}
.cta-one__content__list__title {
  font-size: 20px;
  color: var(--hiredots-black, #0b182a);
  margin-right: 40px;
  flex-shrink: 0;
  margin-bottom: 0;
  letter-spacing: -0.8px;
}
@media (max-width: 767px) {
  .cta-one__content__list__title {
    margin: 10px 0 20px;
  }
}
.cta-one__content__list__info {
  margin: 0;
  padding-left: 31px;
  position: relative;
}
@media (max-width: 767px) {
  .cta-one__content__list__info {
    padding: 0;
  }
}
.cta-one__content__list__info br {
  display: block;
}
@media (max-width: 767px) {
  .cta-one__content__list__info br {
    display: none;
  }
}
.cta-one__content__list__info::after {
  position: absolute;
  left: 0;
  top: -4px;
  height: 64px;
  width: 1px;
  content: "";
  background-color: var(--hiredots-border-color, #cbd2da);
}
@media (max-width: 767px) {
  .cta-one__content__list__info::after {
    display: none;
  }
}

.cta-two {
  position: relative;
  background-color: var(--hiredots-base, #00cde5);
  background-position: top right;
  background-repeat: no-repeat;
  background-size: auto;
}
@media (max-width: 991px) {
  .cta-two {
    background-position: bottom right;
  }
}
.cta-two__shape-one {
  position: absolute;
  left: 5%;
  top: 0;
  opacity: 0.2;
}
@media (max-width: 1499px) {
  .cta-two__shape-one {
    left: 0;
  }
}
@media (max-width: 1199px) {
  .cta-two__shape-one {
    display: none;
  }
}
.cta-two__shape-one img {
  animation: shapeMove 3s linear 0s infinite;
}
.cta-two__shape-two {
  position: absolute;
  left: 2%;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  text-align: center;
}
@media (max-width: 1199px) {
  .cta-two__shape-two {
    display: none;
  }
}
.cta-two__shape-two img {
  -webkit-animation-name: treeMove;
  animation-name: treeMove;
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}
.cta-two__content {
  position: relative;
  padding: 120px 0;
}
@media (max-width: 1199px) {
  .cta-two__content {
    padding: 80px 0;
  }
}
.cta-two__content .hiredots-btn {
  background-color: var(--hiredots-black, #0b182a);
}
.cta-two__sub-title {
  position: relative;
  margin: 0 0 -63px 188px;
}
@media (max-width: 767px) {
  .cta-two__sub-title {
    margin: 0 0 -40px 80px;
  }
}
.cta-two__sub-title img {
  max-width: 100%;
}
.cta-two__title {
  color: var(--hiredots-white, #fff);
  font-size: 60px;
  line-height: 60px;
  letter-spacing: -2.4px;
  margin: 0 0 53px;
}
@media (max-width: 767px) {
  .cta-two__title {
    font-size: 45px;
    line-height: 50px;
    margin: 0 0 33px;
  }
}
.cta-two__image {
  position: relative;
  text-align: right;
  margin-top: -80px;
  margin-left: -40px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .cta-two__image {
    margin-left: -120px;
  }
}
@media (max-width: 991px) {
  .cta-two__image {
    margin: 0;
    text-align: left;
  }
}
.cta-two__image img {
  height: auto;
}
@media (max-width: 991px) {
  .cta-two__image img {
    max-width: 100%;
  }
}

.cta-three {
  position: relative;
  text-align: center;
  padding: 107px 0 120px;
  background-color: var(--hiredots-black, #0b182a);
}
@media (max-width: 767px) {
  .cta-three {
    padding: 75px 0 80px;
  }
}
.cta-three--home {
  padding-bottom: 317px;
}
@media (max-width: 767px) {
  .cta-three--home {
    padding-bottom: 270px;
  }
}
.cta-three__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.2;
  mix-blend-mode: luminosity;
}
.cta-three__title {
  font-size: 36px;
  font-weight: 800;
  color: var(--hiredots-white, #fff);
  margin-bottom: 39px;
  letter-spacing: -1.2px;
  position: relative;
  z-index: 2;
}
@media (min-width: 768px) {
  .cta-three__title {
    font-size: 46px;
  }
}
@media (min-width: 992px) {
  .cta-three__title {
    font-size: 56px;
    letter-spacing: -2.2px;
  }
}
.cta-three__btns {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.cta-foue {
  position: relative;
  background-color: var(--hiredots-base, #00cde5);
  padding: 80px 0;
}
.cta-foue__title {
  color: var(--hiredots-white, #fff);
  font-size: 40px;
  letter-spacing: -1.6px;
  margin: 3px 0 0;
}
.cta-foue .hiredots-btn {
  background-color: var(--hiredots-black, #0b182a);
}
.cta-foue .hiredots-btn::after {
  background-color: var(--hiredots-primary, #0b2f81);
}
@media (max-width: 991px) {
  .cta-foue .hiredots-btn {
    padding: 16px 45px;
  }
}
@media (max-width: 767px) {
  .cta-foue .text-end {
    margin-top: 30px;
    text-align: left !important;
  }
}

/*--------------------------------------------------------------
# Sidebar
--------------------------------------------------------------*/
.sidebar__single {
  background-color: var(--hiredots-gray, #f0f3f9);
  padding: 50px 30px;
}
.sidebar__single + .sidebar__single {
  margin-top: 30px;
}
.sidebar__title {
  margin: 0;
  line-height: 1em;
  font-size: 18px;
  margin-top: -4px;
  margin-bottom: 26px;
  margin-left: 20px;
  text-transform: capitalize;
  letter-spacing: var(--hiredots-letter-space-sm, -0.04em);
}
@media (min-width: 768px) {
  .sidebar__title {
    font-size: 20px;
  }
}
.sidebar__search-wrap {
  background-color: var(--hiredots-primary, #0b2f81);
  padding: 30px 30px;
}
.sidebar__search {
  position: relative;
  margin: -10px;
}
@media (min-width: 1200px) {
  .sidebar__search {
    margin: -20px;
  }
}
.sidebar__search input[type=search],
.sidebar__search input[type=text] {
  outline: none;
  width: 100%;
  height: 64px;
  background-color: transparent;
  font-size: 18px;
  font-weight: 500;
  color: var(--hiredots-white, #fff);
  border: 0;
  padding-left: 40px;
  padding-right: 70px;
  transition: all 500ms ease;
}
.sidebar__search button[type=submit] {
  border: none;
  outline: none;
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
  width: auto;
  font-size: 22px;
  color: var(--hiredots-white, #fff);
}
.sidebar__posts {
  margin-bottom: 0;
}
.sidebar__posts__item {
  display: flex;
  align-items: center;
  padding: 20px;
  transition: 0.5s;
  position: relative;
}
.sidebar__posts__item:hover {
  background-color: var(--hiredots-white, #fff);
}
.sidebar__posts__item:last-child {
  margin-bottom: -20px;
}
.sidebar__posts__item:first-child {
  margin-top: -20px;
}
.sidebar__posts__image {
  flex-shrink: 0;
  margin-right: 20px;
}
.sidebar__posts__title {
  margin: 0;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: var(--hiredots-letter-space-sm, -0.04em);
}
.sidebar__posts__title a {
  color: inherit;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.sidebar__posts__title a:hover {
  background-size: 100% 1px;
}
.sidebar__posts__title a:hover {
  color: var(--hiredots-base, #00cde5);
}
.sidebar__posts__meta {
  margin: 5px 0 0;
  line-height: 1em;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 10px;
  font-size: 13px;
}
.sidebar__posts__meta i {
  color: var(--hiredots-base, #00cde5);
  margin-right: 3px;
}
.sidebar__categories {
  margin-bottom: -21px;
  margin-top: -16px;
}
.sidebar__categories li {
  position: relative;
  list-style: none;
  display: block;
  margin-bottom: 4px;
}
.sidebar__categories li a {
  font-size: 16px;
  color: var(--hiredots-text, #696e76);
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 500ms ease;
  padding: 7px 20px;
}
.sidebar__categories li a::after {
  content: "\e902";
  font-family: "icomoon" !important;
  transition: all 500ms ease;
  font-size: 16px;
  transform: scale(0);
  color: var(--hiredots-base, #00cde5);
}
.sidebar__categories li a:hover {
  padding: 7px 20px;
  background-color: var(--hiredots-white, #fff);
  color: var(--hiredots-text, #696e76);
}
.sidebar__categories li a:hover::after {
  transform: scale(1);
}
.sidebar__projects__card {
  position: relative;
}
.sidebar__projects__card__image img {
  width: 100%;
}
.sidebar__projects__card__content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: flex-end;
  padding: 30px;
  background: linear-gradient(to bottom, rgba(var(--hiredots-white-rgb, 255, 255, 255), 0) 40%, var(--hiredots-black, #0b182a) 100%);
}
.sidebar__projects__card__title {
  font-size: 18px;
  line-height: 1.2em;
  font-weight: bold;
  color: var(--hiredots-white, #fff);
  text-transform: uppercase;
  margin: 0;
  margin-bottom: -4px;
  max-width: 70px;
}
.sidebar__projects__card__title a {
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
  transition: all 500ms ease;
  color: inherit;
}
.sidebar__projects__card__title a:hover {
  background-size: 100% 1px;
}
.sidebar__projects__carousel .owl-nav.disabled + .owl-dots {
  margin-top: 0;
}
.sidebar__projects__carousel .owl-dots {
  position: absolute;
  bottom: 30px;
  right: 30px;
  gap: 5px;
}
.sidebar__projects__carousel .owl-dots .owl-dot span {
  background-color: var(--hiredots-white, #fff);
  box-shadow: 0 0 2px rgba(var(--hiredots-white, #fff), 1);
  border-width: 2px;
  opacity: 0.2;
}
.sidebar__projects__carousel .owl-dots .owl-dot:hover span, .sidebar__projects__carousel .owl-dots .owl-dot.active span {
  opacity: 1;
  background-color: rgba(var(--hiredots-white-rgb, 255, 255, 255), 0);
  border-color: rgba(var(--hiredots-white-rgb, 255, 255, 255), 1);
  box-shadow: 0 0 0px rgba(var(--hiredots-white, #fff), 1);
}
.sidebar__tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-left: 24px;
  margin-right: 24px;
}
.sidebar__tags a {
  text-transform: uppercase;
  background-color: var(--hiredots-white, #fff);
  font-size: 12px;
  font-weight: 700;
  color: var(--hiredots-black, #0b182a);
  transition: all 500ms ease;
  display: inline-flex;
  padding: 10px 20px;
  height: 43px;
}
.sidebar__tags a:hover {
  color: var(--hiredots-white, #fff);
  background-color: var(--hiredots-base, #00cde5);
}
.sidebar__comments {
  margin-top: -2px;
  margin-bottom: -2px;
}
.sidebar__comments__item {
  display: flex;
  align-items: center;
}
.sidebar__comments__item:not(:last-of-type) {
  margin-bottom: 26px;
}
.sidebar__comments__icon {
  flex-shrink: 0;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--hiredots-black, #0b182a);
  font-size: 16px;
  color: var(--hiredots-white, #fff);
  margin-right: 20px;
  border-radius: 50%;
  transition: all 500ms ease;
}
.sidebar__comments__item:hover .sidebar__comments__icon {
  background-color: var(--hiredots-base, #00cde5);
  color: var(--hiredots-white, #fff);
}
.sidebar__comments__title {
  margin: 0;
  font-family: var(--hiredots-font, "Manrope", sans-serif);
  font-size: 15px;
  line-height: 24px;
  font-weight: 500;
  color: var(--hiredots-text, #696e76);
}
.sidebar__comments__title a {
  color: inherit;
  transition: all 500ms ease;
}
.sidebar__comments__title a:hover {
  color: var(--hiredots-black, #0b182a);
}

.service-sidebar {
  background-color: var(--hiredots-gray, #f0f3f9);
  padding: 20px;
}
.service-sidebar__single + .service-sidebar__single {
  margin-top: 20px;
}
.service-sidebar__nav li a {
  font-size: 16px;
  color: var(--hiredots-black, #0b182a);
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 500ms ease;
  padding: 12.5px 30px;
  font-weight: 700;
  background-color: var(--hiredots-white, #fff);
  letter-spacing: -0.64px;
  z-index: 1;
  position: relative;
}
.service-sidebar__nav li a::after {
  content: "\f0da";
  font-family: "Font Awesome 5 Free";
  font-weight: 800;
  transition: all 300ms ease;
  font-size: 14px;
  opacity: 1;
}
.service-sidebar__nav li a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  display: inline-block;
  color: var(--hiredots-white, #fff);
  background-color: var(--hiredots-base, #00cde5);
  z-index: -1;
  transition: 0.5s;
}
.service-sidebar__nav li.current a, .service-sidebar__nav li:hover a {
  color: var(--hiredots-white, #fff);
}
.service-sidebar__nav li.current a::before, .service-sidebar__nav li:hover a::before {
  width: 100%;
}
.service-sidebar__nav li + li {
  margin-top: 10px;
}
.service-sidebar__contact {
  padding: 40px;
  padding-top: 45px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  z-index: 1;
}
.service-sidebar__contact::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: inline-block;
  z-index: -1;
  background-color: var(--hiredots-black, #0b182a);
  opacity: 0.5;
}
.service-sidebar__contact__title {
  margin: 0;
  color: var(--hiredots-white, #fff);
  font-size: 24px;
  letter-spacing: -0.96px;
  margin-bottom: 44px;
  max-width: 200px;
}
.service-sidebar__download {
  display: flex;
  align-items: center;
  padding: 20px 30px;
}
.service-sidebar__download__icon {
  font-size: 24px;
  width: 51px;
  height: 51px;
  line-height: 51px;
  text-align: center;
  border-radius: 50%;
  margin-right: 20px;
  color: var(--hiredots-base, #00cde5);
  background-color: var(--hiredots-white, #fff);
  flex-shrink: 0;
}
.service-sidebar__download__title {
  font-size: 16px;
  font-weight: 700;
  color: var(--hiredots-white, #fff);
  line-height: 1.2;
  letter-spacing: -0.64px;
  margin: 2px 0;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.service-sidebar__download__title:hover {
  background-size: 100% 1px;
}
.service-sidebar__download__title a {
  color: currentColor;
}
.service-sidebar__download__number {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1.2px;
  color: var(--hiredots-white, #fff);
  margin-bottom: 0;
}
.service-sidebar .hiredots-btn {
  padding: 9px 25px;
  height: 38px;
  font-size: 12px;
  font-weight: 700;
}

/*--------------------------------------------------------------
# Blog details
--------------------------------------------------------------*/
.blog-details {
  position: relative;
}
.blog-details .blog-card__image img {
  transform: scale(1);
  transform: translatex(0%) scalex(1);
  opacity: 1;
  filter: blur(0px);
  margin-bottom: 0;
}
.blog-details .blog-card-two .blog-card__image {
  margin-bottom: 0;
}
.blog-details .blog-card:hover .blog-card__image img {
  transform: scale(1);
  transform: translatex(0%) scalex(1);
  opacity: 1;
  filter: blur(0px);
}
.blog-details .blog-card-two__text {
  margin: 0;
}
.blog-details .blog-card-two__text + .blog-card-two__text {
  margin-top: 31px;
}
.blog-details .blog-card__title {
  margin-bottom: 10px;
}
.blog-details .blog-card-two__content {
  padding-bottom: 50px;
  padding: 30px 40px;
  background-color: var(--hiredots-white, #fff);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
  z-index: 1;
  margin-top: -40px;
  margin-right: 40px;
  position: relative;
}
@media (max-width: 1199px) {
  .blog-details .blog-card-two__content {
    margin-right: 0;
  }
}
.blog-details__meta {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-top: 30px;
  border-top: 1px solid var(--hiredots-gray, #f0f3f9);
}
.blog-details__tags {
  display: flex;
  align-items: center;
  gap: 10px;
}
.blog-details__tags__title {
  margin: 0;
  line-height: 1em;
  font-size: 20px;
  font-weight: bold;
  margin-top: -4px;
  margin-bottom: -4px;
}
.blog-details__tags .sidebar__tags a {
  background-color: var(--hiredots-base, #00cde5);
  color: var(--hiredots-white, #fff);
  font-size: 12px;
  font-weight: 700;
}
.blog-details__tags .sidebar__tags a:hover {
  background-color: var(--hiredots-primary, #0b2f81);
}
.blog-details__social {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.blog-details__social a {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--hiredots-gray, #f0f3f9);
  font-size: 14px;
  color: var(--hiredots-black, #0b182a);
  transition: all 500ms ease;
  border-radius: 50%;
}
.blog-details__social a:hover {
  background-color: var(--hiredots-base, #00cde5);
  color: var(--hiredots-white, #fff);
}
.blog-details__paginations {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(1, 1fr);
}
@media (min-width: 768px) {
  .blog-details__paginations {
    grid-template-columns: repeat(2, 1fr);
  }
}
.blog-details__paginations .sidebar__posts__item:first-child {
  margin-top: 0;
}
.blog-details__paginations .sidebar__posts__item:last-child {
  margin-bottom: 0;
}
.blog-details__paginations .sidebar__posts__title {
  font-size: 22px;
  line-height: 1.3;
  font-weight: 800;
  text-transform: none;
  letter-spacing: var(--hiredots-letter-space-sm, -0.04em);
}
.blog-details__paginations .sidebar__posts__item:not(:last-of-type) {
  margin-bottom: 0;
  border: 0;
}
.blog-details__paginations .sidebar__posts__item {
  background-color: var(--hiredots-gray, #f0f3f9);
  padding: 60px 20px;
  transition: 0.5s;
}
.blog-details__paginations .sidebar__posts__item:hover {
  background-color: var(--hiredots-primary, #0b2f81);
}
.blog-details__paginations .sidebar__posts__item:hover .sidebar__posts__title {
  color: var(--hiredots-white, #fff);
}
@media (min-width: 1200px) {
  .blog-details__paginations .sidebar__posts__item {
    padding: 60px;
  }
}

/*--------------------------------------------------------------
# Comments
--------------------------------------------------------------*/
.comments-one {
  margin-top: 40px;
}
@media (min-width: 1200px) {
  .comments-one {
    margin-top: 55px;
  }
}
.comments-one__title {
  margin: 0;
  font-size: 25px;
  color: var(--hiredots-black, #0b182a);
  font-weight: 800;
  margin-top: -4px;
  margin-bottom: -4px;
  letter-spacing: var(--hiredots-letter-space-sm, -0.04em);
}
@media (min-width: 992px) {
  .comments-one__title {
    font-size: 30px;
  }
}
.comments-one__list {
  margin: 0;
  margin-top: 35px;
}
@media (min-width: 768px) {
  .comments-one__card {
    display: flex;
    align-items: flex-start;
  }
}
.comments-one__card:not(:first-of-type) {
  margin-top: 40px;
  padding-top: 40px;
  border-top: 1px solid var(--hiredots-border-color, #cbd2da);
}
@media (min-width: 1200px) {
  .comments-one__card:not(:first-of-type) {
    margin-top: 50px;
    padding-top: 50px;
  }
}
@media (min-width: 768px) {
  .comments-one__card__image {
    margin-right: 30px;
  }
}
@media (min-width: 1200px) {
  .comments-one__card__image {
    margin-right: 45px;
  }
}
.comments-one__card__image img {
  border-radius: 50%;
}
.comments-one__card__title {
  margin: 0;
  font-size: 20px;
  color: var(--hiredots-black, #0b182a);
  font-weight: 800;
  margin-top: 10px;
  margin-bottom: 40px;
  text-transform: capitalize;
  letter-spacing: var(--hiredots-letter-space-sm, -0.04em);
}
.comments-one__card__text {
  margin: 0;
  font-size: 15px;
  line-height: 2em;
  margin-bottom: 20px;
  max-width: 560px;
}
@media (min-width: 768px) {
  .comments-one__card__text {
    margin-bottom: 0;
    margin-right: 40px;
  }
}
.comments-one__card__reply {
  padding: 7.5px 21px;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: var(--hiredots-letter-space, 0.1em);
  background-color: var(--hiredots-gray, #f0f3f9);
  color: var(--hiredots-black, #0b182a);
}
@media (min-width: 768px) {
  .comments-one__card__reply {
    position: absolute;
    top: 0;
    right: 0;
  }
}
.comments-one__card__reply:hover {
  color: var(--hiredots-white, #fff);
}
.comments-one__card__reply::after {
  background-color: var(--hiredots-base, #00cde5);
}
.comments-one__card__reply::before {
  background-color: var(--hiredots-black, #0b182a);
}
.comments-one__card__content {
  position: relative;
}

.comments-form {
  margin-top: 40px;
}
@media (min-width: 1200px) {
  .comments-form {
    margin-top: 55px;
  }
}
.comments-form__title {
  margin: 0;
  font-size: 25px;
  color: var(--hiredots-black, #0b182a);
  font-weight: 800;
  margin-top: -4px;
  margin-bottom: -4px;
  letter-spacing: var(--hiredots-letter-space-sm, -0.04em);
}
@media (min-width: 992px) {
  .comments-form__title {
    font-size: 30px;
  }
}
.comments-form__form {
  margin-top: 45px;
}

/*--------------------------------------------------------------
# Faq
--------------------------------------------------------------*/
.faq-page-search {
  position: relative;
  padding: 120px 0;
}
@media (max-width: 767px) {
  .faq-page-search {
    padding: 80px 0;
  }
}
.faq-page-search .sec-title {
  padding-bottom: 40px;
}
.faq-page-search__form {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 20px;
  width: 100%;
  max-width: 700px;
  margin-top: 25px;
  margin: 0 auto;
}
.faq-page-search__form input[type=text] {
  border: none;
  outline: none;
  display: block;
  background-color: var(--hiredots-white, #fff);
  color: var(--hiredots-text, #696e76);
  font-size: 14px;
  width: 100%;
  font-weight: 500;
  padding-left: 50px;
  padding-right: 70px;
  height: 64px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
}
@media (min-width: 768px) {
  .faq-page-search__form input[type=text] {
    padding-left: 50px;
    padding-right: 70px;
  }
}
.faq-page-search__form__btn {
  border: none;
  outline: none;
  background-color: transparent;
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
  font-size: 20px;
  color: var(--hiredots-base, #00cde5);
  transition: all 500ms ease;
}
.faq-page-search__form__btn:hover {
  color: var(--hiredots-primary, #0b2f81);
}
@media (min-width: 768px) {
  .faq-page-search__form__btn {
    font-size: 22px;
    right: 50px;
  }
}

.faq-page {
  position: relative;
  padding-bottom: 120px;
}
@media (max-width: 767px) {
  .faq-page {
    padding-bottom: 80px;
  }
}
.faq-page__contact {
  padding: 30px;
}
@media (min-width: 1200px) {
  .faq-page__contact {
    padding: 50px;
  }
}
.faq-page__contact__img {
  margin-top: 10px;
}
.faq-page__contact__img img {
  width: 100%;
  height: auto;
}
.faq-page__contact__title {
  text-transform: capitalize;
  margin: 0;
  color: var(--hiredots-white, #fff);
  font-size: 20px;
  line-height: 1.2em;
  font-weight: bold;
  margin-top: -4px;
  margin-bottom: 21px;
  letter-spacing: var(--hiredots-letter-space-sm, -0.04em);
}
@media (min-width: 768px) {
  .faq-page__contact__title {
    font-size: 24px;
    max-width: 200px;
  }
}
.faq-page__contact__text {
  display: flex;
  align-items: center;
  margin-bottom: -6px;
}
.faq-page__contact__text br {
  display: inherit;
}
.faq-page__contact__text > i {
  width: 40px;
  height: 40px;
  background-color: var(--hiredots-black, #0b182a);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--hiredots-white, #fff);
  font-size: 14px;
  flex-shrink: 0;
  border-radius: 50%;
  margin-right: 16px;
}
.faq-page__contact__number {
  color: var(--hiredots-white, #fff);
  font-size: 14px;
  line-height: 24px;
  margin: 0;
  font-weight: 500;
}
.faq-page__contact__number a {
  font-size: 16px;
  color: inherit;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
  transition: all 500ms ease;
  transition: all 500ms ease;
}
.faq-page__contact__number a:hover {
  background-size: 100% 1px;
}
.faq-page__contact__number a:hover {
  color: var(--hiredots-black, #0b182a);
}
.faq-page__accordion {
  position: relative;
}
@media (max-width: 991px) {
  .faq-page__accordion {
    margin: 40px 0 0;
  }
}
.faq-page__accordion .accrodion {
  background-color: var(--hiredots-gray, #f0f3f9);
}
.faq-page__accordion .accrodion + .accrodion {
  margin-top: 10px;
}
.faq-page__accordion .accrodion-title {
  padding: 27px 30px;
  padding-right: 70px;
  cursor: pointer;
}
@media (min-width: 768px) {
  .faq-page__accordion .accrodion-title {
    padding-left: 40px;
  }
}
.faq-page__accordion .accrodion-title h4 {
  font-weight: 800;
  color: var(--hiredots-black, #0b182a);
  font-size: 18px;
  margin: 0;
  transition: all 500ms ease;
  position: relative;
  letter-spacing: var(--hiredots-letter-space-sm, -0.04em);
}
.faq-page__accordion .accrodion-title__icon {
  width: 12px;
  height: 12px;
  position: absolute;
  top: 50%;
  right: -30px;
  transform: translateY(-50%);
}
.faq-page__accordion .accrodion-title__icon::after, .faq-page__accordion .accrodion-title__icon::before {
  width: 2px;
  height: 12px;
  position: absolute;
  background-color: var(--hiredots-base, #00cde5);
  top: 50%;
  left: 50%;
  content: "";
  transform: translate(-50%, -50%);
  transition: all 500ms ease;
}
.faq-page__accordion .accrodion-title__icon::after {
  width: 12px;
  height: 2px;
}
.faq-page__accordion .active .accrodion-title h4 {
  color: var(--hiredots-base, #00cde5);
}
.faq-page__accordion .active .accrodion-title__icon::after, .faq-page__accordion .active .accrodion-title__icon::before {
  background-color: var(--hiredots-black, #0b182a);
  opacity: 0;
}
.faq-page__accordion .active .accrodion-title__icon::after {
  opacity: 1;
}
.faq-page__accordion .accrodion-content .inner {
  padding: 0px 30px 32px;
  margin-top: -10px;
}
@media (min-width: 768px) {
  .faq-page__accordion .accrodion-content .inner {
    padding-left: 40px;
    padding-right: 40px;
  }
}
.faq-page__accordion .accrodion-content p {
  margin: 0;
  font-size: 16px;
  line-height: 30px;
  font-weight: 500;
}

.faq-one {
  position: relative;
  padding: 120px 0;
}
@media (max-width: 767px) {
  .faq-one {
    padding: 80px 0;
  }
}
.faq-one__content {
  position: relative;
}
.faq-one__content .sec-title {
  padding-bottom: 40px;
}
.faq-one__content__title {
  position: relative;
  display: block;
  font-size: 20px;
  line-height: 36px;
  letter-spacing: -0.8px;
  margin: 0 0 34px;
}
.faq-one__content__title::before {
  position: relative;
  display: block;
  width: 100%;
  height: 10px;
  content: "";
  background-color: var(--hiredots-primary, #0b2f81);
  margin-bottom: 20px;
}
.faq-one__content__text {
  font-size: 16px;
  line-height: 30px;
  margin: 0;
}
.faq-one__experiance {
  position: relative;
  border: 1px solid var(--hiredots-border-color-2, #ced5dd);
}
@media (max-width: 1199px) {
  .faq-one__experiance {
    margin-bottom: 30px;
  }
}
.faq-one__experiance img {
  width: 100%;
  height: auto;
}
.faq-one__experiance__content {
  position: relative;
  padding: 18px 100px 26px 29px;
}
.faq-one__experiance__icon {
  width: 92px;
  height: 92px;
  background-color: var(--hiredots-base, #00cde5);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: var(--hiredots-white, #fff);
  font-size: 56px;
  position: absolute;
  right: 26px;
  top: -27px;
  z-index: 2;
}
.faq-one__experiance__count {
  display: flex;
  font-size: 46px;
  color: var(--hiredots-black, #0b182a);
  font-weight: 800;
  line-height: 1;
  margin-bottom: 7px;
}
.faq-one__experiance__text {
  font-size: 16px;
  line-height: 26px;
  margin: 0;
}

/*--------------------------------------------------------------
# Offer
--------------------------------------------------------------*/
.offer-one {
  padding-bottom: 120px;
}
@media (max-width: 767px) {
  .offer-one {
    padding-bottom: 80px;
  }
}
.offer-one--home {
  padding: 100px 0;
}
@media (max-width: 767px) {
  .offer-one--home {
    padding: 80px 0;
  }
}
.offer-one .container-fluid {
  max-width: 1604px;
}
.offer-one__card {
  padding: 20px;
  background-size: cover;
  position: relative;
  overflow: hidden;
}
.offer-one__card::before {
  background: linear-gradient(90deg, rgba(var(--hiredots-white-rgb, 255, 255, 255), 0.13) 0px, rgba(var(--hiredots-white-rgb, 255, 255, 255), 0.13) 77%, rgba(var(--hiredots-white-rgb, 255, 255, 255), 0.5) 92%, rgba(var(--hiredots-white-rgb, 255, 255, 255), 0));
  content: "";
  height: 200%;
  left: -210%;
  opacity: 0;
  position: absolute;
  top: -50%;
  transition: all 0.7s ease 0s;
  width: 200%;
}
.offer-one__card:hover::before {
  left: -30%;
  opacity: 1;
  top: -20%;
  transition-duration: 0.7s, 0.7s, 0.15s;
  transition-property: left, top, opacity;
  transition-timing-function: linear;
}
.offer-one__card__inner {
  position: relative;
  border: 1px solid var(--hiredots-white, #fff);
  padding: 40px;
}
@media (min-width: 992px) {
  .offer-one__card__inner {
    padding: 50px;
  }
}
.offer-one__card__shape {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}
@media (min-width: 992px) {
  .offer-one__card__shape {
    display: block;
  }
}
.offer-one__card__value {
  font-family: var(--hiredots-special-font, "reeyregular", cursive);
  font-weight: 400;
  font-size: 30px;
  color: var(--hiredots-base, #00cde5);
  line-height: 1;
  margin: 0;
}
@media (min-width: 992px) {
  .offer-one__card__value {
    font-size: 40px;
  }
}
.offer-one__card__title {
  position: relative;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0;
  font-size: 30px;
  line-height: 1.2em;
  margin-bottom: 17px;
}
@media (min-width: 992px) {
  .offer-one__card__title {
    font-size: 40px;
  }
}

/*--------------------------------------------------------------
# Animations
--------------------------------------------------------------*/
@keyframes bubbleMover {
  0% {
    -webkit-transform: translateY(0px) translateX(0) rotate(0);
    transform: translateY(0px) translateX(0) rotate(0);
  }
  30% {
    -webkit-transform: translateY(30px) translateX(50px) rotate(15deg);
    transform: translateY(30px) translateX(50px) rotate(15deg);
    -webkit-transform-origin: center center;
    transform-origin: center center;
  }
  50% {
    -webkit-transform: translateY(50px) translateX(100px) rotate(45deg);
    transform: translateY(50px) translateX(100px) rotate(45deg);
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
  }
  80% {
    -webkit-transform: translateY(30px) translateX(50px) rotate(15deg);
    transform: translateY(30px) translateX(50px) rotate(15deg);
    -webkit-transform-origin: left top;
    transform-origin: left top;
  }
  100% {
    -webkit-transform: translateY(0px) translateX(0) rotate(0);
    transform: translateY(0px) translateX(0) rotate(0);
    -webkit-transform-origin: center center;
    transform-origin: center center;
  }
}
@keyframes shapeMover {
  0%, 100% {
    transform: perspective(400px) translateY(0) rotate(0deg) translateZ(0px) translateX(0);
  }
  50% {
    transform: perspective(400px) rotate(-45deg) translateZ(20px) translateY(20px) translateX(20px);
  }
}
@keyframes banner3Shake {
  0% {
    -webkit-transform: rotate3d(0, 1, 0, 0deg);
    transform: rotate3d(0, 1, 0, 0deg);
  }
  30% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }
  60% {
    -webkit-transform: rotate3d(1, 0, 0, 0deg);
    transform: rotate3d(1, 0, 0, 0deg);
  }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }
  100% {
    -webkit-transform: rotate3d(0, 1, 0, 0deg);
    transform: rotate3d(0, 1, 0, 0deg);
  }
}
@keyframes squareMover {
  0%, 100% {
    -webkit-transform: translate(0, 0) rotate(0);
    transform: translate(0, 0) rotate(0);
  }
  20%, 60% {
    -webkit-transform: translate(20px, 40px) rotate(180deg);
    transform: translate(20px, 40px) rotate(180deg);
  }
  30%, 80% {
    -webkit-transform: translate(40px, 60px) rotate(0deg);
    transform: translate(40px, 60px) rotate(0deg);
  }
}
@keyframes treeMove {
  0%, 100% {
    -webkit-transform: rotate(0deg) translateX(0);
    transform: rotate(0deg) translateX(0);
  }
  25%, 75% {
    -webkit-transform: rotate(5deg) translateX(15px);
    transform: rotate(5deg) translateX(15px);
  }
  50% {
    -webkit-transform: rotate(10deg) translateX(30px);
    transform: rotate(10deg) translateX(30px);
  }
}
@keyframes leafMove {
  0%, 100% {
    -webkit-transform: rotate(0deg) translateX(0);
    transform: rotate(0deg) translateX(0);
  }
  25%, 75% {
    transform: rotate(-2deg) translateX(5px);
  }
  50% {
    transform: rotate(-4deg) translateX(10px);
  }
}
@keyframes messageMove {
  0%, 100% {
    transform: translateX(0);
  }
  25%, 75% {
    transform: translateX(5px);
  }
  50% {
    transform: translateX(10px);
  }
}
@keyframes textRotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes flowerRotate {
  0%, 100% {
    transform: rotate(0deg);
  }
  25%, 75% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(10deg);
  }
}
@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
/*--------------------------------------------------------------
# Mobile Nav
--------------------------------------------------------------*/
.mobile-nav__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  transform: translateX(-100%);
  transform-origin: left center;
  transition: transform 500ms ease 500ms, visibility 500ms ease 500ms;
  visibility: hidden;
  position: fixed;
}
.mobile-nav__wrapper .container {
  padding-left: 0;
  padding-right: 0;
}
.mobile-nav__wrapper .home-showcase .row [class*=col-] {
  flex: 0 0 100%;
}
.mobile-nav__wrapper .home-showcase {
  margin-bottom: -1px;
  margin-top: 0;
  border-bottom: 1px solid RGBA(var(--hiredots-white-rgb, 255, 255, 255), 0.1);
}
.mobile-nav__wrapper .home-showcase__inner {
  padding: 15px 0px;
  background-color: transparent;
  box-shadow: none;
}
.mobile-nav__wrapper .home-showcase__title {
  color: var(--hiredots-white, #fff);
}

.mobile-nav__wrapper.expanded {
  opacity: 1;
  transform: translateX(0%);
  visibility: visible;
  transition: transform 500ms ease 0ms, visibility 500ms ease 0ms;
}
.mobile-nav__wrapper.expanded .mobile-nav__content {
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
  transition: opacity 500ms ease 500ms, visibility 500ms ease 500ms, transform 500ms ease 500ms;
}

.mobile-nav__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--hiredots-black, #0b182a);
  opacity: 0.3;
  cursor: url(../images/close.png), auto;
}

.mobile-nav__content {
  width: 300px;
  background-color: var(--hiredots-black2, #12243c);
  z-index: 10;
  position: relative;
  height: 100%;
  overflow-y: auto;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
  opacity: 0;
  visibility: hidden;
  transform: translateX(-100%);
  transition: opacity 500ms ease 0ms, visibility 500ms ease 0ms, transform 500ms ease 0ms;
}
.mobile-nav__content .main-menu__nav {
  display: block;
  padding: 0;
}

.mobile-nav__content .logo-box {
  margin-bottom: 40px;
  display: flex;
}

.mobile-nav__close {
  position: absolute;
  top: 20px;
  right: 15px;
  font-size: 18px;
  color: var(--hiredots-white, #fff);
  cursor: pointer;
}
.mobile-nav__close:hover {
  color: var(--hiredots-base, #00cde5);
}

.mobile-nav__content .main-menu__list,
.mobile-nav__content .main-menu__list ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.mobile-nav__content .main-menu__list ul {
  display: none;
  border-top: 1px solid RGBA(var(--hiredots-white-rgb, 255, 255, 255), 0.1);
}

.mobile-nav__content .main-menu__list ul li > a {
  padding-left: 1em;
}

.mobile-nav__content .main-menu__list li:not(:last-child) {
  border-bottom: 1px solid RGBA(var(--hiredots-white-rgb, 255, 255, 255), 0.1);
}

.mobile-nav__content .main-menu__list li > a {
  display: flex;
  justify-content: space-between;
  line-height: 30px;
  color: var(--hiredots-white, #fff);
  font-size: 12px;
  font-family: var(--hiredots-font, "Manrope", sans-serif);
  text-transform: uppercase;
  font-weight: 500;
  height: 46px;
  letter-spacing: var(--hiredots-letter-space, 0.1em);
  align-items: center;
  transition: 500ms;
}

.mobile-nav__content .main-menu__list li a.expanded {
  color: var(--hiredots-base, #00cde5);
}

.mobile-nav__content .main-menu__list li a button {
  width: 30px;
  height: 30px;
  background-color: var(--hiredots-base, #00cde5);
  border: none;
  outline: none;
  color: var(--hiredots-white, #fff);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transform: rotate(-90deg);
  transition: transform 500ms ease;
}

.mobile-nav__content .main-menu__list li a button.expanded {
  transform: rotate(0deg);
  background-color: var(--hiredots-white, #fff);
  color: var(--hiredots-black, #0b182a);
}

.mobile-nav__social {
  display: flex;
  align-items: center;
}
.mobile-nav__social a {
  font-size: 16px;
  color: var(--hiredots-white, #fff);
  transition: 500ms;
}
.mobile-nav__social a + a {
  margin-left: 20px;
}
.mobile-nav__social a:hover {
  color: var(--hiredots-base, #00cde5);
}

.mobile-nav__contact {
  margin-bottom: 0;
  margin-top: 20px;
  margin-bottom: 20px;
}
.mobile-nav__contact li {
  color: var(--hiredots-white, #fff);
  font-size: 14px;
  font-weight: 500;
  position: relative;
  display: flex;
  align-items: center;
}
.mobile-nav__contact li + li {
  margin-top: 15px;
}
.mobile-nav__contact li a {
  color: inherit;
  transition: 500ms;
}
.mobile-nav__contact li a:hover {
  color: var(--hiredots-base, #00cde5);
}
.mobile-nav__contact li > i {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: var(--hiredots-base, #00cde5);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 12px;
  margin-right: 10px;
  color: var(--hiredots-white, #fff);
}

.mobile-nav__container .main-menu__logo,
.mobile-nav__container .main-menu__right {
  display: none;
}

/*--------------------------------------------------------------
# Search Popup
--------------------------------------------------------------*/
.search-popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -2;
  -webkit-transition: all 1s ease;
  -khtml-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.search-popup__overlay {
  position: fixed;
  width: 224vw;
  height: 224vw;
  top: calc(90px - 112vw);
  right: calc(50% - 112vw);
  z-index: 3;
  display: block;
  -webkit-border-radius: 50%;
  -khtml-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  -webkit-transform: scale(0);
  -khtml-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-transition: transform 0.8s ease-in-out;
  -khtml-transition: transform 0.8s ease-in-out;
  -moz-transition: transform 0.8s ease-in-out;
  -ms-transition: transform 0.8s ease-in-out;
  -o-transition: transform 0.8s ease-in-out;
  transition: transform 0.8s ease-in-out;
  transition-delay: 0s;
  transition-delay: 0.3s;
  -webkit-transition-delay: 0.3s;
  background-color: #000;
  opacity: 0.9;
  cursor: url(../images/close.png), auto;
}
@media (max-width: 767px) {
  .search-popup__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: none;
    width: 100%;
    height: 100%;
    border-radius: 0;
    transform: translateY(-110%);
  }
}
.search-popup__content {
  position: fixed;
  width: 0;
  max-width: 560px;
  padding: 30px 15px;
  left: 50%;
  top: 50%;
  opacity: 0;
  z-index: 3;
  -webkit-transform: translate(-50%, -50%);
  -khtml-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: opacity 0.5s 0s, width 0.8s 0.8s cubic-bezier(0.225, 0.01, 0.475, 1.01), transform 0.2s 0s;
  -khtml-transition: opacity 0.5s 0s, width 0.8s 0.8s cubic-bezier(0.225, 0.01, 0.475, 1.01), transform 0.2s 0s;
  -moz-transition: opacity 0.5s 0s, width 0.8s 0.8s cubic-bezier(0.225, 0.01, 0.475, 1.01), transform 0.2s 0s;
  -ms-transition: opacity 0.5s 0s, width 0.8s 0.8s cubic-bezier(0.225, 0.01, 0.475, 1.01), transform 0.2s 0s;
  -o-transition: opacity 0.5s 0s, width 0.8s 0.8s cubic-bezier(0.225, 0.01, 0.475, 1.01), transform 0.2s 0s;
  transition: opacity 0.5s 0s, width 0.8s 0.8s cubic-bezier(0.225, 0.01, 0.475, 1.01), transform 0.2s 0s;
  transition-delay: 0s, 0.8s, 0s;
  transition-delay: 0s, 0.4s, 0s;
  transition-delay: 0.2s;
  -webkit-transition-delay: 0.2s;
}
.search-popup__form {
  position: relative;
}
.search-popup__form input[type=search],
.search-popup__form input[type=text] {
  width: 100%;
  background-color: var(--hiredots-white, #fff);
  font-size: 15px;
  color: var(--hiredots-text, #696e76);
  border: none;
  outline: none;
  height: 66px;
  padding-left: 30px;
}
.search-popup__form .hiredots-btn {
  padding: 0;
  width: 66px;
  height: 66px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 0;
  right: -1px;
  border-radius: 0;
}
.search-popup__form .hiredots-btn i {
  margin: 0;
}
.search-popup__form .hiredots-btn::after {
  background-color: var(--hiredots-black, #0b182a);
}
.search-popup.active {
  z-index: 9999;
}
.search-popup.active .search-popup__overlay {
  top: auto;
  bottom: calc(90px - 112vw);
  -webkit-transform: scale(1);
  -khtml-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  transition-delay: 0s;
  -webkit-transition-delay: 0s;
  opacity: 0.9;
  -webkit-transition: transform 1.6s cubic-bezier(0.4, 0, 0, 1);
  -khtml-transition: transform 1.6s cubic-bezier(0.4, 0, 0, 1);
  -moz-transition: transform 1.6s cubic-bezier(0.4, 0, 0, 1);
  -ms-transition: transform 1.6s cubic-bezier(0.4, 0, 0, 1);
  -o-transition: transform 1.6s cubic-bezier(0.4, 0, 0, 1);
  transition: transform 1.6s cubic-bezier(0.4, 0, 0, 1);
}
@media (max-width: 767px) {
  .search-popup.active .search-popup__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: none;
    width: 100%;
    height: 100%;
    border-radius: 0;
    transform: translateY(0%);
  }
}
.search-popup.active .search-popup__content {
  width: 100%;
  opacity: 1;
  transition-delay: 0.7s;
  -webkit-transition-delay: 0.7s;
}

/*--------------------------------------------------------------
# Page Header
--------------------------------------------------------------*/
.page-header {
  position: relative;
  padding-top: 120px;
  position: relative;
  overflow: hidden;
}
@media (min-width: 992px) {
  .page-header {
    padding-top: 177px;
  }
}
.page-header::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  z-index: 1;
  background-color: rgba(var(--hiredots-black-rgb, 11, 24, 42), 0.3);
}
.page-header__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(../images/backgrounds/page-header-bg-1-1.jpg);
  z-index: -1;
}
.page-header__shape {
  position: absolute;
  left: 0;
  bottom: 0;
  background-repeat: no-repeat;
  mix-blend-mode: multiply;
  z-index: 2;
}
.page-header .container {
  position: relative;
  z-index: 10;
}
.page-header__title {
  margin: 0;
  font-size: 40px;
  font-weight: 800;
  letter-spacing: -1px;
  margin-bottom: 100px;
  color: var(--hiredots-white, #fff);
}
@media (min-width: 768px) {
  .page-header__title {
    font-size: 46px;
    letter-spacing: -1.8px;
    margin-bottom: 117px;
  }
}

.hiredots-breadcrumb {
  display: inline-flex;
  justify-content: end;
  align-items: center;
  flex-wrap: wrap;
  margin: 0;
  float: right;
  padding: 18px 40px;
  background-color: rgba(var(--hiredots-black-rgb, 11, 24, 42), 0.9);
}
.hiredots-breadcrumb li {
  font-weight: 600;
  font-size: 14px;
  color: var(--hiredots-base, #00cde5);
  display: flex;
  align-items: center;
}
.hiredots-breadcrumb li:not(:last-of-type)::after {
  content: "/";
  position: relative;
  top: 1px;
  margin-left: 6px;
  margin-right: 6px;
  color: var(--hiredots-white, #fff);
}
.hiredots-breadcrumb li span,
.hiredots-breadcrumb li a {
  color: inherit;
  display: inline-flex;
  line-height: 1em;
}
.hiredots-breadcrumb li span:hover,
.hiredots-breadcrumb li a:hover {
  color: var(--hiredots-base, #00cde5);
}
.hiredots-breadcrumb li a {
  color: var(--hiredots-white, #fff);
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.hiredots-breadcrumb li a:hover {
  background-size: 100% 1px;
}

/*--------------------------------------------------------------
# Google Map
--------------------------------------------------------------*/
.google-map {
  position: relative;
}
.google-map iframe {
  position: relative;
  display: block;
  border: none;
  height: 475px;
  width: 100%;
  mix-blend-mode: luminosity;
}
.google-map__contact {
  overflow: hidden;
  background-color: var(--hiredots-gray, #f0f3f9);
}

.contact-map {
  position: relative;
  padding-bottom: 120px;
}
@media (max-width: 767px) {
  .contact-map {
    padding-bottom: 80px;
  }
}
.contact-map .container-fluid {
  padding-left: 0;
  padding-right: 0;
  max-width: 1654px;
}
.contact-map--home-three {
  position: relative;
  overflow: hidden;
  padding: 0;
}
.contact-map--home-three .google-map iframe {
  height: 483px;
}

/*--------------------------------------------------------------
# Client Carousel
--------------------------------------------------------------*/
.client-carousel {
  padding: 80px 0 0;
}
.client-carousel__title {
  font-size: 16px;
  line-height: 28px;
  font-weight: 700;
  letter-spacing: -0.64px;
  margin: -18px 0 0;
}
@media (max-width: 1199px) {
  .client-carousel__title {
    margin: 0 0 30px;
  }
}
.client-carousel__one {
  position: relative;
}
.client-carousel__one__item img {
  opacity: 0.3;
  transition: all 500ms ease;
  max-width: 100%;
  width: auto !important;
}
.client-carousel__one__item:hover img {
  opacity: 0.6;
}
.client-carousel__border {
  border-bottom: 1px solid var(--hiredots-border-color-2, #ced5dd);
  padding-top: 66px;
}

.client-carousel-one {
  position: relative;
  background: var(--hiredots-white, #fff);
  padding: 0 0 46px;
}
.client-carousel-one .client-carousel__one {
  border-top: 1px solid var(--hiredots-border-color-2, #ced5dd);
  padding-top: 46px;
}
.client-carousel-one .client-carousel__one__item {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 90px;
  transition: all 500ms ease;
}
.client-carousel-one .client-carousel__one__item img {
  transition: all 500ms ease;
  opacity: 0.3;
  max-width: 100%;
  width: auto;
}
.client-carousel-one .client-carousel__one__item:hover {
  background-color: var(--hiredots-gray, #f0f3f9);
}
.client-carousel-one .client-carousel__one__item:hover img {
  opacity: 0.6;
}

/*--------------------------------------------------------------
# Hero Slider
--------------------------------------------------------------*/
.main-slider-one {
  position: relative;
  overflow: hidden;
}
.main-slider-one__carousel {
  position: relative;
}
.main-slider-one__carousel.owl-carousel .owl-nav {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  max-width: 1170px;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0;
  margin: 0 auto;
  z-index: 3;
}
@media (max-width: 1199px) {
  .main-slider-one__carousel.owl-carousel .owl-nav {
    width: 100%;
    max-width: 100%;
  }
}
.main-slider-one__carousel.owl-carousel .owl-nav button {
  transition: all 400ms ease;
  width: 74px;
  height: 102px;
  outline: none;
  box-shadow: none;
  border: none;
  background-color: var(--hiredots-primary, #0b2f81);
  border-radius: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--hiredots-white, #fff);
  font-size: 16px;
  margin: 0;
}
.main-slider-one__carousel.owl-carousel .owl-nav button.owl-next {
  background-color: var(--hiredots-base, #00cde5);
}
.main-slider-one__carousel.owl-carousel .owl-nav button.owl-next:hover {
  background-color: var(--hiredots-primary, #0b2f81);
}
.main-slider-one__carousel.owl-carousel .owl-nav button:hover {
  background-color: var(--hiredots-base, #00cde5);
}
.main-slider-one__carousel .owl-dots {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  max-width: 874px;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 58px;
  margin: 0 auto;
  height: 102px;
  z-index: 3;
}
.main-slider-one__carousel .owl-dots::after {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 183px;
  content: "";
  background-color: rgba(var(--hiredots-black-rgb, 11, 24, 42), 0.9);
}
@media (max-width: 1199px) {
  .main-slider-one__carousel .owl-dots {
    width: 100%;
    max-width: 100%;
    right: 148px;
    left: auto;
  }
}
@media (max-width: 991px) {
  .main-slider-one__carousel .owl-dots {
    display: none;
  }
}
.main-slider-one__carousel .owl-dots .owl-dot {
  display: block;
  margin: 0 0 0 6px;
  position: relative;
  z-index: 2;
}
.main-slider-one__carousel .owl-dots .owl-dot button {
  width: auto;
  height: auto;
  border: none;
  margin: 0;
  padding: 0;
  font-size: 24px;
  color: var(--hiredots-white, #fff);
  font-weight: 400;
  display: block;
  background-color: transparent;
  margin: 0;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.main-slider-one__carousel .owl-dots .owl-dot:hover button, .main-slider-one__carousel .owl-dots .owl-dot.active button {
  color: var(--hiredots-base, #00cde5);
}
.main-slider-one__item {
  background-color: var(--hiredots-black, #0b182a);
  position: relative;
  z-index: 3;
  padding-top: 128px;
  padding-bottom: 157px;
}
@media (max-width: 767px) {
  .main-slider-one__item {
    padding-top: 120px;
  }
}
.main-slider-one__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--hiredots-black, #0b182a);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 0.7;
}
.main-slider-one__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(90deg, rgb(11, 24, 42) 0%, rgba(11, 24, 42, 0) 100%);
  opacity: 0.9;
}
.main-slider-one__shape {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: auto;
  mix-blend-mode: multiply;
  transition: transform 1000ms ease, opacity 1000ms ease;
  transform: translateY(100%);
}
.main-slider-one__content {
  position: relative;
  z-index: 3;
}
.main-slider-one__image {
  opacity: 0;
  display: flex;
  align-items: center;
  margin-left: -16%;
  justify-content: center;
  transition: transform 1000ms ease, opacity 1000ms ease;
  transform: translateY(-300px);
  margin-bottom: -61px;
}
.main-slider-one__image img {
  width: 185px !important;
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}
@media (max-width: 767px) {
  .main-slider-one__image img {
    width: 155px !important;
  }
}
@media (max-width: 991px) {
  .main-slider-one__image {
    margin-bottom: -31px;
  }
}
@media (max-width: 767px) {
  .main-slider-one__image {
    margin-bottom: 0;
  }
}
.main-slider-one__title {
  color: var(--hiredots-white, #fff);
  font-size: 50px;
  line-height: 90px;
  margin: 0 0 60px;
  opacity: 0;
  transition: transform 1200ms ease, opacity 1200ms ease;
  transform: translateY(200px);
}
.main-slider-one__title br {
  display: block;
}
@media (max-width: 767px) {
  .main-slider-one__title {
    font-size: 50px;
    line-height: 55px;
  }
}
.main-slider-one__btn {
  position: relative;
  display: flex;
  z-index: 5;
  overflow: hidden;
  opacity: 0;
  transform: perspective(400px) rotateY(0deg) translateY(80px);
  transform-origin: bottom;
  transition: all 1500ms ease;
}
.main-slider-one .slick-active .main-slider-one__shape {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 1700ms;
}
.main-slider-one .slick-active .main-slider-one__image {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 1200ms;
}
.main-slider-one .slick-active .main-slider-one__title {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 1400ms;
}
.main-slider-one .slick-active .main-slider-one__btn {
  opacity: 1;
  transform: perspective(400px) rotateY(0deg) translateY(0px);
  transition-delay: 1600ms;
}

.main-slider-two {
  position: relative;
  overflow: hidden;
}
.main-slider-two__carousel {
  position: relative;
}
.main-slider-two__carousel.owl-carousel .owl-nav {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  max-width: 1170px;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0;
  margin: 0 auto;
  z-index: 3;
}
@media (min-width: 1200px) {
  .main-slider-two__carousel.owl-carousel .owl-nav {
    right: -50px;
  }
}
@media (max-width: 1199px) {
  .main-slider-two__carousel.owl-carousel .owl-nav {
    width: 100%;
    max-width: 100%;
  }
}
.main-slider-two__carousel.owl-carousel .owl-nav button {
  transition: all 400ms ease;
  width: 74px;
  height: 102px;
  outline: none;
  box-shadow: none;
  border: none;
  background-color: var(--hiredots-black, #0b182a);
  border-radius: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--hiredots-white, #fff);
  font-size: 16px;
  margin: 0;
}
.main-slider-two__carousel.owl-carousel .owl-nav button.owl-next {
  background-color: var(--hiredots-white, #fff);
  color: var(--hiredots-black, #0b182a);
}
.main-slider-two__carousel.owl-carousel .owl-nav button.owl-next:hover {
  background-color: var(--hiredots-base, #00cde5);
  color: var(--hiredots-white, #fff);
}
.main-slider-two__carousel.owl-carousel .owl-nav button:hover {
  background-color: var(--hiredots-base, #00cde5);
  color: var(--hiredots-white, #fff);
}
.main-slider-two__item {
  background-color: var(--hiredots-black, #0b182a);
  position: relative;
  z-index: 3;
  padding-top: 207px;
  padding-bottom: 212px;
}
@media (max-width: 767px) {
  .main-slider-two__item {
    padding-top: 120px;
    padding-bottom: 160px;
  }
}
.main-slider-two__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--hiredots-black, #0b182a);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 0.5;
  transition: transform 10000ms ease, -webkit-transform 10000ms ease;
}
.main-slider-two__shape-top {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: right top;
  background-size: auto;
  transition: transform 1000ms ease, opacity 1000ms ease;
  transform: translateY(-100%);
}
@media (max-width: 1199px) {
  .main-slider-two__shape-top {
    right: -50px;
  }
}
@media (max-width: 991px) {
  .main-slider-two__shape-top {
    display: none;
  }
}
.main-slider-two__shape-bottom {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: auto;
  transition: transform 1000ms ease, opacity 1000ms ease;
  transform: translateY(100%);
}
@media (max-width: 1199px) {
  .main-slider-two__shape-bottom {
    left: -50px;
  }
}
@media (max-width: 991px) {
  .main-slider-two__shape-bottom {
    display: none;
  }
}
.main-slider-two__content {
  position: relative;
  z-index: 3;
  text-align: center;
}
.main-slider-two__sub-title {
  opacity: 0;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: rgba(var(--hiredots-white-rgb, 255, 255, 255), 0.6);
  transition: transform 1000ms ease, opacity 1000ms ease;
  transform: translateX(-200px);
  margin-bottom: 21px;
}
@media (max-width: 767px) {
  .main-slider-two__sub-title {
    letter-spacing: 1px;
    font-size: 18px;
  }
}
.main-slider-two__title {
  color: var(--hiredots-white, #fff);
  font-size: 90px;
  line-height: 90px;
  margin: 0 0 29px;
  text-transform: uppercase;
  letter-spacing: -3.6px;
  opacity: 0;
  transition: transform 1200ms ease, opacity 1200ms ease;
  transform: translateX(200px);
}
.main-slider-two__title br {
  display: block;
}
@media (max-width: 991px) {
  .main-slider-two__title {
    font-size: 70px;
    line-height: 75px;
    letter-spacing: -2px;
  }
}
@media (max-width: 767px) {
  .main-slider-two__title {
    font-size: 36px;
    line-height: 46px;
    letter-spacing: -1.5px;
  }
}
.main-slider-two__btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  overflow: hidden;
  opacity: 0;
  transform: perspective(400px) rotateY(0deg) translateY(80px);
  transform-origin: bottom;
  transition: all 1500ms ease;
}
.main-slider-two .slick-active .main-slider-two__shape-top {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 1600ms;
}
.main-slider-two .slick-active .main-slider-two__shape-bottom {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 1600ms;
}
.main-slider-two .slick-active .main-slider-two__sub-title {
  opacity: 1;
  transform: translateX(0);
  transition-delay: 1200ms;
}
.main-slider-two .slick-active .main-slider-two__title {
  opacity: 1;
  transform: translateX(0);
  transition-delay: 1200ms;
}
.main-slider-two .slick-active .main-slider-two__btn {
  opacity: 1;
  transform: perspective(400px) rotateY(0deg) translateY(0px);
  transition-delay: 1500ms;
}

.main-slider-three {
  position: relative;
  overflow: hidden;
}
.main-slider-three__carousel {
  position: relative;
}
.main-slider-three__carousel.owl-carousel .owl-nav {
  display: flex;
  align-items: flex-end;
  gap: 10px;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  right: 0;
  left: 0;
  margin: auto;
  width: 1170px;
  text-align: right;
  outline: none;
  top: 56%;
  transform: translateY(-50%);
}
@media (max-width: 1199px) {
  .main-slider-three__carousel.owl-carousel .owl-nav {
    width: 940px;
  }
}
@media (max-width: 991px) {
  .main-slider-three__carousel.owl-carousel .owl-nav {
    flex-direction: row;
    justify-content: center;
    top: auto;
    bottom: 30px;
    transform: none;
    width: 100%;
  }
}
.main-slider-three__carousel.owl-carousel .owl-nav button {
  transition: all 400ms ease;
  width: 58px;
  height: 58px;
  outline: none;
  box-shadow: none;
  border: none;
  background-color: rgba(var(--hiredots-white-rgb, 255, 255, 255), 0.2);
  border-radius: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--hiredots-white, #fff);
  border-radius: 50%;
  font-size: 16px;
  margin: 0;
}
.main-slider-three__carousel.owl-carousel .owl-nav button:hover {
  background-color: var(--hiredots-white, #fff);
  color: var(--hiredots-black, #0b182a);
}
.main-slider-three__item {
  background-color: var(--hiredots-black, #0b182a);
  position: relative;
  z-index: 3;
  padding-top: 338px;
  padding-bottom: 198px;
}
@media (max-width: 767px) {
  .main-slider-three__item {
    padding-top: 210px;
    padding-bottom: 180px;
  }
}
.main-slider-three__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--hiredots-black, #0b182a);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 0.5;
}
.main-slider-three__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  height: 307px;
  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.main-slider-three__shape {
  position: absolute;
  left: 12%;
  bottom: 87px;
  width: 582px;
  height: 582px;
  opacity: 0;
  border-radius: 50%;
  background-color: var(--hiredots-black, #0b182a);
  mix-blend-mode: soft-light;
  animation-duration: 1s;
  animation-fill-mode: both;
  transition: transform 1200ms ease, opacity 1200ms ease;
}
@media (max-width: 1700px) {
  .main-slider-three__shape {
    left: 6%;
  }
}
@media (max-width: 1500px) {
  .main-slider-three__shape {
    left: 2%;
  }
}
@media (max-width: 1199px) {
  .main-slider-three__shape {
    left: -55px;
  }
}
@media (max-width: 767px) {
  .main-slider-three__shape {
    left: -55px;
    bottom: 115px;
    width: 400px;
    height: 400px;
  }
}
.main-slider-three__content {
  position: relative;
  z-index: 3;
}
.main-slider-three__title {
  color: var(--hiredots-white, #fff);
  font-size: 90px;
  line-height: 90px;
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: -8px;
  margin: 0 0 16px;
  opacity: 0;
  transition: transform 1200ms ease, opacity 1200ms ease;
  transform: translateX(-150px);
}
.main-slider-three__title br {
  display: block;
}
@media (max-width: 991px) {
  .main-slider-three__title {
    font-size: 75px;
    letter-spacing: -4px;
  }
}
@media (max-width: 767px) {
  .main-slider-three__title {
    font-size: 36px;
    line-height: 48px;
    letter-spacing: -2px;
  }
}
.main-slider-three__text {
  font-size: 24px;
  font-weight: 600;
  color: var(--hiredots-white, #fff);
  margin: 0 0 26px;
  opacity: 0;
  transition: transform 1200ms ease, opacity 1200ms ease;
  transform: translateX(-200px);
}
@media (max-width: 767px) {
  .main-slider-three__text {
    font-size: 20px;
  }
}
.main-slider-three__btn {
  position: relative;
  display: flex;
  z-index: 5;
  opacity: 0;
  transition: transform 1200ms ease, opacity 1200ms ease;
  transform: translateX(-200px);
}
.main-slider-three .slick-active .main-slider-three__shape {
  opacity: 1;
  animation-name: zoomIn;
  transition-delay: 1000ms;
}
.main-slider-three .slick-active .main-slider-three__title {
  opacity: 1;
  transform: translateX(0);
  transition-delay: 1200ms;
}
.main-slider-three .slick-active .main-slider-three__text {
  opacity: 1;
  transform: translateX(0);
  transition-delay: 1400ms;
}
.main-slider-three .slick-active .main-slider-three__btn {
  opacity: 1;
  transform: translateX(0);
  transition-delay: 1500ms;
}

/*--------------------------------------------------------------
# Feature 
--------------------------------------------------------------*/
.feature-one {
  position: relative;
  padding: 120px 0;
}
@media (max-width: 767px) {
  .feature-one {
    padding: 80px 0;
  }
}
.feature-one__item {
  position: relative;
  padding-bottom: 50px;
}
.feature-one__item:hover .feature-one__item__content {
  background-color: var(--hiredots-base, #00cde5);
}
.feature-one__item:hover .feature-one__item__icon {
  background-color: var(--hiredots-base, #00cde5);
}
.feature-one__item:hover .feature-one__item__icon span {
  transform: scale(0.9);
}
.feature-one__item:hover .feature-one__item__title {
  color: var(--hiredots-white, #fff);
}
.feature-one__item:hover .feature-one__item__text {
  height: 60px;
  margin-bottom: 25px;
}
.feature-one__item:hover .feature-one__item__ball::after {
  width: 220px;
  height: 220px;
}
.feature-one__item__img {
  position: relative;
  overflow: hidden;
}
.feature-one__item__img img {
  width: 100%;
  height: auto;
  min-height: 320px;
  object-fit: cover;
}
.feature-one__item__content {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: calc(100% - 30px);
  background-color: var(--hiredots-white, #fff);
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
  transition: 500ms ease;
}
.feature-one__item__ball {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.feature-one__item__ball::after {
  position: absolute;
  left: -55px;
  top: -63px;
  width: 0;
  height: 0;
  border-radius: 50%;
  content: "";
  background-color: rgba(var(--hiredots-primary-rgb, 11, 47, 129), 0.1);
  transition: 400ms ease;
}
.feature-one__item__icon {
  position: relative;
  z-index: 1;
  width: 109px;
  height: 109px;
  background-color: var(--hiredots-primary, #0b2f81);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 62px;
  color: var(--hiredots-white, #fff);
  margin: -55px auto 14px;
  transition: 500ms ease;
}
.feature-one__item__icon span {
  transform: scale(1);
  transition: 500ms ease;
}
.feature-one__item__title {
  font-size: 24px;
  letter-spacing: -0.96px;
  transition: 400ms ease;
  margin: 0 0 15px;
  position: relative;
  z-index: 1;
}
.feature-one__item__title br {
  display: block;
}
.feature-one__item__text {
  font-size: 16px;
  padding: 0 25px;
  height: 0;
  margin-bottom: 0;
  color: var(--hiredots-white, #fff);
  overflow: hidden;
  position: relative;
  z-index: 1;
  transition: all 550ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
}
.feature-one__item .hiredots-btn {
  width: 100%;
  background-color: var(--hiredots-primary, #0b2f81);
  height: 50px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1.2px;
  padding: 14px 30px;
}
.feature-one__item .hiredots-btn::after {
  background-color: var(--hiredots-black, #0b182a);
}

.features-one {
  position: relative;
  overflow: hidden;
  padding-top: 120px;
  padding-bottom: 168px;
  z-index: 1;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 767px) {
  .features-one {
    padding: 80px 0;
  }
}
@media (max-width: 991px) {
  .features-one {
    padding-bottom: 0;
  }
}
.features-one .sec-title__tagline {
  color: var(--hiredots-white, #fff);
}
.features-one .sec-title__tagline::before {
  background-color: var(--hiredots-black, #0b182a);
}
.features-one .sec-title__title {
  color: var(--hiredots-white, #fff);
}
.features-one__sec-text {
  font-size: 16px;
  line-height: 30px;
  color: var(--hiredots-white, #fff);
  margin-bottom: 31px;
}
@media (min-width: 1200px) {
  .features-one__sec-text {
    margin-right: -20px;
  }
}
.features-one .sec-title {
  padding-bottom: 31px;
}
.features-one .hiredots-stretch-element-inside-column {
  position: relative;
  height: 100%;
}
.features-one__bg {
  position: absolute;
  bottom: -168px;
  right: 0;
  z-index: -1;
  overflow: hidden;
}
@media (max-width: 991px) {
  .features-one__bg {
    position: relative;
    bottom: 0;
    margin-top: 30px;
  }
}
.features-one__bg img {
  object-fit: cover;
  width: 100%;
  height: auto;
  min-height: 614px;
}
@media (max-width: 991px) {
  .features-one__bg img {
    min-height: auto;
  }
}
.features-one__video {
  color: var(--hiredots-white, #fff);
}
.features-one__video a {
  color: currentColor;
  font-size: 20px;
  line-height: 30px;
}
.features-one__video a i {
  font-size: 25px;
  width: 90px;
  height: 90px;
  margin-right: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--hiredots-primary, #0b2f81);
  transition: 0.3s;
  position: relative;
}
.features-one__video a i::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  display: inline-block;
  box-shadow: 0 0 0 0 rgb(0, 0, 0);
  transform: scale(1);
  animation: pulse 2s infinite;
}
.features-one__video a i:hover {
  color: var(--hiredots-primary, #0b2f81);
  background-color: var(--hiredots-white, #fff);
}
.features-one__right {
  padding-left: 50px;
}
@media (max-width: 1199px) {
  .features-one__right {
    padding-left: 0;
  }
}
.features-one__list {
  background-color: var(--hiredots-white, #fff);
  padding: 25px 30px;
  padding-right: 38px;
  display: inline-flex;
  align-items: center;
  margin-top: 60px;
  flex-wrap: wrap;
  transition: all 0.5s ease;
}
.features-one__list i {
  margin-right: 20px;
  font-size: 62px;
  line-height: 1;
  color: var(--hiredots-base, #00cde5);
  flex-shrink: 0;
}
@media (max-width: 767px) {
  .features-one__list i {
    margin-bottom: 15px;
  }
}
.features-one__list__title {
  font-size: 20px;
  color: var(--hiredots-black, #0b182a);
  margin-right: 50px;
  flex-shrink: 0;
  margin-bottom: 0;
  letter-spacing: -0.8px;
}
.features-one__list__info {
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 0;
  margin-top: 20px;
  position: relative;
}
@media (min-width: 768px) {
  .features-one__list__info {
    margin-top: 0;
    padding-left: 20px;
    max-width: 190px;
  }
}
.features-one__list__info::after {
  position: absolute;
  left: 0;
  top: 6px;
  height: 62px;
  width: 1px;
  content: "";
  background-color: var(--hiredots-border-color, #cbd2da);
}
@media (max-width: 767px) {
  .features-one__list__info::after {
    display: none;
  }
}
.features-one__list + .features-one__list {
  margin-top: 10px;
}

.feature-two {
  position: relative;
}
.feature-two__item {
  position: relative;
  z-index: 5;
  width: 100%;
  background-color: var(--hiredots-white, #fff);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
  padding: 40px 40px 36px;
}
@media (min-width: 992px) {
  .feature-two__item {
    margin-top: -30px;
  }
}
.feature-two__item__top {
  display: flex;
  align-items: center;
  margin: 0 0 16px;
}
.feature-two__item__icon {
  font-size: 62px;
  line-height: 1;
  color: var(--hiredots-base, #00cde5);
  position: relative;
  z-index: 2;
}
.feature-two__item__icon::after {
  width: 50px;
  height: 50px;
  position: absolute;
  left: 22px;
  bottom: -5px;
  content: "";
  background-color: var(--hiredots-gray, #f0f3f9);
  z-index: -1;
}
.feature-two__item__title {
  font-size: 20px;
  letter-spacing: -0.8px;
  max-width: 160px;
  margin: 0 0 0 27px;
}
.feature-two__item__text {
  font-size: 16px;
  line-height: 30px;
  max-width: 250px;
  margin: 0;
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about-one {
  position: relative;
  padding: 0 0 120px;
  overflow: hidden;
}
@media (max-width: 767px) {
  .about-one {
    padding: 0 0 80px;
  }
}
.about-one__image {
  position: relative;
}
@media (min-width: 1300px) {
  .about-one__image {
    margin-left: -85px;
  }
}
@media (min-width: 1400px) {
  .about-one__image {
    margin-left: -105px;
  }
}
.about-one__image-one {
  position: relative;
  z-index: 2;
  display: inline-block;
}
.about-one__image-one img {
  max-width: 100%;
  height: auto;
  border-radius: 0 50% 50% 50%;
}
.about-one__image-one img.about-one__image-one__shape {
  border-radius: 0;
  position: absolute;
  right: 40px;
  top: 37px;
  z-index: -1;
  animation: shapeMove 3s linear 0s infinite;
}
.about-one__image-icon {
  width: 80px;
  height: 80px;
  background-color: var(--hiredots-base, #00cde5);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}
.about-one__content {
  position: relative;
}
@media (max-width: 1199px) {
  .about-one__content {
    margin: 50px 0 0;
  }
}
.about-one__content .sec-title {
  padding-bottom: 31px;
}
.about-one__content__text {
  font-size: 16px;
  margin: 0 0 29px;
}
.about-one__content__list {
  margin: 0 0 13px;
  padding: 0;
}
.about-one__content__list li {
  list-style: none;
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 40px;
  font-weight: 700;
  color: var(--hiredots-black, #0b182a);
  letter-spacing: -0.36px;
}
.about-one__content__list li span {
  display: inline-block;
  margin-right: 9px;
  color: var(--hiredots-base, #00cde5);
  -webkit-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
  transition: all 0.6s ease;
}
.about-one__content__quote {
  position: relative;
  background-color: var(--hiredots-white, #fff);
  padding: 20px 50px 27px;
  color: var(--hiredots-black, #0b182a);
  font-size: 18px;
  line-height: 30px;
  font-weight: 700;
  font-style: italic;
  box-shadow: 15px 29px 87px 0px rgba(0, 0, 0, 0.07);
  margin-left: -290px;
  margin-bottom: 30px;
  z-index: 99;
}
@media (max-width: 1199px) {
  .about-one__content__quote {
    margin-left: 0px;
  }
}
.about-one__content__quote::before {
  position: absolute;
  left: 10px;
  top: 0;
  width: 7px;
  content: "";
  height: 84%;
  background-color: var(--hiredots-primary, #0b2f81);
}
.about-one__content__quote::after {
  position: absolute;
  right: 0;
  bottom: -30px;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 50px 30px 0;
  border-color: transparent var(--hiredots-white, #fff) transparent transparent;
}
.about-one__content__author {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 79px;
  justify-content: flex-end;
  margin-right: 29px;
  text-align: right;
}
@media (max-width: 767px) {
  .about-one__content__author {
    margin: 20px 0 0;
  }
}
.about-one__content__author__thumb {
  width: 79px;
  height: 79px;
  border-radius: 50%;
  border: 2px solid var(--hiredots-base, #00cde5);
  padding: 4px;
  overflow: hidden;
}
.about-one__content__author__thumb img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.about-one__content__author__meta {
  display: block;
  position: relative;
  margin: 0;
  line-height: 1;
  padding-left: 20px;
  font-size: 14px;
  letter-spacing: 1.4px;
  text-transform: uppercase;
}
.about-one__content__author__meta img {
  display: block;
  margin-bottom: -12px;
}
.about-one__award {
  position: relative;
  background-color: var(--hiredots-gray, #f0f3f9);
  padding: 29px 40px 25px 20px;
  text-align: right;
  max-width: 247px;
  margin: 20px 0 40px auto;
}
.about-one__award__content {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  line-height: 1;
}
.about-one__award__icon {
  font-size: 79px;
  line-height: 1;
  color: var(--hiredots-base, #00cde5);
}
.about-one__award__count {
  display: flex;
  font-size: 48px;
  color: var(--hiredots-black, #0b182a);
  font-weight: 800;
  letter-spacing: -1.9px;
  line-height: 1;
  margin: 0 0 0 14px;
  position: relative;
  top: 5px;
}
.about-one__award__text {
  margin: 4px 0 0;
  font-size: 20px;
}
.about-one .hiredots-btn {
  margin-left: auto;
  max-width: 213px;
  display: flex;
}

.about-two {
  position: relative;
  padding: 120px 0;
}
@media (max-width: 767px) {
  .about-two {
    padding: 80px 0;
  }
}
.about-two__image {
  position: relative;
  z-index: 2;
}
@media (max-width: 1199px) {
  .about-two__image {
    max-width: 601px;
  }
}
.about-two__image__shape {
  position: absolute;
  left: -30px;
  top: 80px;
  z-index: -1;
  opacity: 0.3;
  max-width: 100%;
  animation: shapeMove 3s linear 0s infinite;
}
@media (min-width: 1300px) {
  .about-two__image__shape {
    left: -70px;
  }
}
@media (max-width: 1199px) {
  .about-two__image__shape {
    display: none;
  }
}
.about-two__image-one {
  position: relative;
  z-index: 2;
  display: inline-block;
}
.about-two__image-one img {
  max-width: 100%;
  height: auto;
}
.about-two__image-two {
  position: relative;
  float: right;
  margin: -359px -30px 0 0;
  z-index: 2;
}
@media (max-width: 767px) {
  .about-two__image-two {
    margin: 30px 0 0 0;
    text-align: left;
  }
}
.about-two__image-two img {
  max-width: 100%;
  height: auto;
}
.about-two__image-two::after {
  position: absolute;
  left: -95px;
  bottom: 0;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 80px 95px;
  border-color: transparent transparent var(--hiredots-base, #00cde5) transparent;
}
@media (max-width: 767px) {
  .about-two__image-two::after {
    display: none;
  }
}
.about-two__image-icon {
  width: 80px;
  height: 80px;
  background-color: var(--hiredots-base, #00cde5);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
.about-two__content {
  position: relative;
  padding-left: 0;
}
@media (min-width: 1200px) {
  .about-two__content {
    padding-left: 69px;
  }
}
@media (max-width: 1199px) {
  .about-two__content {
    padding-top: 50px;
  }
}
.about-two__content .sec-title {
  padding-bottom: 22px;
}
.about-two__content__text {
  font-size: 15px;
  margin: 0 0 32px;
}
.about-two__content__info {
  background-color: var(--hiredots-white, #fff);
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
}
.about-two__content__list {
  margin: 0;
  padding: 18px 30px 0;
  list-style: none;
  clear: both;
}
.about-two__content__list li {
  width: 50%;
  float: left;
  list-style: none;
  position: relative;
  font-size: 18px;
  line-height: 36px;
  letter-spacing: -0.72px;
  color: var(--hiredots-black, #0b182a);
  font-weight: 700;
}
@media (max-width: 767px) {
  .about-two__content__list li {
    float: none;
    width: 100%;
  }
}
@media (min-width: 1200px) {
  .about-two__content__list li:nth-child(even), .about-two__content__list li:nth-child(2), .about-two__content__list li:nth-child(4), .about-two__content__list li:nth-child(6), .about-two__content__list li:nth-child(8) {
    padding-left: 35px;
  }
}
.about-two__content__list li span {
  font-size: 16px;
  display: inline-block;
  margin-right: 6px;
  line-height: inherit;
  color: var(--hiredots-base, #00cde5);
}
.about-two__content__title {
  position: relative;
  background-color: var(--hiredots-primary, #0b2f81);
  font-size: 16px;
  color: var(--hiredots-white, #fff);
  font-weight: 600;
  margin: 23px 0 0;
  padding: 12.5px 30px;
  display: flex;
  align-items: center;
  width: 100%;
}
.about-two__content__title span {
  font-size: 22px;
  line-height: inherit;
  margin-right: 18px;
}
.about-two__content__author-wrapper {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .about-two__content__author-wrapper {
    display: block;
    float: left;
  }
  .about-two__content__author-wrapper .about-one__content__author {
    margin: 0 0 20px;
  }
}

.about-three {
  position: relative;
  padding: 120px 0 0;
  overflow: hidden;
}
@media (max-width: 767px) {
  .about-three {
    padding: 80px 0 0;
  }
}
.about-three__border {
  border-bottom: 1px solid var(--hiredots-border-color-2, #ced5dd);
  padding-bottom: 120px;
}
@media (max-width: 767px) {
  .about-three__border {
    padding-bottom: 80px;
  }
}
.about-three__image {
  position: relative;
  margin-right: -27px;
}
@media (min-width: 1280px) {
  .about-three__image {
    margin-left: -78px;
  }
}
@media (max-width: 991px) {
  .about-three__image {
    margin-right: 0;
  }
}
.about-three__image > img {
  max-width: 100%;
}
.about-three__content {
  position: relative;
  padding: 0 0 0 70px;
}
@media (max-width: 1199px) {
  .about-three__content {
    padding-left: 14px;
  }
}
@media (max-width: 991px) {
  .about-three__content {
    padding: 60px 0 0;
  }
}
.about-three__content .sec-title {
  padding-bottom: 30px;
}
.about-three__content__text {
  font-size: 16px;
  line-height: 30px;
  margin: 0 0 29px;
}
.about-three__list {
  margin: 0 0 29px;
  padding: 0;
  list-style: none;
}
.about-three__list li {
  list-style: none;
  position: relative;
  padding-left: 26px;
  font-size: 18px;
  line-height: 30px;
  color: var(--hiredots-black, #0b182a);
  font-weight: 600;
  margin: 0 0 6px;
}
.about-three__list li span {
  position: absolute;
  left: 0;
  top: 2px;
  bottom: 0;
  margin: auto;
  line-height: inherit;
  color: var(--hiredots-base, #00cde5);
}
.about-three__call {
  position: relative;
  padding: 10px 0 0 73px;
  min-height: 61px;
  display: block;
  margin: 13px 0 40px;
}
.about-three__call__icon {
  position: absolute;
  left: 0;
  top: 0;
  width: 61px;
  height: 61px;
  background-color: var(--hiredots-primary, #0b2f81);
  font-size: 25px;
  color: var(--hiredots-white, #fff);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.about-three__call__text {
  display: block;
  font-size: 14px;
  line-height: 1;
  color: var(--hiredots-text, #696e76);
  margin-bottom: 5px;
}
.about-three__call__number {
  color: var(--hiredots-black, #0b182a);
  font-size: 18px;
  font-weight: 800;
  display: inline-block;
  line-height: 1.2;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.about-three__call:hover .about-three__call__number {
  color: var(--hiredots-base, #00cde5);
  background-size: 100% 1px;
}
.about-three__info-box {
  position: relative;
  background-color: var(--hiredots-white, #fff);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 27px 30px 25px;
  max-width: 180px;
  margin: 0 0 0 auto;
  border-top: 4px solid var(--hiredots-primary, #0b2f81);
}
@media (max-width: 767px) {
  .about-three__info-box {
    margin: 30px 0 0;
  }
}
.about-three__info-box__icon {
  width: 103px;
  height: 103px;
  display: flex;
  justify-content: center;
  background-color: var(--hiredots-gray, #f0f3f9);
  border-radius: 50%;
  font-size: 60px;
  color: var(--hiredots-base, #00cde5);
  margin: auto;
}
.about-three__info-box__title {
  font-size: 18px;
  font-weight: 700;
  margin: -30px 0 0;
}

/*--------------------------------------------------------------
# Project
--------------------------------------------------------------*/
.project-one {
  position: relative;
  overflow: hidden;
  padding-top: 120px;
  padding-bottom: 120px;
}
.project-one__img img {
  width: 100%;
}
@media (max-width: 767px) {
  .project-one {
    padding: 80px 0;
  }
}
.project-one__card {
  position: relative;
  overflow: hidden;
  margin-right: 30px;
}
.project-one__card::after {
  position: absolute;
  top: -100%;
  transition: 0.4s;
  content: url(../images/shapes/project-shape.png);
}
.project-one__card::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  display: inline-block;
  opacity: 0;
  height: 0;
  transition: 0.4s;
  background-color: rgba(var(--hiredots-black-rgb, 11, 24, 42), 0.8);
}
.project-one__card:hover::before {
  height: 100%;
  opacity: 1;
}
.project-one__card:hover::after {
  top: 0;
  bottom: auto;
}
.project-one__card:hover .project-one__content {
  top: auto;
  bottom: 35px;
}
.project-one__content {
  position: absolute;
  bottom: -90px;
  left: 40px;
  transition: 0.6s;
}
.project-one__tagline {
  color: var(--hiredots-white, #fff);
  font-size: 12px;
  line-height: 1;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  display: block;
  margin-bottom: 5px;
}
.project-one__title {
  font-size: 24px;
  color: var(--hiredots-white, #fff);
  margin: 0;
}
.project-one__title a {
  color: currentColor;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.project-one__title a:hover {
  background-size: 100% 1px;
}
.project-one__title a:hover {
  color: var(--hiredots-base, #00cde5);
}
.project-one--page {
  position: relative;
  padding-top: 0;
}
.project-one--home-three {
  position: relative;
  padding: 0;
}
.project-one--home-three .container {
  max-width: 1600px;
}

.project-two {
  position: relative;
  background-color: var(--hiredots-base, #00cde5);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}
.project-two__left-content {
  position: relative;
  padding: 120px 0 57px;
}
@media (max-width: 767px) {
  .project-two__left-content {
    padding: 80px 0 30px;
  }
}
.project-two__left-content .sec-title__title,
.project-two__left-content .sec-title__tagline {
  color: var(--hiredots-white, #fff);
}
.project-two__left-content .sec-title__tagline::before {
  background-color: var(--hiredots-primary, #0b2f81);
}
@media (min-width: 992px) and (max-width: 1199px) {
  .project-two .sec-title__title {
    font-size: 42px;
  }
}
.project-two__list {
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
}
.project-two__list li {
  display: block;
  position: relative;
  padding: 0 0 0 48px;
  margin: 0 0 25px;
  color: var(--hiredots-white, #fff);
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
  letter-spacing: -0.72px;
  cursor: pointer;
}
.project-two__list li::before {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 4px;
  height: 0;
  content: "";
  background-color: var(--hiredots-white, #fff);
  transition: all 500ms ease;
}
.project-two__list li:hover::before, .project-two__list li.active-btn::before {
  height: 50px;
}
.project-two__img {
  position: relative;
  overflow: hidden;
}
.project-two__img img {
  width: inherit;
  height: auto;
}
@media (max-width: 767px) {
  .project-two__img img {
    width: 100%;
    min-height: 300px;
    object-fit: cover;
  }
}
.project-two__card {
  position: relative;
  overflow: hidden;
  display: inline-block;
}
@media (max-width: 99px) {
  .project-two__card {
    display: block;
  }
}
.project-two__btn {
  position: absolute;
  top: 0;
  width: 770px;
  left: 0;
  text-align: right;
}
@media (max-width: 1199px) {
  .project-two__btn {
    width: 615px;
  }
}
@media (max-width: 991px) {
  .project-two__btn {
    width: 100%;
  }
}
.project-two__btn .hiredots-btn {
  width: 74px;
  padding: 36px 10px;
  font-size: 16px;
  background-color: var(--hiredots-primary, #0b2f81);
}
.project-two__btn .hiredots-btn::after {
  background-color: var(--hiredots-base, #00cde5);
}
.project-two__btn .hiredots-btn:hover::after {
  left: -40%;
  width: 200%;
}
.project-two__content {
  position: absolute;
  top: auto;
  bottom: 61px;
  left: 60px;
}
@media (max-width: 767px) {
  .project-two__content {
    bottom: 30px;
    left: 30px;
  }
}
.project-two__tagline {
  color: var(--hiredots-white, #fff);
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1.6px;
  display: block;
  margin-bottom: 7px;
}
.project-two__title {
  font-size: 40px;
  letter-spacing: -1.6px;
  color: var(--hiredots-white, #fff);
  margin: 0;
}
@media (max-width: 767px) {
  .project-two__title {
    font-size: 30px;
    letter-spacing: -1px;
  }
}
.project-two__title a {
  color: currentColor;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.project-two__title a:hover {
  background-size: 100% 1px;
}
.project-two__title a:hover {
  color: var(--hiredots-base, #00cde5);
}

/*--------------------------------------------------------------
# Feature Details
--------------------------------------------------------------*/
.project-details {
  position: relative;
  padding: 120px 0;
}
@media (max-width: 767px) {
  .project-details {
    padding: 80px 0;
  }
}
.project-details__img {
  position: relative;
}
.project-details__img img {
  width: 100%;
  height: auto;
}
.project-details__meta {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-left: 60px;
  margin-right: 60px;
  padding: 35px 60px 42px;
  border-bottom: 3px solid var(--hiredots-base, #00cde5);
  margin-top: -60px;
  background-color: var(--hiredots-white, #fff);
  position: relative;
  z-index: 1;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
}
@media (max-width: 1199px) {
  .project-details__meta {
    margin-left: 30px;
    margin-right: 30px;
    padding-left: 30px;
    padding-right: 30px;
  }
}
.project-details__meta__item {
  position: relative;
}
@media (max-width: 767px) {
  .project-details__meta__item {
    margin-bottom: 25px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .project-details .project-details__social {
    margin-top: 25px;
  }
}
.project-details__tagline {
  font-size: 14px;
  font-weight: 500;
  display: block;
  margin-bottom: 1px;
}
.project-details__title {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}
.project-details__social a {
  width: 43px;
  height: 43px;
  line-height: 43px;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
  color: var(--hiredots-black, #0b182a);
  background-color: var(--hiredots-gray, #f0f3f9);
}
.project-details__social a + a {
  margin-left: 10px;
}
.project-details__social a:hover {
  color: var(--hiredots-white, #fff);
  background-color: var(--hiredots-base, #00cde5);
}
.project-details__wrapper {
  padding-top: 51px;
  padding-bottom: 100px;
}
.project-details__headline {
  font-size: 36px;
  letter-spacing: -1.4px;
  margin-bottom: 22px;
}
.project-details__info {
  font-size: 16px;
  margin-bottom: 30px;
  line-height: 30px;
}
.project-details__highligt {
  color: var(--hiredots-base, #00cde5);
  font-size: 24px;
  letter-spacing: var(--hiredots-letter-space-sm, -0.04em);
  margin-top: 28px;
}
.project-details h4 {
  font-size: 26px;
  margin-top: 45px;
  margin-bottom: 26px;
  letter-spacing: var(--hiredots-letter-space-sm, -0.04em);
}
.project-details__feature {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
  margin-top: 44px;
}
.project-details__feature img {
  max-width: 100%;
}
.project-details__feature__list {
  flex-shrink: 0;
  margin: 0;
  padding: 0;
}
.project-details__feature__list li {
  font-size: 16px;
  font-weight: 700;
  line-height: 36px;
  color: var(--hiredots-black, #0b182a);
}
.project-details__feature__list i {
  color: var(--hiredots-base, #00cde5);
  margin-right: 10px;
}
.project-details__pagination {
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
  padding-bottom: 30px;
  border: 1px solid var(--hiredots-border-color-2, #ced5dd);
  border-left: 0;
  border-right: 0;
}
.project-details__pagination span.previous i {
  margin-right: 20px;
}
.project-details__pagination span.next i {
  margin-left: 20px;
}
.project-details__pagination span a {
  color: var(--hiredots-black, #0b182a);
  font-weight: 700;
  text-transform: capitalize;
  letter-spacing: -0.72px;
  font-size: 18px;
}
.project-details__pagination span a:hover {
  color: var(--hiredots-base, #00cde5);
}
.project-details__pagination span a:hover i {
  color: var(--hiredots-white, #fff);
  background-color: var(--hiredots-base, #00cde5);
}
.project-details__pagination span i {
  width: 52px;
  height: 52px;
  line-height: 52px;
  font-size: 16px;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
  color: var(--hiredots-black, #0b182a);
  background-color: var(--hiredots-gray, #f0f3f9);
  transition: 0.4s;
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.service-one {
  position: relative;
  background-position: top center;
  background-repeat: no-repeat;
  padding: 120px 0;
}
@media (max-width: 767px) {
  .service-one {
    padding: 80px 0;
  }
}

.service-one-card {
  background-color: var(--hiredots-white, #fff);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
  transition: all 0.6s ease;
}
.service-one-card:hover {
  background-color: var(--hiredots-primary, #0b2f81);
}
.service-one-card:hover .service-one-card__link {
  color: var(--hiredots-white, #fff);
}
.service-one-card__content {
  margin: 0px 15px;
  padding: 30px 40px 33px 35px;
  background-color: var(--hiredots-gray, #f0f3f9);
}
@media (min-width: 992px) and (max-width: 1199px) {
  .service-one-card__content {
    padding-left: 30px;
    padding-right: 30px;
  }
}
.service-one-card__image {
  position: relative;
}
.service-one-card__image img {
  width: 100%;
  height: auto;
}
.service-one-card__icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}
.service-one-card__icon i {
  font-size: 62px;
  display: inline-block;
  color: var(--hiredots-base, #00cde5);
}
.service-one-card__cat {
  font-size: 10px;
  text-transform: uppercase;
  color: var(--hiredots-text, #696e76);
  background-color: var(--hiredots-border-color-2, #ced5dd);
  display: inline-block;
  padding: 5px 15px;
  height: 29px;
  font-weight: 700;
  letter-spacing: 1px;
}
.service-one-card__cat:hover {
  color: var(--hiredots-white, #fff);
  background-color: var(--hiredots-base, #00cde5);
}
.service-one-card__title {
  font-size: 22px;
  color: var(--hiredots-black, #0b182a);
  letter-spacing: -0.88px;
  margin-bottom: 8px;
}
.service-one-card__title a {
  color: currentColor;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.service-one-card__title a:hover {
  background-size: 100% 1px;
}
.service-one-card__title a:hover {
  color: var(--hiredots-base, #00cde5);
}
.service-one-card__text {
  margin-bottom: 0;
  line-height: 26px;
}
.service-one-card__link {
  margin: 12px 50px 0;
  padding-bottom: 13px;
  display: flex;
  align-items: center;
  color: var(--hiredots-black, #0b182a);
  font-weight: 700;
  font-size: 13px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .service-one-card__link {
    margin-left: 45px;
  }
}
.service-one-card__link i {
  padding-left: 10px;
  font-size: 16px;
  position: relative;
  top: 1px;
  display: inline-block;
}
.service-one-card__link:hover {
  color: var(--hiredots-base, #00cde5);
}
.service-one-card__link:hover i {
  padding-left: 5px;
  transition: all 0.3s ease;
}

.service-two {
  position: relative;
  background-position: top center;
  background-repeat: no-repeat;
  padding: 120px 0 0;
}
@media (max-width: 767px) {
  .service-two {
    padding: 80px 0 0;
  }
}
.service-two .sec-title__tagline {
  color: var(--hiredots-text-dark, #7e8c9e);
}
.service-two .sec-title__title {
  color: var(--hiredots-white, #fff);
}

.service-two-card {
  background-color: var(--hiredots-white, #fff);
  transition: all 0.5s ease;
}
.service-two-card__image {
  position: relative;
}
.service-two-card__image img {
  width: 100%;
  height: auto;
}
.service-two-card:hover .service-two-card__content {
  border-color: var(--hiredots-black, #0b182a);
}
.service-two-card__content {
  margin: 0;
  padding: 0 29px 24px;
  border: 1px solid var(--hiredots-border-color-2, #ced5dd);
  transition: all 0.5s ease;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .service-two-card__content {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.service-two-card__icon {
  width: 70px;
  height: 67px;
  background-color: var(--hiredots-base, #00cde5);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 45px;
  color: var(--hiredots-white, #fff);
  margin-top: -48px;
  margin-bottom: 16px;
  position: relative;
  z-index: 2;
}
.service-two-card__title {
  font-size: 20px;
  color: var(--hiredots-black, #0b182a);
  letter-spacing: -0.8px;
  margin-bottom: 14px;
}
.service-two-card__title a {
  color: currentColor;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.service-two-card__title a:hover {
  background-size: 100% 1px;
}
.service-two-card__title a:hover {
  color: var(--hiredots-base, #00cde5);
}
.service-two-card__text {
  margin-bottom: 0;
  font-size: 16px;
  line-height: 30px;
}
.service-two-card__link {
  margin: 0 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--hiredots-text, #696e76);
  font-weight: 700;
  font-size: 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  background-color: var(--hiredots-border-color-2, #ced5dd);
  height: 31px;
  width: 131px;
}
.service-two-card__link i {
  padding-left: 10px;
  font-size: 16px;
  position: relative;
  top: 1px;
  display: inline-block;
}
.service-two-card__link:hover {
  background-color: var(--hiredots-black, #0b182a);
  color: var(--hiredots-white, #fff);
}

/*--------------------------------------------------------------
# Service details
--------------------------------------------------------------*/
.service-details {
  padding: 120px 0;
}
@media (max-width: 767px) {
  .service-details {
    padding: 80px 0;
  }
}
.service-details__thumbnail {
  margin-bottom: 30px;
}
.service-details__thumbnail img {
  width: 100%;
}
.service-details__image__circle {
  position: relative;
}
.service-details__image__circle img {
  border-radius: 50%;
  max-width: 100%;
}
.service-details__title {
  font-size: 25px;
  margin-bottom: 23px;
  letter-spacing: -1.2px;
}
@media (min-width: 768px) {
  .service-details__title {
    font-size: 30px;
  }
}
.service-details__lighlight {
  font-size: 24px;
  margin-bottom: 34px;
  color: var(--hiredots-base, #00cde5);
  letter-spacing: var(--hiredots-letter-space-sm, -0.04em);
}
.service-details__text {
  margin: 0;
  font-size: 15px;
  line-height: 30px;
  margin-bottom: 27px;
  padding-right: 5px;
}
.service-details__text + .service-details__title {
  margin-top: 43px;
  margin-bottom: 33px;
}
.service-details__text + .service-details__list {
  margin-top: 40px;
}
.service-details__text + .service-details__text {
  margin-top: 35px;
}
.service-details_list-title {
  font-size: 30px;
  letter-spacing: -1.2px;
  margin: 0 0 23px;
}
.service-details_list-text {
  font-size: 18px;
  margin: 0 0 34px;
}
.service-details__list {
  margin: 0;
  padding: 0;
}
.service-details__list li {
  position: relative;
  font-size: 16px;
  padding-left: 27px;
  color: var(--hiredots-text, #696e76);
  margin-top: 7px;
}
.service-details__list li > i {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-size: 14px;
  color: var(--hiredots-base, #00cde5);
}
.service-details__process {
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.07);
  margin-bottom: 60px;
}
.service-details__process__top {
  text-align: center;
  padding: 37px 40px 35px;
}
@media (min-width: 768px) {
  .service-details__process__top.item-2 {
    border-right: 2px solid var(--hiredots-border-color, #cbd2da);
    border-left: 2px solid var(--hiredots-border-color, #cbd2da);
  }
}
.service-details__process__icon {
  display: block;
  color: var(--hiredots-base, #00cde5);
  font-size: 60px;
  line-height: 1;
}
.service-details__process__title {
  font-size: 18px;
  margin: 0;
  margin-bottom: 25px;
}
.service-details__process__text {
  margin: 0;
  font-size: 15px;
  line-height: 2em;
  max-width: 220px;
  margin-top: 10px;
}
.service-details__post {
  margin-bottom: 0;
}
.service-details__post li + li {
  margin-top: 10px;
}
.service-details__post__date {
  text-transform: uppercase;
  letter-spacing: var(--hiredots-letter-space-xl, 0.2em);
  color: var(--hiredots-base, #00cde5);
  font-size: 14px;
  margin: 0;
  line-height: 2.1428571429em;
}
.service-details__post__title {
  margin: 0;
  line-height: 1.875em;
  color: var(--hiredots-black, #0b182a);
  text-shadow: 0 0 0.5px currentColor;
}
.service-details__post__title a {
  color: inherit;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.service-details__post__title a:hover {
  background-size: 100% 1px;
}
.service-details__post__title a:hover {
  color: var(--hiredots-black, #0b182a);
}
.service-details__info {
  border-top: 1px solid var(--hiredots-border-color, #cbd2da);
  padding-top: 55px;
  margin-top: 60px;
}
.service-details__info__title {
  font-weight: bold;
  text-transform: uppercase;
  margin: 0;
  color: var(--hiredots-black, #0b182a);
  font-size: 20px;
  margin-bottom: 24px;
}
.service-details__info__list {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .service-details__info__list li {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.service-details__info__list li + li {
  border-top: 1px solid var(--hiredots-border-color, #cbd2da);
  margin-top: 20px;
  padding-top: 20px;
}
.service-details__info__list__date {
  color: var(--hiredots-black, #0b182a);
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}
.service-details__info__list__date > i {
  color: var(--hiredots-base, #00cde5);
  font-size: 22px;
  margin-right: 10px;
}
.service-details__info__list__space {
  font-size: 16px;
  line-height: 24px;
  color: var(--hiredots-text, #696e76);
  margin-top: 10px;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .service-details__info__list__space {
    margin-bottom: 0;
    margin-top: 0;
  }
}
.service-details__info__list__btn {
  font-size: 10px;
  padding: 10.75px 30px;
}

/*--------------------------------------------------------------
# Instagram
--------------------------------------------------------------*/
.instagram-one {
  position: relative;
  padding: 120px 0;
}
@media (max-width: 767px) {
  .instagram-one {
    padding: 80px 0;
  }
}
@media (min-width: 1400px) {
  .instagram-one .container {
    max-width: 1380px;
  }
}
.instagram-one__title {
  display: block;
  text-align: center;
  position: relative;
  font-size: 14px;
  color: var(--hiredots-text, #696e76);
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
  font-family: var(--hiredots-font, "Manrope", sans-serif);
  letter-spacing: 2.8px;
  margin: 0 auto 39px;
}
.instagram-one__title span {
  display: inline-block;
  background-color: var(--hiredots-white, #fff);
  position: relative;
  z-index: 2;
  padding: 0 28px;
}
@media (max-width: 550px) {
  .instagram-one__title span {
    padding: 0 18px;
  }
}
.instagram-one__title::after {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 9px;
  width: 518px;
  height: 1px;
  content: "";
  background-color: var(--hiredots-border-color, #cbd2da);
}
@media (max-width: 550px) {
  .instagram-one__title::after {
    width: 100%;
  }
}
.instagram-one a {
  overflow: hidden;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  position: relative;
  display: block;
  width: 100%;
  height: auto;
}
.instagram-one a::before {
  position: absolute;
  content: "\f16d";
  font-family: "Font Awesome 5 Brands";
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  text-align: center;
  top: 55%;
  transform: translateY(-50%);
  z-index: 3;
  color: var(--hiredots-white, #fff);
  font-size: 30px;
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  transition: all ease 0.4s;
  transition-delay: 0s;
}
.instagram-one a::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 0;
  z-index: 1;
  opacity: 0;
  background: rgba(var(--hiredots-black-rgb, 11, 24, 42), 0.5);
  visibility: hidden;
  transition: all ease 0.4s;
}
.instagram-one a:hover::after {
  height: 100%;
  opacity: 1;
  visibility: visible;
}
.instagram-one a:hover::before {
  top: 50%;
  visibility: visible;
  opacity: 1;
  transition-delay: 0.4s;
}
.instagram-one a img {
  width: 100%;
  height: auto;
  transition: all ease 0.3s;
}
.instagram-one a:hover img {
  transform: scale(1.1) rotate(1.5deg);
  -moz-transform: scale(1.1) rotate(1.5deg);
  -webkit-transform: scale(1.1) rotate(1.5deg);
  -ms-transform: scale(1.1) rotate(1.5deg);
  -o-transform: scale(1.1) rotate(1.5deg);
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.instagram-two {
  position: relative;
  z-index: 2;
  padding: 0 0;
  margin-bottom: -120px;
}
.instagram-two .container {
  max-width: 1670px;
}
.instagram-two a {
  overflow: hidden;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  position: relative;
  display: block;
  width: 100%;
  height: auto;
}
.instagram-two a::before {
  position: absolute;
  content: "\f16d";
  font-family: "Font Awesome 5 Brands";
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  text-align: center;
  top: 55%;
  transform: translateY(-50%);
  z-index: 3;
  color: var(--hiredots-white, #fff);
  font-size: 40px;
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  transition: all ease 0.4s;
  transition-delay: 0s;
}
.instagram-two a::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 0;
  z-index: 1;
  opacity: 0;
  background: rgba(var(--hiredots-base-rgb, 0, 205, 229), 0.9);
  visibility: hidden;
  transition: all ease 0.4s;
}
.instagram-two a:hover::after {
  height: 100%;
  opacity: 1;
  visibility: visible;
}
.instagram-two a:hover::before {
  top: 50%;
  visibility: visible;
  opacity: 1;
  transition-delay: 0.4s;
}
.instagram-two a img {
  width: 100%;
  height: auto;
  transition: all ease 0.3s;
}
.instagram-two a:hover img {
  transform: scale(1.1) rotate(1.5deg);
  -moz-transform: scale(1.1) rotate(1.5deg);
  -webkit-transform: scale(1.1) rotate(1.5deg);
  -ms-transform: scale(1.1) rotate(1.5deg);
  -o-transform: scale(1.1) rotate(1.5deg);
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

/*--------------------------------------------------------------
# Opening Time
--------------------------------------------------------------*/
.opening {
  position: relative;
  margin-bottom: -85px;
}
.opening--home-three {
  margin-bottom: 0;
  padding-bottom: 120px;
}
@media (max-width: 767px) {
  .opening--home-three {
    padding-bottom: 80px;
  }
}
.opening__wrapper {
  background-color: var(--hiredots-base, #00cde5);
  position: relative;
  z-index: 2;
  padding: 39px 0;
}
@media (max-width: 767px) {
  .opening__wrapper {
    padding: 30px 20px;
    text-align: center;
  }
}
.opening__wrapper::after {
  position: absolute;
  left: 0;
  top: 0;
  width: 180px;
  height: 100%;
  content: "";
  opacity: 0.102;
  background-image: linear-gradient(90deg, rgba(var(--hiredots-black-rgb, 11, 24, 42), 0) 0%, rgba(var(--hiredots-black-rgb, 11, 24, 42), 1) 100%);
}
@media (max-width: 991px) {
  .opening__wrapper::after {
    display: none;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .opening__wrapper::after {
    width: 155px;
  }
}
.opening__icon {
  font-size: 65px;
  color: var(--hiredots-white, #fff);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px 0 0;
  margin-right: -10px;
  position: relative;
  z-index: 2;
}
.opening__title {
  color: var(--hiredots-white, #fff);
  text-transform: uppercase;
  font-size: 30px;
  font-weight: 700;
  margin: 27px -30px 0 33px;
  position: relative;
}
@media (max-width: 767px) {
  .opening__title {
    margin: 20px 0 0;
  }
}
.opening__info {
  font-size: 12px;
  line-height: 30px;
  color: var(--hiredots-white, #fff);
  text-transform: uppercase;
  padding: 12px 0 20px;
  margin: 0 0 0 -14px;
}
@media (max-width: 991px) {
  .opening__info {
    margin: 0 0 0;
    text-align: center;
  }
}
@media (max-width: 767px) {
  .opening__info {
    margin: 0 0 0;
  }
}
.opening__info--last {
  margin-left: -45px;
  padding-left: 60px;
  border-left: 1px solid rgba(var(--hiredots-white-rgb, 255, 255, 255), 0.2);
}
@media (max-width: 991px) {
  .opening__info--last {
    margin: 0 0 0;
    padding: 0;
    border: none;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .opening__info--last {
    margin-left: -30px;
    padding-left: 20px;
  }
}
.opening__info__text {
  display: block;
  font-size: 20px;
  text-transform: none;
}

/*--------------------------------------------------------------
# Work Process
--------------------------------------------------------------*/
.work-process-one {
  position: relative;
  counter-reset: count;
  padding: 0 0 120px;
}
@media (max-width: 767px) {
  .work-process-one {
    padding: 0 0 80px;
  }
}
.work-process-one__item {
  position: relative;
  counter-increment: count;
  padding-top: 10px;
}
.work-process-one__item__shape {
  position: absolute;
  right: -60px;
  top: 52px;
  width: 87px;
  height: 62px;
  background-position: center;
  background-repeat: no-repeat;
}
@media (max-width: 767px) {
  .work-process-one__item__shape {
    display: none;
  }
}
@media (max-width: 991px) {
  .work-process-one__item__shape--md-none {
    display: none;
  }
}
.work-process-one__item__icon-bg {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  width: 168px;
  height: 168px;
  content: "";
  background-color: var(--hiredots-base, #00cde5);
  border-radius: 50%;
  z-index: -1;
}
.work-process-one__item__icon-bg::after {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 10px;
  width: 168px;
  height: 168px;
  content: "";
  background-color: var(--hiredots-white, #fff);
  border-radius: 50%;
  z-index: -1;
}
.work-process-one__item__icon {
  width: 168px;
  height: 168px;
  font-size: 62px;
  color: var(--hiredots-base, #00cde5);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
  border-radius: 50%;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
  margin: auto;
}
.work-process-one__item__icon span {
  display: inline-block;
  transition: all 500ms ease;
}
.work-process-one__item:hover .work-process-one__item__icon span {
  transform: scale(0.95);
}
.work-process-one__item__content {
  position: relative;
  padding: 34px 0 0;
}
.work-process-one__item__title {
  font-size: 24px;
  line-height: 30px;
  margin: 0 0 16px;
}
.work-process-one__item__text {
  margin: 0 0 20px;
}
.work-process-one__item__number {
  width: 51px;
  height: 51px;
  background-color: var(--hiredots-primary, #0b2f81);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: var(--hiredots-white, #fff);
  font-size: 18px;
  font-weight: 500;
  position: relative;
  margin: auto;
}
.work-process-one__item__number::before {
  content: counters(count, ".", decimal-leading-zero);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*--------------------------------------------------------------
# history
--------------------------------------------------------------*/
.history {
  padding: 120px 0;
}
@media (max-width: 767px) {
  .history {
    padding: 80px 0;
  }
}
.history__card + .history__card {
  margin-top: 60px;
}
.history__card {
  position: relative;
}
@media (min-width: 1200px) {
  .history__card {
    display: flex;
    margin-bottom: 0;
  }
  .history__card::before {
    position: absolute;
    content: "";
    height: 4px;
    background-color: var(--hiredots-primary, #0b2f81);
    z-index: -1;
    left: 50px;
    right: 60px;
  }
  .history__card.item-2, .history__card.item-4, .history__card.item-6, .history__card.item-8 {
    flex-direction: row-reverse;
  }
}
@media (min-width: 1200px) and (min-width: 1200px) {
  .history__card.item-2 .history__card__left, .history__card.item-4 .history__card__left, .history__card.item-6 .history__card__left, .history__card.item-8 .history__card__left {
    display: flex;
    flex-direction: row-reverse;
  }
}
@media (min-width: 1200px) and (min-width: 1200px) {
  .history__card.item-2 .history__card__year, .history__card.item-4 .history__card__year, .history__card.item-6 .history__card__year, .history__card.item-8 .history__card__year {
    margin-right: 0px;
    margin-left: 80px;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .history__card.item-2 .history__card__content, .history__card.item-4 .history__card__content, .history__card.item-6 .history__card__content, .history__card.item-8 .history__card__content {
    padding-right: 40px;
  }
}
@media (min-width: 1200px) {
  .history__card.item-2 .history__card__content::before, .history__card.item-4 .history__card__content::before, .history__card.item-6 .history__card__content::before, .history__card.item-8 .history__card__content::before {
    right: auto;
    left: 100%;
    transform: rotateY(-180deg);
  }
}
@media (min-width: 1200px) {
  .history__card__left {
    display: flex;
  }
}
.history__card__year {
  font-size: 26px;
  font-weight: 700;
  color: var(--hiredots-white, #fff);
  width: 107px;
  height: 107px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--hiredots-primary, #0b2f81);
  letter-spacing: var(--hiredots-letter-space-sm, -0.04em);
  margin-bottom: 30px;
}
@media (min-width: 1200px) {
  .history__card__year {
    margin-bottom: 0;
    margin-right: 80px;
  }
}
.history__card__content {
  padding: 40px 25px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.07);
  position: relative;
}
@media (min-width: 768px) {
  .history__card__content {
    margin-bottom: 0;
    padding: 31px 40px 40px;
    padding-right: 65px;
  }
}
@media (min-width: 1200px) {
  .history__card__content::before {
    position: absolute;
    right: 100%;
    top: 4px;
    content: url("../images/shapes/history-arrow.png");
    background-color: var(--hiredots-white, #fff);
  }
}
.history__card__date {
  font-size: 16px;
  font-weight: 500;
}
.history__card__title {
  font-size: 40px;
  margin-bottom: 22px;
  letter-spacing: var(--hiredots-letter-space-sm, -0.04em);
}
@media (max-width: 767px) {
  .history__card__title {
    font-size: 30px;
  }
}
.history__card__text {
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 0;
}
.history__card__img img {
  width: 100%;
}

/*--------------------------------------------------------------
# jobs
--------------------------------------------------------------*/
.jobs {
  padding: 120px 0;
  position: relative;
}
@media (max-width: 767px) {
  .jobs {
    padding: 80px 0;
  }
}
.jobs__form {
  background-color: var(--hiredots-gray, #f0f3f9);
  padding: 30px;
}
@media (min-width: 768px) {
  .jobs__form {
    padding: 50px;
  }
}
.jobs__form__checkbox {
  display: flex;
  flex-wrap: wrap;
  padding-top: 26px;
  padding-bottom: 27px;
  border-bottom: 1px solid var(--hiredots-border-color-2, #ced5dd);
  margin-bottom: 60px;
}
.jobs__form__checkbox .item {
  position: relative;
  left: -10px;
  margin-right: 40px;
  font-size: 16px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .jobs__form__checkbox .item {
    margin-right: 20px;
  }
}
.jobs__form__checkbox .item input[type=checkbox] {
  visibility: hidden;
}
.jobs__form__checkbox .item input[type=checkbox]:checked + label.check {
  background-color: var(--hiredots-base, #00cde5);
  border-color: var(--hiredots-base, #00cde5);
}
.jobs__form__checkbox .item input[type=checkbox]:checked + label.check:after {
  opacity: 1;
}
.jobs__form__checkbox .item label {
  cursor: pointer;
}
.jobs__form__checkbox .item label.check {
  position: relative;
  top: 4px;
  left: -5px;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  background-color: var(--hiredots-gray, #f0f3f9);
}
.jobs__form__checkbox .item label.check:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 4px;
  opacity: 0;
  position: absolute;
  top: 5px;
  transform: rotate(-45deg);
  width: 11px;
}
.jobs__group input {
  width: 100%;
  border: 0;
  font-size: 14px;
  height: 64px;
  padding: 0 20px;
  color: var(--hiredots-text, #696e76);
  font-weight: 500;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
}
@media (min-width: 768px) {
  .jobs__group input {
    padding: 0 50px;
  }
}
.jobs__group input:focus {
  outline: 0;
}
.jobs-list__card {
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--hiredots-border-color-2, #ced5dd);
  padding-bottom: 40px;
}
@media (min-width: 992px) {
  .jobs-list__card {
    display: flex;
    gap: 40px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .jobs-list__card {
    gap: 0;
  }
}
.jobs-list__card + .jobs-list__card {
  margin-top: 40px;
}
.jobs-list__left {
  align-content: center;
}
@media (min-width: 768px) {
  .jobs-list__left {
    display: flex;
  }
}
@media (min-width: 992px) {
  .jobs-list__right {
    border-left: 1px solid var(--hiredots-border-color-2, #ced5dd);
    padding-left: 30px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .jobs-list__right {
    padding-left: 20px;
  }
}
.jobs-list__logo {
  width: 150px;
  height: 144px;
  line-height: 144px;
  margin-right: 50px;
  text-align: center;
  background-color: var(--hiredots-gray, #f0f3f9);
  flex-shrink: 0;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .jobs-list__logo {
    margin-right: 25px;
  }
}
.jobs-list__content {
  margin-top: 29px;
}
.jobs-list__time {
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 8px;
  font-weight: 500;
}
.jobs-list__time i {
  color: var(--hiredots-base, #00cde5);
  margin-right: 3px;
}
.jobs-list__title {
  font-size: 24px;
  font-weight: 800;
  color: var(--hiredots-black, #0b182a);
  letter-spacing: var(--hiredots-letter-space-sm, -0.04em);
  margin-bottom: 0;
  line-height: 1.2;
}
@media (min-width: 768px) {
  .jobs-list__title {
    line-height: 1;
  }
}
.jobs-list__title a {
  color: currentColor;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.jobs-list__title a:hover {
  background-size: 100% 1px;
}
.jobs-list__title a:hover {
  color: var(--hiredots-base, #00cde5);
}
.jobs-list__location {
  font-size: 16px;
  font-weight: 500;
}
.jobs-list__price {
  font-size: 16px;
  font-weight: 500;
  margin-right: 50px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .jobs-list__price {
    margin-right: 20px;
  }
}
@media (max-width: 991px) {
  .jobs-list__price {
    margin: 30px 0 20px;
    display: block;
  }
}
@media (max-width: 767px) {
  .jobs-list__price {
    margin: 0 0 20px;
    display: block;
  }
}
.jobs-list__price b {
  font-weight: 700;
  color: var(--hiredots-black, #0b182a);
}

/*--------------------------------------------------------------
# Pricing
--------------------------------------------------------------*/
.pricing-one {
  padding: 120px 0;
  position: relative;
  background-position: top center;
  background-repeat: no-repeat;
}
@media (max-width: 767px) {
  .pricing-one {
    padding: 80px 0;
  }
}
.pricing-one__item {
  position: relative;
  background-color: var(--hiredots-white, #fff);
  transition: all 500ms ease;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.05);
  padding: 15px;
}
.pricing-one__item:hover {
  background-color: var(--hiredots-primary, #0b2f81);
}
.pricing-one__inner {
  position: relative;
  border: 1px solid var(--hiredots-border-color-2, #ced5dd);
  transition: all 500ms ease;
  background-color: var(--hiredots-white, #fff);
  padding: 33px 40px 39px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .pricing-one__inner {
    padding-left: 30px;
    padding-right: 30px;
  }
}
.pricing-one__title {
  font-size: 24px;
  letter-spacing: -0.96px;
  margin: 0 0 24px;
}
.pricing-one__title br {
  display: block;
}
.pricing-one__icon {
  font-size: 64px;
  line-height: 1;
  color: var(--hiredots-base, #00cde5);
  position: relative;
  z-index: 2;
  margin: 0 0 23px;
}
.pricing-one__icon::after {
  width: 62px;
  height: 58px;
  position: absolute;
  left: 20px;
  top: -10px;
  content: "";
  background-color: var(--hiredots-gray, #f0f3f9);
  z-index: -1;
}
.pricing-one__price {
  display: block;
  font-size: 34px;
  font-weight: 800;
  color: var(--hiredots-base, #00cde5);
  line-height: 1;
  letter-spacing: -1.3px;
  text-transform: uppercase;
  padding: 0 0 38px;
  margin: 0 0 33px;
  border-bottom: 1px solid var(--hiredots-border-color-2, #ced5dd);
}
.pricing-one__price__tagline {
  display: block;
  font-size: 14px;
  color: var(--hiredots-black, #0b182a);
  letter-spacing: 0;
  margin-top: 11px;
}
.pricing-one__text {
  font-size: 16px;
  line-height: 26px;
  margin: 0 0 33px;
}
.pricing-one__text br {
  display: block;
}
.pricing-one .hiredots-btn {
  background-color: var(--hiredots-primary, #0b2f81);
}
.pricing-one .hiredots-btn::after {
  background-color: var(--hiredots-base, #00cde5);
}



/*# sourceMappingURL=hiredots.css.map */


